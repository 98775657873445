import { useSnackbar } from "notistack";



const useAlerts = () => {
  const { enqueueSnackbar } = useSnackbar();


  const handleAlert = ({ message, variant }) => {
    enqueueSnackbar(message, { variant });
  };

  return handleAlert;
};

export default useAlerts;