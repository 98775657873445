import React from "react";
import {
  TextField,
  InputLabel,
  createTheme,
  ThemeProvider,
} from "@mui/material";



const CustomTextField = ({
  name,
  value,
  onChange,
  helpertext,
  type,
  multiLine,
  rows,

}) => {
  return (
    <>

        <InputLabel
          sx={{

            fontWeight: 500,
            fontSize: "13px",
            fontFamily: "outfit",
            mb: 1,
          }}
        >
          {name}
        </InputLabel>
        <TextField
          helperText={helpertext}
          type={type}
       
          placeholder={name}
          size="large"
          fullWidth
          multiline={multiLine}
          value={value}
          rows={rows}
          onChange={onChange}
          InputProps={{
            style: {
              fontFamily: "outfit",
              fontSize: "13px",
              borderRadius: "10px",
         
              fontWeight: 500,
        color:'text.primary',
       

              // Replace with your desired font family
            },
            
          }}
        />

    </>
  );
};

export default CustomTextField;
