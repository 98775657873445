import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Paper,
  Chip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material";

import { IoMailOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { BiMessageSquareEdit } from "react-icons/bi";

import { useSnackbar } from "notistack";

import DeleteGuest from "./DeleteGuest";
import InternalNotes from "../../RManagement/Notes/InternalNotes";
import EditGuest from "./EditGuest";
import AddTags from "./AddTags";

const AdvancedGuestOutlet = ({ datas, action }) => {
  const [chipData, setChipData] = React.useState([

  ]);

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const [isLoading, setIsLoading] = useState(false);
  const [outletData, setOutletData] = useState(null);
  const [alignment, setAlignment] = React.useState("left");
  const [selectedButton, setSelectedButton] = useState(1);

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const colors = ['#FF6B6B', '#6BCB77', '#4D96FF', '#C0AFFF', '#FF9CEE', '#FFAB76', "#3F9996", "#9EB25B", "#E6A147"];

  const getRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};

const coloredTags = datas?.Tags?.map(tag => ({
  ...tag,
  bgColor: getRandomColor()
}));



  return (
    <>
      <Box sx={{}}>
        <Box
          sx={{
            px: 4,
            py: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "0.3px solid",
            borderColor: "text.primary",
          }}
        >
          <Typography
            sx={{ fontWeight: 500, fontSize: "14px", fontFamily: "optima" }}
          >{`${datas?.FirstName} ${datas?.LastName}`}</Typography>
          <Box sx={{ pr: 3 }}>
            <IconButton
              onClick={() => setSelectedButton(1)}
              sx={{
                borderRight: "0.5px solid ",
                borderLeft: "0.5px solid ",
                borderColor: "text.primary",
                borderRadius: 0,
                p: 2,
                color: "text.primary",
                "&:hover": {
                  background: "background.card",
                  color: "text.primary",
                },
                ...(selectedButton === 1 && {
                  background: "background.card",
                  color: "primary.main",
                }),
              }}
            >
              <CgProfile style={{ fontSize: "20px" }} />
            </IconButton>
            <IconButton
              onClick={() => setSelectedButton(2)}
              sx={{
                borderRight: "0.5px solid #fff",
                borderRadius: 0,

                p: 2,
                color: "text.primary",
                "&:hover": {
                  background: "background.card",
                  color: "text.primary",
                },
                ...(selectedButton === 2 && {
                  background: "background.card",
                  color: "primary.main",
                }),
              }}
            >
              <AiOutlineClockCircle style={{ fontSize: "18px" }} />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            bgcolor: "background.card",
            p: 2,
            m: 4,
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            borderRadius: "8px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
              <Avatar
                sx={{
                  bgcolor: "#d9d9d9",
                  color: "#000",
                  height: "80px",
                  width: "80px",
                  fontFamily: "outfit",
                  fontSize: "30px",
                }}
              >
                {`${datas?.FirstName?.charAt(0)}${
                  datas?.LastName?.charAt(0) || ""
                }`}
              </Avatar>
              <Box>
                <Typography sx={{ fontWeight: 700 }}>
                  {`${datas?.FirstName} ${datas?.LastName || ""} `}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                    mt: 1,
                  }}
                >
                  <IoMailOutline style={{}} />
                  <Typography sx={{ fontSize: "12px" }}>{`${
                    datas?.EmailAddress || "Not provided"
                  }`}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                    mt: 0,
                  }}
                >
                  <FiPhone style={{}} />
                  <Typography sx={{ fontSize: "12px" }}>
                    {`${datas?.PhoneNumber}`}{" "}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <EditGuest guest={datas} callback={action} />
            </Box>
          </Box>
          <Divider sx={{ borderColor: "#ccc", my: 1 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              className="three-line-ellipsis"
              sx={{ width: "65%", fontSize: "12px" }}
            >
              {datas?.InternalNote || (
                <span style={{ color: "#ccccccb7" }}>
                  No internal note available. Click the edit button to add or
                  modify.
                </span>
              )}
            </Typography>
            <Box>
              <InternalNotes
                value={datas?.InternalNote || ""}
                id={datas?.Id}
                guest={true}
                action={action}
              >
                <IconButton>
                  <BiMessageSquareEdit style={{ fontSize: "25px" }} />
                </IconButton>
              </InternalNotes>
            </Box>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ mt: 2, display: "flex", columnGap: 2, flexWrap: "wrap", alignItems:'center' }}>
            {coloredTags.map((data) => {
              return (
                <Chip
                  key={data.Id}
                  label={data?.Name}
                  // onDelete={
                  //   data.label === "React" ? undefined : handleDelete(data?.Id)
                  // }
                  sx={{
                    color:"inherit",
                    background: data.bgColor,
                    fontSize: "10px",
                    fontFamily: "outfit",
                    mb:1
                  }}
                />
              );
            })}
            <AddTags name={`${datas?.FirstName} ${datas?.LastName}`}  data={datas} callback={action} />
          </Box>
        </Box>
        {selectedButton === 1 ? (
          <>
            <Box sx={{ mt: 7, px: 4 }}>
              <Typography sx={{ fontWeight: 500 }}>
                Contact Information
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  bgcolor: "background.card",
                  borderRadius: 2,
                  px: 0,
                  py: 4,
                  borderRadius: "8px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                }}
              >
                <Grid
                  container
                  sx={{
                    borderBottom: "0.5px solid ",
                    borderColor: "text.primary",
                    px: 3,
                  }}
                >
                  <Grid item lg={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      First Name
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >{`${datas?.FirstName || "Not provided"} `}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    borderBottom: "0.5px solid ",
                    borderColor: "text.primary",
                    px: 3,
                    py: 2,
                  }}
                >
                  <Grid item lg={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      Last Name
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >{`${datas?.LastName || "Not provided"}`}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    borderBottom: "0.5px solid ",
                    borderColor: "text.primary",
                    px: 3,
                    py: 2,
                  }}
                >
                  <Grid item lg={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      Phone Number
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >{`${datas?.PhoneNumber || "Not provided"}`}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    borderBottom: "0.5px solid ",
                    borderColor: "text.primary",
                    px: 3,
                    py: 2,
                  }}
                >
                  <Grid item lg={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      Email Address
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >{`${datas?.EmailAddress || "Not provided"}`}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ mt: 5, px: 4 }}>
              <Typography sx={{ fontWeight: 500 }}>Notable Dates</Typography>
              <Box
                sx={{
                  mt: 2,
                  px: 0,
                  py: 4,
                  bgcolor: "background.card",

                  borderRadius: "8px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                }}
              >
                <Grid
                  container
                  sx={{
                    borderBottom: "0.5px solid ",
                    borderColor: "text.primary",
                    px: 3,
                    py: 2,
                  }}
                >
                  <Grid item lg={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      Birthday
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >{`${
                      datas?.Birthday ? datas?.Birthday : "No Birthday"
                    }`}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    borderBottom: "0.5px solid ",
                    borderColor: "text.primary",
                    px: 3,
                    py: 2,
                  }}
                >
                  <Grid item lg={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      Anniversary
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >{`${
                      datas?.WeddingAnniversary
                        ? datas?.WeddingAnniversary
                        : "No Anniversary"
                    }`}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ mt: 3, px: 4 }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Visit History
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Table
                    sx={{
                      borderRadius: "8px",
                      bgcolor: "background.card",

                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            fontWeight: 500,

                            fontSize: "14px",
                            fontFamily: "outfit",
                            borderBottom: "0.5px solid ",
                            borderColor: "text.primary",
                          }}
                        >
                          Upcoming
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontWeight: 500,

                            fontSize: "14px",
                            fontFamily: "outfit",
                            borderBottom: "0.5px solid ",
                            borderColor: "text.primary",
                          }}
                        >
                          Visit
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontWeight: 500,

                            fontSize: "14px",
                            fontFamily: "outfit",
                            borderBottom: "0.5px solid ",
                            borderColor: "text.primary",
                          }}
                        >
                          Cancellations
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontWeight: 500,

                            fontSize: "14px",
                            fontFamily: "outfit",
                            borderBottom: "0.5px solid ",
                            borderColor: "text.primary",
                          }}
                        >
                          No shows
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            fontWeight: 500,

                            fontSize: "14px",
                            fontFamily: "outfit",
                            border: "none",
                          }}
                        >
                          {datas?.UpcomingBookings
                            ? datas?.UpcomingBookings
                            : 0}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontWeight: 500,

                            fontSize: "14px",
                            fontFamily: "outfit",
                            border: "none",
                          }}
                        >
                          {datas?.BookingAttendance}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontWeight: 500,

                            fontSize: "14px",
                            fontFamily: "outfit",
                            border: "none",
                          }}
                        >
                          {datas?.CancelledBookings
                            ? datas?.CancelledBookings
                            : 0}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontWeight: 500,

                            fontSize: "14px",
                            fontFamily: "outfit",
                            border: "none",
                          }}
                        >
                          {datas?.NoShowForBookings
                            ? datas?.NoShowForBookings
                            : 0}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>

              {/* <Box sx={{ mt: 5 }}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                            color: "#fff",
                          }}
                        >
                          Upcoming
                        </Typography>
                        <Box
                          sx={{
                            mt: 3,
                            p: 4,
                            bgcolor: "#1a1a1a",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#fff",
                              }}
                            >
                              Thursday, April 30, 2023
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#fff",
                                mt: 2,
                              }}
                            >
                              Orchid Bistro Premium
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#fff",
                                mt: 2,
                              }}
                            >
                              5 Guest
                            </Typography>
                            <Button
                              variant="outlined"
                              disabled
                              sx={{
                                mt: 3,
                                color: "#fff",
                                "&:disabled": {
                                  border: "0.5px solid #fff",
                                  color: "#fff",
                                  bgcolor: "#555",
                                  borderRadius: "5px",
                                },
                              }}
                            >
                              Booked
                            </Button>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#fff",
                                mt: 2,
                              }}
                            >
                              11:30 PM
                            </Typography>
                          </Box>
                        </Box>
                      </Box> */}
              <Box sx={{ mt: 5 }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  History
                </Typography>

                {!datas?.guestReservationHitory ||
                datas?.guestReservationHitory.length === 0 ? (
                  <>
                    <Box
                      sx={{
                        mt: 3,
                        p: 4,
                        bgcolor: "background.card",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        boxShadow:
                          "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                      }}
                    >
                      <Typography sx={{ color: "#ccc", fontWeight: 400 }}>
                        No History Records
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      {datas?.guestReservationHitory?.map((item, index) => (
                        <>
                          <Grid item md={3}>
                            <Box
                              sx={{
                                mt: 3,
                                p: 4,
                                bgcolor: "background.card",
                                borderRadius: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                mb: 2,
                                boxShadow:
                                  "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                              }}
                            >
                              <Box key={index} sx={{}}>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {formatDate(item?.Date)}
                                </Typography>
                                {/* <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                               
                                mt: 2,
                              }}
                            >
                              Orchid Bistro Premium
                            </Typography> */}
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: 500,

                                    mt: 2,
                                  }}
                                >
                                  {item?.Time}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: 500,

                                    mt: 2,
                                  }}
                                >
                                  {item?.PartySize} Guest
                                </Typography>
                                <Button
                                  variant="outlined"
                                  disabled
                                  sx={{
                                    mt: 3,

                                    "&:disabled": {
                                      border: "0.5px solid #fff",
                                      color: "#fff",
                                      bgcolor: "#555",
                                      borderRadius: "5px",
                                    },
                                  }}
                                >
                                  {item?.Status}
                                </Button>
                              </Box>
                              <Box>
                                {/* <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#fff",
                                mt: 2,
                              }}
                            >
                          {item?.Time}
                            </Typography> */}
                              </Box>
                            </Box>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </>
                )}
              </Box>

              <Box sx={{ my: 5 }} align="right">
                <DeleteGuest id={datas?.Id} action={action} />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default AdvancedGuestOutlet;

function formatDate(dateString) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  const dayOfWeek = days[date.getDay()];

  return `${dayOfWeek}, ${month} ${day}, ${year}`;
}
