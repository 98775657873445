import React, { useState, useEffect } from "react";
import { getOpeningHours, manageReservation } from "../../../../../api";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Grid,
  Divider,
  InputLabel,
  TextField,
  InputAdornment,
  LinearProgress,
  Button,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import Loader from "../../../../Common/Loader";
import img1 from "../../../../../assets/Icons/guestMgt/1.svg";
import img2 from "../../../../../assets/Icons/guestMgt/2.svg";
import img3 from "../../../../../assets/Icons/guestMgt/3.svg";
import img4 from "../../../../../assets/Icons/guestMgt/4.svg";
import img5 from "../../../../../assets/Icons/guestMgt/5.svg";
import people from "../../../../../assets/Icons/people.svg";
import dayjs from "dayjs";
import { CiSquarePlus } from "react-icons/ci";
import { CiSquareMinus } from "react-icons/ci";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const ManageReservation = ({
  data,
  handleAlert,
  openDrawer,
  toggleDrawer,
  borderColor,
  action,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [guest, setGuest] = useState(1);
  const [convertedDate, setConvertedDate] = useState("");
  const [time, setTime] = useState(null);
  const [isTimeLoading, setTimeLoading] = useState(false);
  const [timeError, setTimeError] = useState(null);
  const [timeList, setTimeList] = useState(null);
  const [internalNotes, setInternalNotes] = useState("");



  const selectedRestaurant = localStorage.getItem("restaurantID");

  useEffect(() => {
    setGuest(parseFloat(data?.Guest));
    const year = new Date().getFullYear()
    const originalDate = `${data?.BookingDate} ${year}`;
    const formattedDate = dayjs(originalDate)
      .add(0, "day")
      .format("YYYY-MM-DD");
    setConvertedDate(formattedDate);
  }, [data]);



  useEffect(() => {
    setInternalNotes(data?.InternalNote);
  }, [data]);

  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setDate(formattedDate);
  };

    useEffect(() => {
    setDate(convertedDate);
  }, [convertedDate]);

  const hanleManageReservation = async () => {
    setIsLoading(true);
    await manageReservation(data?.BoookingId, guest, date, time, internalNotes)
      .then((res) => {
        if (res?.data?.status) {
          handleAlert("success", `${res?.data?.success_message}`);
          toggleDrawer(false);
          action();
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        handleAlert("error", `${err.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGetHours = async () => {
    setTimeLoading(true);
    setTimeList(null);
    setTimeError(null);

    await getOpeningHours(selectedRestaurant, date, guest)
      .then((res) => {
        setTimeLoading(false);

        if (res?.data?.status) {
          setTimeList(res?.data?.result[0]?.GroupedTime);
        } else {
          setTimeError(res?.data?.error_message);
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setTimeLoading(false);

        handleAlert("error", `${err}`);
      });
  };

    useEffect(() => {
    if (guest && date && openDrawer) {
      handleGetHours();
    }
  }, [guest, date, openDrawer]);

  return (
    <>
      {isLoading && <Loader />}
      <Drawer
        open={openDrawer}
        onClose={() => toggleDrawer(false)}
        anchor="right"
      >
        <Box
          sx={{
            bgcolor: "background.default",
            width: { lg: "600px", md: "70vw", sm: "80vw", xs: "95vw" },
            border: "0.5px solid #242424",
            height: "100vh",
            p: 5,
            boxSizing: "border-box",
            overflow: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontFamily: "optima", fontSize: "20px", fontWeight: 600 }}
            >
              Manage Reservation
            </Typography>
            <IconButton onClick={() => toggleDrawer(false)}>
              <CloseOutlined style={{ fontSize: "30px" }} />
            </IconButton>
          </Box>
          <Grid container spacing={2} sx={{ mt: 4, opacity: 0.4 }}>
            <Grid item lg={12} md={12}>
              <Box
                sx={{
                  p: 2,
                  py: 1,
                  borderRadius: "10px 10px 0px 0px",
                  bgcolor: "background.paper",
                  borderBottom: `1px solid ${borderColor}`,
                  display: "flex",
                  columnGap: 8,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                    width: "50%",
                  }}
                >
                  <AccountCircleRoundedIcon
                    sx={{
                      color: "#D9D9D9",
                      fontSize: {
                        lg: "40px",
                        md: "40px",
                        sm: "30px",
                        xs: "25px",
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "#fff",
                      fontSize: {
                        lg: "12px",
                        md: "12px",
                        sm: "12px",
                        xs: "10px",
                      },
                      textTransform: "capitalize",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data?.bookingUserDetails?.Name
                      ? data?.bookingUserDetails?.Name
                      : "No Name"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    width: "50%",
                  }}
                >
                  <img src={people} width={20} alt="people_icon" />
                  <Typography
                    sx={{
                      fontWeight: 400,
                      color: "#fff",
                      fontSize: {
                        lg: "12px",
                        md: "12px",
                        sm: "12px",
                        xs: "10px",
                      },
                    }}
                  >
                    {data?.Guest ? data?.Guest : "--"}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ bgcolor: "background.card", py: 3, px: 2 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img1} width={15} alt="icon" />{" "}
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                          fontWeight: 500,
                        }}
                      >
                        {data?.RestaurantName ? data?.RestaurantName : "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img2} width={15} alt="icon" />{" "}
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                          fontWeight: 500,
                        }}
                      >
                        {data?.BookingTime ? data?.BookingTime : "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img3} width={15} alt="icon" />{" "}
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                          fontWeight: 500,
                        }}
                      >
                        {data?.bookingUserDetails?.PhoneNumber
                          ? data?.bookingUserDetails?.PhoneNumber
                          : "No Name"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img4} width={15} alt="icon" />{" "}
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                          fontWeight: 500,
                        }}
                      >
                        {data?.BookingDate ? data?.BookingDate : "No Name"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img5} width={15} alt="icon" />{" "}
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                          fontWeight: 500,
                        }}
                      >
                        {data?.bookingUserDetails?.Email
                          ? data?.bookingUserDetails?.Email
                          : "No Name"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 3 }} />
          <Box sx={{ margin: "0 auto", mt: 5, width: "80%" }}>
            <InputLabel sx={{ fontSize: "12px" }}>Guest Size</InputLabel>
            <TextField
              margin="dense"
              fullWidth
              value={guest}
              InputProps={{
                style: {
                  borderRadius: "8px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Box>
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() => {
                          if (guest > 1) {
                            setGuest(guest - 1);
                          }
                        }}
                      >
                        <CiSquareMinus style={{ fontSize: "35px" }} />
                      </IconButton>
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() => setGuest(guest + 1)}
                      >
                        <CiSquarePlus style={{ fontSize: "35px" }} />
                      </IconButton>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            <InputLabel sx={{ fontSize: "14px", mt: 2 }}>Date</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast
                onChange={handleDateChange}
                defaultValue={dayjs(date)}
                slotProps={{
                  textField: {
                    sx: {
                      borderRadius: "8px",
                      mt: 1,
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px", // Ensures the border-radius is applied correctly
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>

            <InputLabel sx={{ fontSize: "14px", mt: 2 }}>
              Avaliable Time
            </InputLabel>

            {isTimeLoading ? (
              <>
                <Box sx={{ mt: 2 }}>
                  <LinearProgress />

                  <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
                    Getting Available Time
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                {timeList && (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      mt: 2,
                      maxHeight: "200px",
                      overflow: "scroll",
                    }}
                  >
                    {timeList[0]?.map((item, index) => (
                      <Grid item md={3} sm={4} xs={4} key={index}>
                        <Box
                          onClick={() => setTime(item?.Time)}
                          sx={{
                            bgcolor: "#333",
                            height: "45px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            display: "grid",
                            placeItems: "center",
                            transition: "0.2s all linear",
                            ...(time === item?.Time && {
                              bgcolor: "#BC172F",
                            }),
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: 500,
                              textAlign: "center",
                              fontSize: {
                                lg: "12px",
                                md: "12px",
                                sm: "12px",
                                xs: "10px",
                              },
                            }}
                          >
                            {item?.Time}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                    {timeList[1] &&
                      timeList[1]?.map((item, index) => (
                        <Grid item md={3} sm={4} xs={4} key={index}>
                          <Box
                            onClick={() => setTime(item?.Time)}
                            sx={{
                              bgcolor: "#333",
                              height: "45px",
                              borderRadius: "5px",
                              cursor: "pointer",
                              display: "grid",
                              placeItems: "center",
                              transition: "0.2s all linear",
                              ...(time === item?.Time && {
                                bgcolor: "#BC172F",
                              }),
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                fontWeight: 500,
                                textAlign: "center",
                                fontSize: {
                                  lg: "12px",
                                  md: "12px",
                                  sm: "12px",
                                  xs: "10px",
                                },
                              }}
                            >
                              {item?.Time}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                )}
                {timeError ||
                  (!timeList && (
                    <Typography color="primary" sx={{}}>
                      {timeError}
                    </Typography>
                  ))}
              </>
            )}
            <Box>
              <InputLabel sx={{ mt: 1 }}>Internal Notes</InputLabel>

              <TextField
                fullWidth
                value={internalNotes}
                onChange={(e) => setInternalNotes(e.target.value)}
                placeholder="Add any internal notes or comments here. 
"
                helperText="This information is not visible to the diner."
                multiline
                rows={5}
                margin="dense"
              />
            </Box>

            <Button
              disabled={!time || !guest || !date}
              variant="contained"
              fullWidth
              sx={{ height: "50px", borderRadius: "10px", mt: 5 }}
              onClick={hanleManageReservation}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default ManageReservation;
