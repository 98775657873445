import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Modal,
  TextField,
  IconButton,
  InputLabel,
  Divider,
  LinearProgress,
  ListItemButton,
  CircularProgress,
  InputAdornment,
} from "@mui/material";

import { IoMailOutline } from "react-icons/io5";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CloseOutlined } from "@mui/icons-material";
import { IoImageOutline } from "react-icons/io5";
import { sendEmail } from "../../../../api";
import Loader from "../../../Common/Loader";
import { useSnackbar } from "notistack";
import { SiMinutemailer } from "react-icons/si";
import { getFormattedDateTime } from "../../../../helpers/Format";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: "70vw", lg: "80vw", md: "80vw" },
  bgcolor: "#333333",
  boxShadow: 24,
  borderRadius: "8px",
  boxSizing: "border-box",
};

const SendEmail = ({ selectedGuest }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const selectedRestaurant = localStorage.getItem("restaurantName");

  const templates = [
    {
      name: "Reservations",
      defaultTemplate:
        '<p><strong>Hi </strong><strong style="color: #FFDFF7;">@Model.Name</strong><strong>,</strong></p><p><br></p><p>We are delighted to invite you to a special "Dine for Free" event, an exclusive evening of exquisite cuisine and delightful company. Join us for an unforgettable dining experience, where you can savor a variety of gourmet dishes prepared by our renowned chefs, all on the house! This event is our way of expressing gratitude to our valued guests like you.</p><p><br></p><p>Event Details:</p><ul><li>Date: [Event Date]</li><li>Time: [Event Time]</li><li>Location: [Event Venue], [Venue Address]</li></ul><p><br></p><p>Please RSVP by [RSVP Date] to confirm your attendance. You can RSVP by replying to this email or by calling us at [Phone Number].</p><p><br></p><p>We look forward to welcoming you and sharing a wonderful evening together.</p><p><br></p><p>Thanks,</p><p><strong>Restaurant Name</strong></p>',
    },
    {
      name: "Feedback survey",
      defaultTemplate: `<p>${getFormattedDateTime()}</p><p><br></p><h2>Your Feedback Matters - Share Your Dining Experience with Us</h2><p><br></p><p>Dear Paul Kehinde,</p><p><br></p><p>We hope you had a fantastic dining experience at ${selectedRestaurant}. We value your opinion and would like to hear your thoughts about your visit.</p><p><br></p><p>Please take a few minutes to complete our post-dining survey. Your feedback will help us enhance our services and ensure that every visit to ${selectedRestaurant} is memorable.</p><p><br></p><p>SurveryLink:&nbsp;<span style="color: rgb(188, 23, 47);">www.reisty.com/postdiningsurvey/Bistro-at-the-Theatre</span></p><p><span style="color: rgb(188, 23, 47);">﻿</span></p><p>Thank you for choosing ${selectedRestaurant}. We appreciate your support and look forward to welcoming you back soon. If have any questions or need any support, contact:</p><p><br></p><p><strong>Phone:</strong><a href="about:blank" rel="noopener noreferrer" target="_blank" style="color: rgb(188, 23, 47);"><strong>&nbsp;[Restaurant Phone Number]</strong></a></p><p><strong>Email:&nbsp;&nbsp;</strong><a href="mailto:" rel="noopener noreferrer" target="_blank" style="color: rgb(188, 23, 47);"><strong>[Restaurant Email Address]</strong></a></p><p><br></p><p><strong>Thanks,</strong></p><p><strong>The Reisty Team.</strong></p>`,
    },
    {
      name: "Event",
      defaultTemplate: `<p>Dear </strong><strong style="color: #FFDFF7;">@Model.Name</strong><strong></p><p><br></p><p>We are thrilled to confirm your ticket booking for the upcoming [EventName] scheduled to take place on [EventDate] at [EventAddress] Thank you for choosing to be a part of this exciting event!</p><p><br></p><p>Here are the details of your booking:</p><p>Event: [EventName]</p><p>Date: [EventDate]</p><p>Time: [EventTime]</p><p>Venue: [EventAddress]</p><p><br></p><p>Ticket Details:</p><p><br></p><p><br></p><p>Ticket Type: [Ticket Type]</p><p>Quantity: [Ticket Quantity]</p><p>Total Cost: [Cost]</p><p>Ticket Id: [ID]</p><p>Referral code:</p><p><br></p><p>If have any questions or need any support, contact:</p><p>support@reisty.com</p><p><br></p><p>Thanks,</p><p>The Reisty Team.</p>`,
    },
    {
      name: "Empty",
      defaultTemplate: "",
    },
  ];

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const [active_template, setActiveTemplate] = useState(templates[0]);
  const [selectedTemplate, setSelectedTemplate] = useState(
    active_template.defaultTemplate
  );

  useEffect(() => {
    setSelectedTemplate(active_template?.defaultTemplate);
  }, [active_template]);

  const handleChange = (value) => {
    setSelectedTemplate(value);
  };

  const [subject, setSubject] = useState("");

  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [restaurantEmail, setRestaurantEmail] = useState("");

  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // setUploadFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURL = e.target.result;
        setSelectedFileURL(dataURL);
      };
      reader.readAsDataURL(file);
    }
  };

  const transformedData = selectedGuest.map(
    (item) => `${item.email}_${item.name}`
  );

  // console.log(selectedTemplate)

  const handleSendEmail = async () => {
    setIsLoading(true);
    setIsSuccessful(false);
    await sendEmail(
      selectedTemplate,
      subject,
      selectedFile,
      transformedData,
      restaurantEmail
    )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          setIsSuccessful(true);
          handleAlert("success", `Email sent successfully`);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert("error", `${err?.message}`);
      });
  };

  useEffect(() => {
    if (isSuccessful) {
      setTimeout(() => {
        setIsSuccessful(false);
      }, 3000);
    }
  }, [isSuccessful]);

  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setRestaurantEmail(emailValue);

    if (!validateEmail(emailValue)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        startIcon={<IoMailOutline />}
        sx={{
          bgcolor: "#fff",
          px: 3,
          borderRadius:'8px',
          whiteSpace:'nowrap', textOverflow:'ellipsis',
          "&:disabled": {
            bgcolor: "#333",
          },
        }}
        disabled={selectedGuest?.length === 0}
      >
        <InputLabel sx={{color:'primary.main'}}
        >

        Send mail
        </InputLabel>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isLoading && <Loader />}
          <Box
            sx={{
              px: 4,
              height: "58px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              bgcolor: isSuccessful ? "#10893e" : "#333333",
              borderRadius: "8px 8px 0px 0px",
              borderBottom: "1px solid",
              borderColor: "primary.main",
            }}
          >
            {isLoading ? (
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    columnGap: 3,
                  }}
                >
                  <Typography
                    sx={{ mb: 1, textAlign: "center", color: "#ccc" }}
                  >
                    Sending...
                  </Typography>
                  <CircularProgress size={15} />
                </Box>
              </>
            ) : (
              <>
                {isSuccessful ? (
                  <>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        my: 1,
                        fontSize: "12px",
                      }}
                    >
                      Email Sent Successfully{" "}
                      <SiMinutemailer style={{ fontSize: "20px" }} />
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: 700,
                        fontFamily: "optima",
                        fontSize: "16px",
                      }}
                    >
                      Email - {selectedGuest?.length} Guest selected
                    </Typography>

                    <IconButton onClick={handleClose}>
                      <CloseOutlined sx={{ color: "#fff", fontSize: "20px" }} />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </Box>
          <Box
            className="show_scrollbar"
            sx={{ display: "flex", height: "75vh", overflowY: "scroll" }}
          >
            <Box
              sx={{
                width: "20%",
                bgcolor: "#31353B",
                height: "780px",
                boxSizing: "border-box",
                p: 0,
              }}
            >
              <Typography sx={{ fontSize: "16px", p: 3 }}>
                Templates 
              </Typography>
              <Box sx={{ mt: 4 }}>
                {templates?.map((tem, index) => (
                  <ListItemButton
                    key={index}
                    onClick={() => {
                      setActiveTemplate(tem);
                      // console.log(tem, active_template);
                    }}
                    selected={tem?.name == active_template?.name}
                    sx={{
                      mb: 2,
                      "&.Mui-selected": {
                        borderLeft: "2px solid",
                        borderColor: "primary.main",
                      },
                    }}
                  >
                    <Typography sx={{ fontSize: "16px", py: 1, ml: 2 }}>
                      {tem.name}
                    </Typography>
                  </ListItemButton>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                px: 0,
                pb: 2,
                bgcolor: "#3F4349",
                width: "80%",
                height: "780px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 2,
                  height: "50px",
                  borderBottom: "0.43px solid #BCBCBC",
                  px: 4,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#FFDFF7",
                    width: "8%",
                  }}
                >
                  From :
                </Typography>
                <TextField
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                  value={restaurantEmail}
                  onChange={handleEmailChange}
                  placeholder="your email"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          sx={{
                            color: "#ed4337",
                            fontSize: "10px",
                            fontWeight: 300,
                          }}
                        >
                          {emailError || ""}
                        </Typography>
                      </InputAdornment>
                    ),
                    style: {
                      fontFamily: "outfit",
                      fontSize: "14px",
                      color: "#fff",
                      fontWeight: 500,
                    },
                  }}
                  size="small"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 2,
                  height: "50px",
                  borderBottom: "0.43px solid #BCBCBC",
                  px: 4,
                }}
              >
                <Typography
                  color={"primary"}
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#FFDFF7",
                    whiteSpace: "nowrap",
                    width: "8%",
                  }}
                >
                  To :
                </Typography>
                <InputLabel
                  sx={{ fontWeight: 500, fontSize: "14px", color: "#fff" }}
                >
                  {selectedGuest &&
                    selectedGuest?.map((guest) => guest?.email).join(", ")}
                </InputLabel>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 2,
                  height: "50px",
                  borderBottom: "0.43px solid #BCBCBC",
                  px: 4,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#FFDFF7",
                    width: "8%",
                  }}
                >
                  Subject :
                </Typography>

                <TextField
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="Subject"
                  InputProps={{
                    style: {
                      fontFamily: "outfit",
                      fontSize: "14px",
                      color: "#fff",
                      fontWeight: 500,
                    },
                  }}
                  size="small"
                  id="custom-css-outlined-input"
                />
              </Box>
              <Box sx={{ mt: 2, px: 4 }}>
                <ReactQuill
                  theme="snow"
                  value={selectedTemplate}
                  onChange={handleChange}
                />
              </Box>
              {selectedFile && (
                <Box sx={{ mt: 2, px: 4 }}>
                  <Box
                    sx={{
                      background: `url('${selectedFileURL}')`,
                      height: "60px",
                      width: "80px",
                      backgroundSize: "cover",
                    }}
                  />
                  <Typography sx={{ color: "#ccc", fontSize: "10px" }}>
                    {selectedFile?.name}
                    <IconButton onClick={() => setSelectedFile(null)}>
                      <CloseOutlined
                        sx={{ fontSize: "14px", color: "#BC172F" }}
                      />
                    </IconButton>{" "}
                  </Typography>
                </Box>
              )}
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  mt: 2,
                  px: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box></Box>
                {/* <Button
                startIcon={<IoImageOutline />}
                sx={{ color: "#fff" }}
                onClick={handleButtonClick}
              >
                Add Image
              </Button> */}
                {/* <input
                  accept="image/*"
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                /> */}
                <Button
                  onClick={handleSendEmail}
                  disabled={
                    !subject ||
                    !active_template?.defaultTemplate ||
                    !selectedGuest ||
                    !restaurantEmail
                  }
                  sx={{ height: "45px", borderRadius: "8px", width: "145px" }}
                  variant="contained"
                >
                  Send Now
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SendEmail;
