import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import {
  changeBookingStatus,
  deleteWaitlist,
  getOpeningHours,
  manageReservation,
  removeFromWaitlist,
} from "../../../../api";
import Loader from "../../../Common/Loader";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Grid,
  Modal,
  InputLabel,
  Divider,
  Drawer, TextField, InputAdornment, LinearProgress
} from "@mui/material";
import SellIcon from "@mui/icons-material/Sell";


import img1 from "../../../../assets/Icons/guestMgt/1.svg";
import img2 from "../../../../assets/Icons/guestMgt/2.svg";
import img3 from "../../../../assets/Icons/guestMgt/3.svg";
import img4 from "../../../../assets/Icons/guestMgt/4.svg";
import img5 from "../../../../assets/Icons/guestMgt/5.svg";
import crown from "../../../../assets/Icons/crown.svg";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { CloseOutlined } from "@mui/icons-material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Person4OutlinedIcon from "@mui/icons-material/Person4Outlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import people from "../../../../assets/Icons/people.svg";
import note from "../../../../assets/Icons/document-text.svg";
import forbidden from "../../../../assets/Icons/forbidden.svg";
import Notes from "../Notes/Notes";
import { MdOutlineCancel } from "react-icons/md";
import { CiSquarePlus } from "react-icons/ci";
import { CiSquareMinus } from "react-icons/ci";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";



const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md: 400, sm: 400, xs: 350 },
  maxHeight: "70vh",
  overflow: "scroll",
  bgcolor: "#1a1a1a",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  boxSizing: "border-box",
};


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 700, md: 700, sm: 400, xs: 350 },
  maxHeight: "70vh",
  overflow: "scroll",
  bgcolor: "#1a1a1a",
  borderRadius: 2,
  boxShadow: 24,
  p: 0,
};

const WaitlistCard = ({ borderColor, data, action, finished, upcoming }) => {
  const [open, setOpen] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [date, setDate] = useState(null);
  const [guest, setGuest] = useState(1);
  const [convertedDate, setConvertedDate] = useState("");

  useEffect(() => {
    setGuest(parseFloat(data?.Guest));
    // The original date
    const originalDate = `${data?.BookingDate} 2024`;

    // Convert the original date and add 1 day
    const formattedDate = dayjs(originalDate)
      .add(0, "day")
      .format("YYYY-MM-DD");

    // Set the converted date to state
    setConvertedDate(formattedDate);
  }, [data]);

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = (newOpen) => {
    setOpenDrawer(newOpen);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleModifyReservation = async (status) => {
    setIsLoading(true);
    await changeBookingStatus(data?.BoookingId, status)
      .then((res) => {
        setIsLoading(false);
        action();
        setOpen6(false);
        setOpen4(false);
        setOpen5(false);
        setOpen(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleAcceptWaitlist = async () => {
    setIsLoading(true);
    await removeFromWaitlist(data?.Id)
      .then((res) => {
        setIsLoading(false);
        action();
        setOpen6(false);
        setOpen4(false);
        setOpen5(false);
        setOpen(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setDate(convertedDate);
  }, [convertedDate]);
  const selectedRestaurant = localStorage.getItem("restaurantID");

  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setDate(formattedDate);
  };

  const [time, setTime] = useState(null);
  const [isTimeLoading, setTimeLoading] = useState(false);
  const [timeError, setTimeError] = useState(null);
  const [timeList, setTimeList] = useState(null);

  const handleGetHours = async () => {
    setTimeLoading(true);
    setTimeList(null);
    setTimeError(null);

    await getOpeningHours(selectedRestaurant, date, guest)
      .then((res) => {
        setTimeLoading(false);

        if (res?.data?.status) {
          setTimeList(res?.data?.result[0]?.GroupedTime);
        } else {
          setTimeError(res?.data?.error_message);
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setTimeLoading(false);

        handleAlert("error", `${err}`);
      });
  };

  useEffect(() => {
    if (guest && date && openDrawer) {
      handleGetHours();
    }
  }, [guest, date, openDrawer]);

  const restaurantName = localStorage.getItem("restaurantName");
  const isNostalgia = data?.RestaurantName === "NOSTALGIA LAGOS";

  const [internalNotes, setInternalNotes] = useState("");

  useEffect(() => {
    setInternalNotes(data?.InternalNote);
  }, [data]);

  const hanleManageReservation = async () => {
    setIsLoading(true);
    await manageReservation(data?.BoookingId, guest, date, time, internalNotes)
      .then((res) => {
        if (res?.data?.status) {
          handleAlert("success", `${res?.data?.success_message}`);
          setOpenDrawer(false);
          action();
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        handleAlert("error", `${err.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [open3, setOpen3] = useState(false);

  const handleDeleteWaitlist = async () => {
    setIsLoading(true);
    await deleteWaitlist(data?.Id)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          handleAlert(
            "success",
            `${res?.data?.success_message || "Waitlist Removal Successful "}`
          );
          action();
          setOpen3(false);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        handleAlert("error", `${err.message}`);
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Box
        onClick={handleOpen}
        sx={{
          p: 1,
          border: `2px solid ${borderColor}`,
          borderRadius: 1,
          height: "150px",
          cursor: "pointer",
          display: "flex",
          overflow: "hidden",
          justifyContent: "space-between",
          flexDirection: "column",
          "&:hover": {
            bgcolor: "#333",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 1,
            flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" },
            rowGap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
              width: "60%",
            }}
          >
            <SellIcon sx={{ color: "#fff", fontSize: "12px" }} />

            <InputLabel
              sx={{
                color: "#fff",
                fontWeight: 500,
                fontSize: { lg: "12px", md: "12px", sm: "12px", xs: "10px" },
              }}
            >
              {data?.BookingUserDetails?.Name
                ? data?.BookingUserDetails?.Name
                : "--"}
            </InputLabel>
          </Box>
          <Box
            sx={{
              bgcolor: "rgba(223, 142, 20, 0.40)",
              px: 1,
              borderRadius: 1,
              display: "flex",
              alignItems: "center",
              columnGap: 1,
              width: "38%",
            }}
          >
            <img src={crown} alt="crown_icon" />
            <InputLabel
              sx={{ color: "#fff", fontSize: "10px", lineHeight: "15px" }}
            >
              {data?.RestaurantName ? data?.RestaurantName : "--"}
            </InputLabel>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
          <TourOutlinedIcon sx={{ color: "#565660", fontSize: "12px" }} />
          <Typography
            sx={{
              color: "#fff",
              fontWeight: 400,
              fontSize: { lg: "12px", md: "12px", sm: "10px", xs: "10px" },
            }}
          >
            {data?.Date ? data?.Date : "--"}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", columnGap: 2, mt: 0 }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
              width: "100%",
            }}
          >
            <WidgetsOutlinedIcon sx={{ color: "#565660", fontSize: "12px" }} />
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 400,
                fontSize: { lg: "12px", md: "12px", sm: "10px", xs: "10px" },
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
                columnGap: 0.7,
              }}
            >
              {`${data?.Time} `}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
            <Person4OutlinedIcon sx={{ color: "#565660", fontSize: "12px" }} />
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 400,
                fontSize: { lg: "12px", md: "12px", sm: "10px", xs: "10px" },
              }}
            >
              {data?.Guest ? data?.Guest : "--"}
            </Typography>
          </Box>
          <IconButton>
            <MoreHorizOutlinedIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="hide_scrollbar">
          <Box
            sx={{
              p: 2,
              px: { lg: 4, md: 4, sm: 2, xs: 2 },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: `1px solid ${borderColor}`,
            }}
          >
            <Typography
              sx={{ color: "#fff", fontWeight: 400, fontFamily: "optima" }}
            >
              Waitlist Reservation
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlined sx={{ color: "#fff" }} />
            </IconButton>
          </Box>
          <Box sx={{ py: 3, px: { lg: 4, md: 4, sm: 2, xs: 2 } }}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    p: 2,
                    py: 1,
                    borderRadius: "10px 10px 0px 0px",
                    bgcolor: "#4d4d4d",
                    borderBottom: `1px solid ${borderColor}`,
                    display: "flex",
                    columnGap: { lg: 8, md: 8, sm: 5, xs: 4 },
                    alignItems: "center",
                    justifyContent: "space-between",
                    overflow: "scroll",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <AccountCircleRoundedIcon
                      sx={{
                        color: "#D9D9D9",
                        fontSize: {
                          lg: "40px",
                          md: "40px",
                          sm: "30px",
                          xs: "20px",
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: "#fff",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "12px",
                          xs: "10px",
                        },
                        textTransform: "capitalize",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data?.BookingUserDetails?.Name
                        ? data?.BookingUserDetails?.Name
                        : "N/A"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                    }}
                  >
                    <img src={people} width={20} alt="people_icon" />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#fff",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "12px",
                          xs: "10px",
                        },
                      }}
                    >
                      {data?.Guest ? data?.Guest : "--"}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ bgcolor: "#333", py: 3, px: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img src={img1} width={15} alt="icon" />{" "}
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              lg: "12px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                            fontWeight: 500,
                          }}
                        >
                          {data?.RestaurantName ? data?.RestaurantName : "--"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img src={img2} width={15} alt="icon" />{" "}
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              lg: "12px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                            fontWeight: 500,
                          }}
                        >
                          {data?.Time ? data?.Time : "--"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img src={img3} width={15} alt="icon" />{" "}
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              lg: "12px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                            fontWeight: 500,
                          }}
                        >
                          {data?.BookingUserDetails?.PhoneNumber
                            ? data?.BookingUserDetails?.PhoneNumber
                            : "N/A"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img src={img4} width={15} alt="icon" />{" "}
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              lg: "12px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                            fontWeight: 500,
                          }}
                        >
                          {data?.Date ? data?.Date : "No Date"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img src={img5} width={15} alt="icon" />{" "}
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              lg: "12px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                            fontWeight: 500,
                          }}
                        >
                          {data?.BookingUserDetails?.Email
                            ? data?.BookingUserDetails?.Email
                            : "N/A"}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ mt: 5 }}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box
                        sx={{
                          border: "1px dashed #fff",
                          p: 1.5,
                          borderRadius: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 2,
                          }}
                        >
                          <img src={note} width={15} alt="icon" />
                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: 400,
                              fontSize: "12px",
                            }}
                          >
                            Visit Notes
                          </Typography>
                        </Box>
                        <Notes
                          value={
                            data?.VisitNote ? data?.VisitNote : "No Visit  Note"
                          }
                          type="visit"
                          borderColor={borderColor}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "10px",
                              textDecoration: "underline",
                            }}
                          >
                            View
                          </Typography>
                        </Notes>
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box
                        sx={{
                          border: "1px dashed #fff",
                          p: 1.5,
                          borderRadius: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 2,
                          }}
                        >
                          <img src={forbidden} width={15} alt="icon" />
                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: 400,
                              fontSize: "12px",
                            }}
                          >
                            Dietary Restrictions
                          </Typography>
                        </Box>
                        <Notes
                          type="dietary"
                          value={
                            data?.DietaryRestriction
                              ? data?.DietaryRestriction
                              : "No Dietary Restrictions"
                          }
                          borderColor={borderColor}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "10px",
                              textDecoration: "underline",
                            }}
                          >
                            View
                          </Typography>
                        </Notes>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              px: 4,
              py: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderTop: "0.5px solid #fff",
              bgcolor: "#333",
              columnGap: 2,
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              rowGap: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                onClick={() => setOpen5(true)}
                variant="outlined"
                sx={{
                  color: "#E6475E",

                  borderRadius: 2,
                  border: "1px solid #E6475E",
                  fontWeight: 500,
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                }}
                size="large"
                startIcon={
                  <HighlightOffOutlinedIcon
                    sx={{ color: "#E6475E", fontSize: "12px" }}
                  />
                }
              >
                Cancel waitlist
              </Button>
            </Box>
            {!finished && (
              <Button
                onClick={() => setOpen6(true)}
                variant="contained"
                fullWidth
                startIcon={
                  <IoCheckmarkDoneCircleOutline style={{ fontSize: "18px" }} />
                }
                sx={{
                  // color: "#2b2b2b",
                  borderRadius: 2,
                  bgcolor: "#19A662",
                  fontWeight: 500,
                  "&:hover": {
                    bgcolor: "#19A662b7",
                  },
                }}
                size="large"
              >
                Accept Reservation
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      <Modal
        open={open5}
        onClose={() => setOpen5(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#fff",
              textAlign: "center",
              textDecoration: "line-through",
            }}
          >
            Cancel{" "}
            <b style={{ fontSize: "12px" }}>
              {" "}
              {data?.bookingUserDetails?.Name?.split(" ")[0] || "--"}
            </b>{" "}
            waitlist ?
          </Typography>
          <Typography
            sx={{ color: "#ccc", fontSize: "12px", textAlign: "center" }}
          >
            This action cannot be reversed.
          </Typography>
          <Box sx={{ display: "grid", placeContent: "center", mt: 2 }}>
            <MdOutlineCancel style={{ fontSize: "40px" }} />
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: 2, mt: 3 }}
          >
            <Button
              variant="outlined"
              onClick={() => setOpen5(false)}
              fullWidth
              sx={{ color: "#fff", borderColor: "#fff" }}
            >
              Not yet
            </Button>
            <Button
              onClick={() => handleDeleteWaitlist()}
              variant="contained"
              fullWidth
              sx={{}}
            >
              Yes, Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={open6}
        onClose={() => setOpen6(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <Typography
            sx={{ fontSize: "16px", color: "#fff", textAlign: "center" }}
          >
            Accept
            <b style={{ fontSize: "14px" }}>
              {" "}
              {data?.bookingUserDetails?.Name?.split(" ")[0] || "--"}
            </b>{" "}
            Reservation
          </Typography>
          <Typography
            sx={{ color: "#ccc", fontSize: "12px", textAlign: "center" }}
          >
            This action cannot be reversed.
          </Typography>
          <Box sx={{ display: "grid", placeContent: "center", mt: 2 }}>
            <IoCheckmarkDoneCircleOutline style={{ fontSize: "40px" }} />
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: 2, mt: 3 }}
          >
            <Button
              variant="outlined"
              onClick={() => setOpen6(false)}
              fullWidth
              sx={{ color: "#fff", borderColor: "#fff" }}
            >
              Not yet
            </Button>
            <Button
              onClick={() => handleAcceptWaitlist()}
              variant="contained"
              fullWidth
              sx={{
                bgcolor: "#19A662",
                "&:hover": {
                  bgcolor: "#19A662b7",
                },
              }}
            >
              Yes, Move
            </Button>
          </Box>
        </Box>
      </Modal>

      <Drawer
        open={openDrawer}
        onClose={() => toggleDrawer(false)}
        anchor="right"
      >
        <Box
          sx={{
            bgcolor: "background.default",
            width: { lg: "600px", md: "70vw", sm: "80vw", xs: "95vw" },
            border: "0.5px solid #242424",
            height: "100vh",
            p: 5,
            boxSizing: "border-box",
            overflow: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontFamily: "optima", fontSize: "20px", fontWeight: 600 }}
            >
              Manage Reservation
            </Typography>
            <IconButton onClick={() => toggleDrawer(false)}>
              <CloseOutlined style={{ fontSize: "30px" }} />
            </IconButton>
          </Box>
          <Grid container spacing={2} sx={{ mt: 4, opacity: 0.4 }}>
            <Grid item lg={12} md={12}>
              <Box
                sx={{
                  p: 2,
                  py: 1,
                  borderRadius: "10px 10px 0px 0px",
                  bgcolor: "background.paper",
                  borderBottom: `1px solid ${borderColor}`,
                  display: "flex",
                  columnGap: 8,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                    width: "50%",
                  }}
                >
                  <AccountCircleRoundedIcon
                    sx={{
                      color: "#D9D9D9",
                      fontSize: {
                        lg: "40px",
                        md: "40px",
                        sm: "30px",
                        xs: "25px",
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "#fff",
                      fontSize: {
                        lg: "12px",
                        md: "12px",
                        sm: "12px",
                        xs: "10px",
                      },
                      textTransform: "capitalize",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data?.bookingUserDetails?.Name
                      ? data?.bookingUserDetails?.Name
                      : "No Name"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    width: "50%",
                  }}
                >
                  <img src={people} width={20} alt="people_icon" />
                  <Typography
                    sx={{
                      fontWeight: 400,
                      color: "#fff",
                      fontSize: {
                        lg: "12px",
                        md: "12px",
                        sm: "12px",
                        xs: "10px",
                      },
                    }}
                  >
                    {data?.Guest ? data?.Guest : "--"}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ bgcolor: "background.card", py: 3, px: 2 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img1} width={15} alt="icon" />{" "}
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                          fontWeight: 500,
                        }}
                      >
                        {data?.RestaurantName ? data?.RestaurantName : "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img2} width={15} alt="icon" />{" "}
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                          fontWeight: 500,
                        }}
                      >
                        {data?.BookingTime ? data?.BookingTime : "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img3} width={15} alt="icon" />{" "}
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                          fontWeight: 500,
                        }}
                      >
                        {data?.bookingUserDetails?.PhoneNumber
                          ? data?.bookingUserDetails?.PhoneNumber
                          : "No Name"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img4} width={15} alt="icon" />{" "}
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                          fontWeight: 500,
                        }}
                      >
                        {data?.BookingDate ? data?.BookingDate : "No Name"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img5} width={15} alt="icon" />{" "}
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                          fontWeight: 500,
                        }}
                      >
                        {data?.bookingUserDetails?.Email
                          ? data?.bookingUserDetails?.Email
                          : "No Name"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 3 }} />
          <Box sx={{ margin: "0 auto", mt: 5, width: "80%" }}>
            <InputLabel sx={{ fontSize: "12px" }}>Guest Size</InputLabel>
            <TextField
              margin="dense"
              fullWidth
              value={guest}
              InputProps={{
                style: {
                  borderRadius: "8px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Box>
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() => {
                          if (guest > 1) {
                            setGuest(guest - 1);
                          }
                        }}
                      >
                        <CiSquareMinus style={{ fontSize: "35px" }} />
                      </IconButton>
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() => setGuest(guest + 1)}
                      >
                        <CiSquarePlus style={{ fontSize: "35px" }} />
                      </IconButton>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            <InputLabel sx={{ fontSize: "14px", mt: 2 }}>Date</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast
                onChange={handleDateChange}
                defaultValue={dayjs(date)}
                slotProps={{
                  textField: {
                    sx: {
                      borderRadius: "8px",
                      mt: 1,
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px", // Ensures the border-radius is applied correctly
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>

            <InputLabel sx={{ fontSize: "14px", mt: 2 }}>
              Avaliable Time
            </InputLabel>

            {isTimeLoading ? (
              <>
                <Box sx={{ mt: 2 }}>
                  <LinearProgress />

                  <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
                    Getting Available Time
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                {timeList && (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      mt: 2,
                      maxHeight: "200px",
                      overflow: "scroll",
                    }}
                  >
                    {timeList[0]?.map((item, index) => (
                      <Grid item md={3} sm={4} xs={4} key={index}>
                        <Box
                          onClick={() => setTime(item?.Time)}
                          sx={{
                            bgcolor: "#333",
                            height: "45px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            display: "grid",
                            placeItems: "center",
                            transition: "0.2s all linear",
                            ...(time === item?.Time && {
                              bgcolor: "#BC172F",
                            }),
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: 500,
                              textAlign: "center",
                              fontSize: {
                                lg: "12px",
                                md: "12px",
                                sm: "12px",
                                xs: "10px",
                              },
                            }}
                          >
                            {item?.Time}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                    {timeList[1] &&
                      timeList[1]?.map((item, index) => (
                        <Grid item md={3} sm={4} xs={4} key={index}>
                          <Box
                            onClick={() => setTime(item?.Time)}
                            sx={{
                              bgcolor: "#333",
                              height: "45px",
                              borderRadius: "5px",
                              cursor: "pointer",
                              display: "grid",
                              placeItems: "center",
                              transition: "0.2s all linear",
                              ...(time === item?.Time && {
                                bgcolor: "#BC172F",
                              }),
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                fontWeight: 500,
                                textAlign: "center",
                                fontSize: {
                                  lg: "12px",
                                  md: "12px",
                                  sm: "12px",
                                  xs: "10px",
                                },
                              }}
                            >
                              {item?.Time}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                )}
                {timeError ||
                  (!timeList && (
                    <Typography color="primary" sx={{}}>
                      {timeError}
                    </Typography>
                  ))}
              </>
            )}
            <Box>
              <InputLabel sx={{ mt: 1 }}>Internal Notes</InputLabel>

              <TextField
                fullWidth
                value={internalNotes}
                onChange={(e) => setInternalNotes(e.target.value)}
                placeholder="Add any internal notes or comments here. 
"
                helperText="This information is not visible to the diner."
                multiline
                rows={5}
                margin="dense"
              />
            </Box>

            <Button
              disabled={!time || !guest || !date}
              variant="contained"
              fullWidth
              sx={{ height: "50px", borderRadius: "10px", mt: 5 }}
              onClick={hanleManageReservation}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default WaitlistCard;
