import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Header";
import { CloseOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  InputLabel,
  useTheme,
  useMediaQuery,
  Checkbox,
  TextField,
  MenuItem,
  Divider,
  ListItemButton,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaRedo } from "react-icons/fa";

import CreateExperience from "../../Components/Experience/CreateExperience";
import { deleteEvent, editEvent, getEvent } from "../../../api";
import Loader from "../../Common/Loader";
import { useSnackbar } from "notistack";
import emptyRes from "../../../assets/img/emptyRes.svg";
import deleteIcon from "../../../assets/Icons/delete.svg";
import { Link } from "react-router-dom";
import { PiShareFat } from "react-icons/pi";
import { ImWhatsapp } from "react-icons/im";
import { RiTwitterXFill } from "react-icons/ri";
import { LiaCopy } from "react-icons/lia";
import CustomTextField from "../../Components/CustomField/CustomTextField";
import { FiFacebook } from "react-icons/fi";
import { MdEventAvailable, MdOutlineDone } from "react-icons/md";
import { BiCalendarEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import SEO from "../../../Utility/SEO";
import { ThemeContext } from "../../../STYLES/theme/ThemeContextProvider";
import DeleteModal from "../../Common/DeleteModal";
import CustomModal from "../../Common/CustomModal";
import RecurringEvent from "../../Components/Experience/RecurringEvent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "5px",
  boxShadow: 24,
  bgcolor: "#1a1a1a",
  borderRadius: "12px",
  pb: 3,
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: "5px",
  boxShadow: 24,
  bgcolor: "#1a1a1a",
  borderRadius: "12px",
  pb: 3,
};

const Experience = () => {
  const selectedRestaurant = localStorage.getItem("restaurantID");
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [copied, setCopied] = useState(false);
  const [eventItem, setEventItem] = useState(null);
  const [openOneTimeEvent, setOpenOneTimeEvent] = useState(false);
  const [openRecurringEvent, setOpenRecurringEvent] = useState(false);

  const handleOpenOneTimeEvent = () => {
    setOpenOneTimeEvent(true);
    setOpenCreateModal(false);
  };
  const handleRecurringEvent = () => {
    setOpenRecurringEvent(true);
    setOpenCreateModal(false);
  };
  const handleOpen = (id, name) => {
    setEventId(id);
    setName(name);
    setOpen(true);
  };
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = (name) => {
    setOpen2(true);
    setName(name);
  };
  const [open3, setOpen3] = useState(false);
  const handleOpen3 = (item) => {
    setOpen3(true);
    setEventItem(item);
  };
  const handleClose = () => setOpen(false);
  const [eventId, setEventId] = useState("");

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handleGetEvents = async () => {
    setLoading(true);
    await getEvent()
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setData(res?.data?.result);
        }
      })
      .catch((err) => {
        setLoading(false);

        handleAlert("error", `${err.message} `);
      });
  };

  const handleDelete = async () => {
    setLoading(true);
    await deleteEvent(eventId)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          handleAlert("success", `${name} Event, deleted successfully`);
          setOpen(false);
          handleGetEvents();
        }
      })
      .catch((err) => {
        setLoading(false);

        handleAlert("error", `${err.message} `);
      });
  };

  useEffect(() => {
    handleGetEvents();
  }, []);

  const url_event = `https://ticket.reisty.com/event/${name
    ?.split(" ")
    .join("-")}/${selectedRestaurant}`;

  const handleCopy = () => {
    navigator.clipboard
      .writeText(url_event)
      .then((res) => {
        setCopied(true);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const shareOnWhatsApp = () => {
    const message = `Check out our latest event: ${name} ${url_event}`;
    const url = `whatsapp://send?text=${encodeURIComponent(message)}`;
    window.open(url);
  };
  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url_event
    )}`;
    window.open(url, "_blank");
  };

  const shareOnTwitter = () => {
    const tweetText = encodeURIComponent(
      `Check out our latest event: ${name} ${url_event}`
    );
    const url = `https://twitter.com/intent/tweet?text=${tweetText}`;
    window.open(url, "_blank");
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [eventName, setEventName] = useState("");
  const [time, setTime] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [date, setDate] = useState(0);
  const [description, setDescription] = useState("");
  const handleChange = (value) => {
    setTextValue(value);
  };
  const [selectedFile, setSelectedFile] = useState(" ");
  const [textValue, setTextValue] = useState("");
  const [buffer, setBuffer] = useState({
    checked: false,
    value: "00:15:00",
  });

  const handleFile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  useEffect(() => {
    if (eventItem) {
      setEventName(eventItem?.EventName);
      setTime(eventItem?.EventTime);
      setDate(eventItem?.EventDate);
      setTextValue(eventItem?.Description);
      setFrom(eventItem?.DurationFrom);
      setTo(eventItem?.DurationTo);
      setBuffer({
        ...buffer,
        checked: eventItem?.BookingBuffer ? true : false,
        value: eventItem?.BookingBuffer,
      });
    }
  }, [eventItem]);

  const handleEditEvent = async () => {
    setLoading(true);
    await editEvent(
      eventItem?.EventId,
      true,
      eventName,
      textValue,
      date,
      time,
      selectedFile
    )
      .then((res) => {
        setLoading(false);
        if (res?.data?.status) {
          handleAlert("success", `${res?.data?.success_message}`);
          setOpen3(false);
          handleGetEvents();
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setLoading(false);
        handleAlert("error", `${err.message} `);
      });
  };
  const { isDarkTheme } = useContext(ThemeContext);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  return (
    <>
      <SEO
        title="Reisty | Restaurant Experiences Management"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
        canonicalUrl="https://restaurant.reisty.com/dashboard/experience"
      />
      {loading && <Loader />}
      <Box
        sx={{
          p: 2,

          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "80px",
          background: isDarkTheme ? "#1a1a1a" : "#ffff",
          borderBottom: "0.2px solid #ccccccb7",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 200,
              fontSize: "24px",
              fontWeight: 700,
              fontFamily: "optima",
            }}
          >
            {" "}
            Experience
          </Typography>
          <Typography
            variant="caption"
            sx={{ fontWeight: 200, fontSize: "12px", mt: 0.5 }}
          >
            {" "}
            Host Unforgettable Events: Easily Create, Manage, and Sell Tickets
            for Your Restaurant's Special Occasions.
          </Typography>
        </Box>
        <Button variant="contained" onClick={() => setOpenCreateModal(true)}>
          Create New Experience
        </Button>

        {/* <CreateExperience action={handleGetEvents} /> */}
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          width: "95%",
          height: "80vh",
          overflow: "scroll",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Box>
            <Typography
              variant="body1"
              sx={{ fontSize: "16px", fontWeight: 400, mb: 2 }}
            >
              Restaurant Experience
            </Typography>
          </Box>
        </Box>
        {!data || data.length === 0 ? (
          <>
            <Box
              sx={{
                height: "40vh",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mt: 5,
                borderRadius: "8px",
              }}
            >
              <img src={emptyRes} />
              <Typography sx={{ mt: 5, color: "#fff", fontWeight: 500 }}>
                No Events Available
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Grid container spacing={2}>
              {data.map((item, index) => (
                <Grid item md={4} sm={6} xs={12} key={index}>
                  <Box
                    sx={{
                      width: "100%",
                      // height: "300px",
                      bgcolor: "#1a1a1a",
                      p: 1,
                      pb: 0,
                      overflow: "hidden",
                      cursor: "pointer",
                      transition: "0.2s all linear",
                      borderRadius: "8px",
                      "&:hover": {
                        border: "1px solid #BC172F",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: "150px",
                        backgroundImage: `url('${item?.EventImage}')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        p: 2,
                        borderRadius: "8px",
                      }}
                    >
                      <Box
                        align="center"
                        sx={{
                          bgcolor: "#1a1a1ab7",
                          width: "fit-content",
                          p: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 900,
                            color: "#fff",
                            fontSize: "20px",
                          }}
                        >
                          {new Date(item?.EventDate).getDate()}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "#fff",
                          }}
                        >
                          {new Date(item?.EventDate).toLocaleString("default", {
                            month: "short",
                          })}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ p: 1, mt: 0 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: "#fff",
                            fontSize: "14px",
                            width: "60%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item?.EventName}
                        </Typography>
                        <Box>
                          <Typography sx={{ color: "#ccc", fontSize: "11px" }}>
                            Time
                          </Typography>
                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: 500,
                              fontSize: "14px",
                              mt: -1,
                            }}
                          >
                            {item?.EventTime}
                          </Typography>
                        </Box>
                      </Box>
                      {/* <Box sx={{ mt: 0, height: "32px" }}>
                        <ReactQuill
                          className="editor"
                          value={item?.Description.slice(0, 105)}
                          readOnly={true}
                          theme={null} // Disable theme
                          modules={{ toolbar: false }}
                        />
                      </Box> */}
                      <Box
                        sx={{
                          borderTop: "0.2px solid #ccc",
                          mt: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          pt: 2,
                        }}
                      >
                        <Link
                          to={`/dashboard/experience/tickets/${item?.EventName}/${item?.EventId}`}
                        >
                          <Button variant="contained">Ticket</Button>
                        </Link>
                        <Box sx={{}}>
                          <IconButton
                            onClick={() => handleOpen2(item?.EventName)}
                          >
                            <PiShareFat
                              style={{ color: "#fff", fontSize: "16px" }}
                            />
                          </IconButton>
                          <IconButton onClick={() => handleOpen3(item)}>
                            <BiCalendarEdit
                              style={{ color: "#fff", fontSize: "16px" }}
                            />
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              handleOpen(item?.EventId, item?.EventName)
                            }
                          >
                            <AiOutlineDelete
                              style={{ color: "#fff", fontSize: "16px" }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {/* <Button variant="contained" sx={{ mt: 3 }}>
          Add New
        </Button> */}
      </Box>

      {/* Modal for Name, contact and date */}

      <DeleteModal
        open={open}
        handleClose={() => setOpen(false)}
        handleDelete={handleDelete}
        isLoading={loading}
        name={`Event "${name}"`}
      />
      <Modal
        open={open2}
        onClose={() => setOpen2(!open2)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              p: 1,
              px: 3,
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              bgcolor: "#333",
              borderRadius: "12px 12px 0px 0px",
            }}
          >
            <Typography
              sx={{ color: "#fff", fontWeight: 400, fontSize: "14px" }}
            >
              Share
            </Typography>
            <IconButton onClick={() => setOpen2(!open2)}>
              <CloseIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              py: 3,
              px: 8,
            }}
          >
            <IconButton onClick={shareOnWhatsApp}>
              <ImWhatsapp style={{ color: "#fff", fontSize: "30px" }} />
            </IconButton>
            <IconButton onClick={shareOnTwitter}>
              <RiTwitterXFill style={{ color: "#fff", fontSize: "30px" }} />
            </IconButton>
            <IconButton onClick={shareOnFacebook}>
              <FiFacebook style={{ color: "#fff", fontSize: "30px" }} />
            </IconButton>
          </Box>

          <Box sx={{ px: 2, display: "flex" }}>
            <CustomTextField value={url_event} />
            {copied ? (
              <IconButton>
                <MdOutlineDone style={{ color: "#56b85c" }} />
              </IconButton>
            ) : (
              <IconButton onClick={handleCopy}>
                <LiaCopy style={{ color: "#fff" }} />
              </IconButton>
            )}
          </Box>
        </Box>
      </Modal>

      <Modal
        open={open3}
        onClose={() => setOpen3(!open3)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <Box
            sx={{
              display: "flex",
              p: 2,
              px: { md: 4, sm: 3, xs: 2 },
              bgcolor: "#333",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #BC172F",
              borderRadius: "12px 12px 0px 0px",
            }}
          >
            <Typography
              sx={{ color: "#fff", fontWeight: 500, fontFamily: "optima" }}
            >
              Edit Experience
            </Typography>
            <IconButton onClick={() => setOpen3(false)}>
              <CloseOutlined sx={{ color: "#fff" }} />
            </IconButton>
          </Box>

          <Box sx={{ p: 2, px: { md: 4, sm: 3, xs: 2 }, mb: 5 }}>
            <Typography
              sx={{ fontWeight: 500, fontSize: "14px", color: "#fff" }}
            >
              Restaurant Experience
            </Typography>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item md={6} sm={12} xs={12}>
                <CustomTextField
                  name="Event Name"
                  value={eventName}
                  onChange={(e) => setEventName(e.target.value)}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                {eventItem?.EventTime === null ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 3,
                      }}
                    >
                      <Box>
                        <InputLabel sx={{ fontSize: "12px" }}>From:</InputLabel>
                        <CustomTextField
                          value={FormatTime(from || "12:00")}
                          type="time"
                          onChange={(e) => setFrom(e.target.value)}
                        />{" "}
                      </Box>
                      <Box>
                        <InputLabel sx={{ fontSize: "12px" }}>To:</InputLabel>
                        <CustomTextField
                          value={FormatTime(to || "12:00")}
                          type="time"
                          onChange={(e) => setTo(e.target.value)}
                        />{" "}
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <CustomTextField
                      name="Event Time"
                      value={FormatTime(time || "12:00")}
                      type="time"
                      onChange={(e) => setTime(e.target.value)}
                    />
                  </>
                )}
              </Grid>
              <Grid item md={12}>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                    my: 2,
                  }}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: 300 }}>
                    <Checkbox
                      checked={buffer?.checked}
                      onChange={(e) =>
                        setBuffer({ ...buffer, checked: e.target.checked })
                      }
                    />{" "}
                    Booking Buffer:
                  </Typography>
                  <TextField
                    defaultValue={"00:15:00"}
                    select
                    disabled={!buffer.checked}
                    value={buffer.value}
                    onChange={(e) =>
                      setBuffer({ ...buffer, value: e.target.value })
                    }
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: "47px",
                      },
                    }}
                  >
                    <MenuItem value="00:15:00">15 Minutes</MenuItem>
                    <MenuItem value="00:30:00">30 Minutes</MenuItem>
                    <MenuItem value="01:00:00">1 Hour</MenuItem>
                    <MenuItem value="02:00:00">2 Hours</MenuItem>
                    <MenuItem value="03:00:00">3 Hours</MenuItem>
                  </TextField>
                </Box>
                <Divider />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <CustomTextField
                  type="date"
                  name="Event Date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <CustomTextField
                  accept="image/*"
                  name="Event Image"
                  type="file"
                  onChange={handleFile}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <InputLabel
                  sx={{
                    color: "#ccc",
                    mb: 1,
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Description
                </InputLabel>
                {/* <CustomTextField
                  name="Description"
                  multiLine
                  rows={5}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                /> */}

                <ReactQuill
                  className="editor"
                  theme="snow"
                  value={textValue}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Button
                  disabled={!eventName || !date || !time || !textValue}
                  onClick={handleEditEvent}
                  variant="contained"
                  sx={{
                    mt: 4,
                    py: 1.5,
                    borderRadius: "10px",
                    "&:disabled": {
                      bgcolor: "#333",
                      color: "#ccc",
                    },
                  }}
                  fullWidth
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>

      <CustomModal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        title="Select Experience Type"
        width={500}
      >
        <Box sx={{ p: 4, boxSizing: "border-box" }}>
          <Typography sx={{ my: 2, fontWeight: 500 }}>
            What type of experience do you want to add?
          </Typography>
          <ListItemButton
            onClick={handleOpenOneTimeEvent}
            sx={{ border: "0.5px solid #fff", borderRadius: "10px", py: 2 }}
          >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
              <MdEventAvailable style={{ fontSize: "25px" }} />
              <Box>
                <Typography sx={{ fontSize: "18px", fontFamily: "optima" }}>
                  One - Time Event
                </Typography>
                <Typography>(Suitable for a one time event)</Typography>
              </Box>
            </Box>
          </ListItemButton>
          <ListItemButton
          onClick={handleRecurringEvent}
            sx={{
              border: "0.5px solid #fff",
              borderRadius: "10px",
              py: 2,
              mt: 3,
            }}
          >
            <Box sx={{}}>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                <FaRedo style={{ fontSize: "20px" }} />
                <Box>
                  <Typography sx={{ fontSize: "18px", fontFamily: "optima" }}>
                    Recurring Event
                  </Typography>
                  <Typography>
                    (Suitable for a events that occur frequently)
                  </Typography>
                </Box>
              </Box>
            </Box>
          </ListItemButton>
        </Box>
      </CustomModal>

      <CreateExperience
        action={handleGetEvents}
        open={openOneTimeEvent}
        onClose={() => setOpenOneTimeEvent(false)}
      />
      <RecurringEvent
        action={handleGetEvents}
        open={openRecurringEvent}
        onClose={() => setOpenRecurringEvent(false)}
      />
    </>
  );
};

export default Experience;

const FormatTime = (time12h) => {
  const [time, modifier] = time12h?.split(" ");
  let [hours, minutes] = time?.split(":");

  if (modifier === "PM" && hours !== "12") {
    hours = String(parseInt(hours, 10) + 12);
  } else if (modifier === "AM" && hours === "12") {
    hours = "00";
  }

  return `${hours}:${minutes}`;
};
