import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { Badge, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { getDashboadTables } from "../../api";
import dayjs from "dayjs";

const Calender = (props) => {
  const [value, setValue] = useState(dayjs());
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const handleTableData = async () => {
    setIsLoading(true);
    await getDashboadTables(100)
      .then((res) => {
        setIsLoading(false);

        setData(res?.data?.result);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleTableData();
  }, []);

  const reservationDates = data.map((reservation) => reservation.Date);

  const handleChange = (newValue) => {
    setValue(newValue);
    const formattedDate = newValue.format("YYYY-MM-DD");
    props.onChildValue(formattedDate);
  };

  const renderDay = ({
    highlightedDays = [],
    day,
    outsideCurrentMonth,
    ...other
  }) => {
    const formattedDay = dayjs(day).format("YYYY-MM-DD");
    const isReserved = reservationDates.includes(formattedDay);

    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={
          isLoading ? (
            <CircularProgress size={5} />
          ) : isReserved ? (
            <p style={{ fontSize: "5px" }}>🔴</p>
          ) : undefined
        }
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  };

  return (
<LocalizationProvider dateAdapter={AdapterDayjs}>
  <DateCalendar
    showDaysOutsideCurrentMonth
    disablePast={props.past}
    disableFuture={props.future}
    value={value}
    onChange={(newValue) => handleChange(newValue)}
    slots={{
      day: renderDay,
    }}
    sx={{
      "&.MuiDateCalendar-root": {
        fontFamily: "Outfit",
        fontWeight: 600,
        fontSize: "8px",
        maxWidth: "250px",
        maxHeight:'270px'
      },
      "& .MuiTypography-root": {
        fontSize: "12px", // Reduce text size
      },
      "& .MuiPickersDay-root": {
        width: "30px", // Reduce day button size
        height: "30px",
        fontSize: "10px",
      },
      "& .MuiPickersDay-dayOutsideMonth": {
        opacity: 0.5, // Dim outside month days
      },
      "& .MuiPickersCalendarHeader-root": {
        minHeight: "30px", // Reduce header height
      },
      "& .MuiPickersSlideTransition-root": {
        minHeight: "200px", // Reduce calendar container height
      },
    }}
  />
</LocalizationProvider>

  );
};

export default Calender;
