import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { getReservations, getReservationWaitlist } from "../../../api";
import WalkinHeader from "../../Common/WalkinHeader";
import Loader from "../../Common/Loader";

import Header from "../Header";

import { ThemeContextProvider } from "../../../STYLES/theme/ThemeContextProvider";
import TopNav from "../../Layout/TopNav";
import Expected from "./ReservationStatus/Expected";
import Seated from "./ReservationStatus/Seated";
import Finished from "./ReservationStatus/Finished";
import Canceled from "./ReservationStatus/Canceled";
import Waitlist from "./ReservationStatus/Waitlist";
import { useLocation } from "react-router-dom";

const ReservationManagement = () => {
  const [expanded, setExpanded] = React.useState("Expected");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [finished, setFinished] = useState(null);
  const [seated, setSeated] = useState(null);
  const [upcoming, setUpcoming] = useState(null);
  const [cancelled, setIsCancelled] = useState(null);
  // const [open2, setOpen2] = React.useState(false);
  // const handleOpen2 = () => setOpen2(true);
  // const handleClose2 = () => setOpen2(false);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const panelParams = searchParams.get("panel");

    if (panelParams === "Expected") {
      setExpanded("Expected");
    } else if (panelParams === "Seated") {
      setExpanded("Seated");
    } else if (panelParams === "Finished") {
      setExpanded("Finished");
    } else if (panelParams === "Cancelled") {
      setExpanded("Cancelled");
    }
  }, [location]);

  const filterFinished = data?.filter(
    (item) => item.BookingStatus == "Finished"
  );
  const filterExpected = data?.filter(
    (item) => item.BookingStatus == "Expected"
  );
  const filterSeated = data?.filter((item) => item.BookingStatus == "Seated");
  const filterCancelled = data?.filter(
    (item) => item.BookingStatus === "Cancelled"
  );

  const [reservationLoader, setReservationLoader] = useState(false)
  const handleGetWaitlistReservation = async () => {
    setIsLoading(true);
    await getReservationWaitlist()
      .then((res) => {
        setIsLoading(false);
        setWaitlist(res?.data?.result);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetWaitlistReservation();
  }, []);

  useEffect(() => {
    if (data) {
      setIsCancelled(filterCancelled || []);
      setUpcoming(filterExpected || []);
      setFinished(filterFinished || []);
      setSeated(filterSeated || []);
    }
  }, [data]);

  const inputDate = new Date();

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");

  const formattedDateString = `${year}-${month}-${day}`;

  const [formattedDate, setFormattedDate] = useState(formattedDateString);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleGetReservations = async () => {
    setReservationLoader(true);
    await getReservations(formattedDate)
      .then((res) => {
        setReservationLoader(false);
        const { data } = res;
        setData(data?.result);
      })
      .catch((err) => {
        setReservationLoader(false);
      });
  };

  useEffect(() => {
    handleGetReservations();
  }, [formattedDate]);

  const filterDate = (value) => {
    setFormattedDate(value);
  };

  const [waitlist, setWaitlist] = useState(null);

  return (
    <>
      {/* {isLoading && <Loader />} */}
      <ThemeContextProvider>
        <TopNav />

        <Box sx={{}}>
          <Header title="Manage Reservation" />

          <WalkinHeader
            updateDate={filterDate}
            action={handleGetReservations}
          />
          <Box>
            <Expected
              expanded={expanded}
              handleChange={handleChange}
              upcoming={upcoming}
              handleGetReservations={handleGetReservations}
              loader={reservationLoader}
            />
            <Seated
              expanded={expanded}
              seated={seated}
              handleChange={handleChange}
              handleGetReservations={handleGetReservations}
                     loader={reservationLoader}
            />
            <Finished
              expanded={expanded}
              finished={finished}
              handleChange={handleChange}
              handleGetReservations={handleGetReservations}
                     loader={reservationLoader}
            />
            <Canceled
              expanded={expanded}
              cancelled={cancelled}
              handleChange={handleChange}
              handleGetReservations={handleGetReservations}
                     loader={reservationLoader}
            />
            <Waitlist
              expanded={expanded}
              handleChange={handleChange}
              waitlist={waitlist}
              handleGetWaitlistReservation={handleGetWaitlistReservation}
              handleGetReservations={handleGetReservations}
              finished={finished}
                     loader={isLoading}
            />
          </Box>
        </Box>
      </ThemeContextProvider>
    </>
  );
};

export default ReservationManagement;

// =====================CARD COMPONENT & EDIT RESERVATION ====================================================================
// =================================================