import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { createPoint } from "../../../api";

import { useSnackbar } from "notistack";
import useAlerts from "../../hooks/useAlerts";
import { isAction } from "@reduxjs/toolkit";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  background: "#1a1a1a",
  boxShadow: 24,

  p: 4,
  borderRadius: "12px",
};
const CreatePoint = ({ open, handleClose, action }) => {
  const handleAlert = useAlerts();
  const [formValues, setFormValues] = useState({
    value: "",
    from: "",
    to: "",
  });

  const { value, from, to } = formValues;

  const [isLoading, setIsLoading] = useState(false);

  const handleCreatePoint = async () => {
    setIsLoading(true);
    await createPoint(value, from, to)
      .then((res) => {
        // console.log(res);
        handleAlert({
          message: `Point created successfully`,
          variant: "success",
        });
        handleClose();
        action();
      })
      .catch((err) => {
        // console.log(err);
        handleAlert({ message: `${err?.message}`, variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // console.log(formValues);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "12px 12px 0px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "background.card",
              borderBottom: "1px solid #BC172F",
              p: 2,
              width: "100%",
              px: 3,
              boxSizing: "border-box",
            }}
          >
            <Typography
              sx={{ fontFamily: "optima", fontSize: "18px", fontWeight: 700 }}
            >
              Create Points
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlined sx={{ fontSize: "20px" }} />
            </IconButton>
          </Box>

          <Box sx={{ mt: 4, px: 5 }}>
            <Box>
              <InputLabel>How many points?</InputLabel>
              <TextField
                margin="dense"
                fullWidth
                type="number"
                value={value}
                onChange={(e) =>
                  setFormValues({ ...formValues, value: e.target.value })
                }
                InputProps={{ style: { borderRadius: "8px" } }}
              />
            </Box>

            <Divider sx={{ my: 2 }} />
            <InputLabel sx={{ mt: 2 }}>Time interval</InputLabel>

            <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
              <InputLabel sx={{ mt: 2, width: "20%" }}>From</InputLabel>
              <TextField
                margin="dense"
                fullWidth
                type="time"
                value={from}
                onChange={(e) =>
                  setFormValues({ ...formValues, from: e.target.value })
                }
                InputProps={{ style: { borderRadius: "8px" } }}
              />
            </Box>
            <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
              <InputLabel sx={{ mt: 2, width: "20%" }}>To:</InputLabel>
              <TextField
                margin="dense"
                fullWidth
                type="time"
                value={to}
                onChange={(e) =>
                  setFormValues({ ...formValues, to: e.target.value })
                }
                InputProps={{ style: { borderRadius: "8px" } }}
              />
            </Box>

            <Box align="right" sx={{ mt: 3, mb: 5 }}>
              <Button
                disabled={!value || !from || !to || isLoading}
                onClick={handleCreatePoint}
                variant="contained"
                sx={{ height: "45px", width: isLoading ? "auto" : "150px", borderRadius: "8px" , transition:'0.2s all linear'}}
              >
                {isLoading ? (
                  <CircularProgress size={30} color="inherit" thickness={5.5}/>
                ) : (
                  "       Create point"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CreatePoint;
