import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ThemeContext } from "../../STYLES/theme/ThemeContextProvider";

const Header = (props) => {
  const { isDarkTheme } = useContext(ThemeContext);
  return (
    <Box
      sx={{
        height: "80px",
        background: isDarkTheme ? "#1a1a1a" : "#ffff",
        borderBottom: "0.2px solid #ccccccb7",
        px: 3,
        placeContent: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: { lg: "24px", md: "24px", sm: "12px", xs: "12px" },
          fontWeight: 700,
          fontFamily: "optima",
        }}
      >
        {props.title}
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          color: isDarkTheme ? "#ccc" : "#333",
          lineHeight: "13px",
          mt: 1,
        }}
      >
        {props.description}
      </Typography>
      {/* <Button variant="contained">Quick Setup</Button> */}
    </Box>
  );
};

export default Header;
