import {
  Box,
  Button,
  Checkbox,
  Drawer,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemButton,
  TextField,
  Typography,
} from "@mui/material";
import { VariableSizeList as List } from "react-window";
import { motion } from "framer-motion";
import React, { useState, useEffect, useRef } from "react";
import { TbFilterSearch } from "react-icons/tb";
import { BiExport } from "react-icons/bi";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { TbFileTypePdf } from "react-icons/tb";

import SendEmail from "./SendEmail";
import CsvExport from "../../../Common/CsvExport";
import ImportGuest from "../Create/ImportGuest";
import { CiImport } from "react-icons/ci";
import FilterBox from "./FilterBox";
import emptyIcon from "../../../../assets/img/emptyRes.svg";

const CRM = ({ data, callBack }) => {
  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const restaurantName = localStorage.getItem("restaurantName");
  const [openImport, setOpenImport] = React.useState(false);
  const handleOpenImport = () => {
    setOpenImport(true);
  };
  const toggleDrawer = (newOpen) => () => {
    setFilterDrawer(newOpen);
  };

  const updateFilteredData = (newData) => {
    if (data) {
      setFilteredData(newData);
    }
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const listRef = useRef();

  const handleCheckboxChange = (id, name, email) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some((row) => row.id === id);
      if (isSelected) {
        return prevSelectedRows.filter((row) => row.id !== id);
      } else {
        return [...prevSelectedRows, { id, name, email }];
      }
    });
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      const allRows = data?.map((row) => ({
        id: row.Id,
        name: row.FirstName,
        email: row.EmailAddress,
      }));
      setSelectedRows(allRows);
    } else {
      setSelectedRows([]);
    }
  };

  const [searchParams, setSearchParams] = useState("");

  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    // Filter items based on the search term
    const filteredItems = data?.filter(
      (item) =>
        item?.FirstName?.toLowerCase().includes(searchTerm) ||
        item?.LastName?.toLowerCase().includes(searchTerm) ||
        item?.EmailAddress?.toLowerCase().includes(searchTerm)
    );

    setFilteredData(filteredItems);
    setSearchParams(searchTerm);
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const generatePDF = () => {
    const doc = new jsPDF();

    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);

    // Add heading
    doc.text(
      ` ${restaurantName} Guest List - ${formatDate(new Date())}`,
      10,
      10
    );
    // Set custom font and size for the table
    doc.setFont("helvetica");
    doc.setFontSize(12);

    const tableColumn = [
      "Name",
      "Email",
      "Phone Number",
      "Upcoming",
      "Finished",
      "Cancelled",
    ];
    const tableRows = [];

    filteredData.forEach((item) => {
      const itemData = [
        `${item.FirstName} ${item?.LastName}`,
        `${item.EmailAddress}`,
        item.PhoneNumber,
        item.UpcomingBookings ? item.UpcomingBookings : 0,
        item?.BookingAttendance,
        item.CancelledBookings,
      ];
      tableRows.push(itemData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 15,
      styles: {
        fontSize: 8,
        font: "helvetica",
      },
      headStyles: {
        fillColor: [188, 23, 47],
      },
      didDrawPage: function (data) {
        // Footer
        const str = "Powered by Reisty";
        doc.setFontSize(8);
        doc.text(
          str,
          data.settings.margin.left,
          doc.internal.pageSize.height - 10
        );
      },
    });

    doc.save(`${restaurantName}_gl(${new Date()}).pdf`);
  };

  // Dynamic row height
  const getRowHeight = () => 60; // Adjust if needed

  const Row = ({ index, style }) => {
    const item = filteredData[index];

    return (
      <Box
        key={index}
        hover
        onClick={() =>
          handleCheckboxChange(item?.Id, item.FirstName, item?.EmailAddress)
        }
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          px: 4,
          columnGap: 2,
          cursor: "pointer",
          "&:hover": { bgcolor: "#333" },
        }}
        style={style} // Ensure the row follows react-window's positioning
      >
        <Box sx={{ border: "none", width: "5%" }} align="left">
          <Checkbox
            checked={selectedRows.some(
              (selectedRow) => selectedRow.id === item.Id
            )}
            // onChange={() =>
            //   handleCheckboxChange(item?.Id, item.FirstName, item?.EmailAddress)
            // }
          />
        </Box>
        <Box
          sx={{
            fontFamily: "outfit",
            fontSize: "12px",
            textAlign: "left",
            width: "20%",
          }}
        >
          {`${item?.FirstName} ${item?.LastName}`}
        </Box>
        <Box
          sx={{
            border: "none",
            fontFamily: "outfit",
            fontSize: "12px",
            width: "20%",
          }}
        >
          {item?.EmailAddress}
        </Box>
        <Box sx={{ fontFamily: "outfit", fontSize: "12px", width: "15%" }}>
          {item?.PhoneNumber}
        </Box>
        <Box
          sx={{
            fontFamily: "outfit",
            fontSize: "12px",
            width: "20%",
            textAlign: "center",
          }}
        >
          {item?.UpcomingBookings || 0}
        </Box>
        <Box
          sx={{
            border: "none",
            fontFamily: "outfit",
            fontSize: "12px",
            width: "10%",
            textAlign: "center",
          }}
        >
          {item?.guestReservationHitory
            ? item?.guestReservationHitory?.length
            : 0}
        </Box>
        <Box
          sx={{
            border: "none",
            fontFamily: "outfit",
            fontSize: "12px",
            width: "10%",
            textAlign: "center",
          }}
        >
          {item?.CancelledBookings || 0}
        </Box>
      </Box>
    );
  };

  let list = [
    "Jack",
    8,
    2,
    2,
    6,
    "King",
    5,
    3,
    "King",
    "Queen",
    "King",
    "Queen",
    10,
    "King",
    "King",
    11,
    12,
    "Jack",
  ];
  // for(let i = 0; i < list.length - 1; i ++){
  //   for(let j = 0; j < list.length - i - 1; j++){
  //     let a = list[j];
  //     let b = list[j + 1];

  //     if(typeof a === "number" && typeof b === "number"){
  //       if(a > b){
  //         [list[j], list[j + 1]] = [b,a];
  //       }
  //     }else if (typeof a === "string" && typeof b === "number"){
  //        [list[j], list[j + 1]] = [b,a];
  //     }else if((a === "Queen" && b === "Jack") || (a === "King" && b === "Jack")){
  //       [list[j], list[j + 1]] = [b, a];
  //     }else if (a === "King" && b === "Queen"){
  //       [list[j], list[j + 1]] = [b, a];
  //     }
  //   }
  //   console.log(list, "New List")
  // }

  // list.sort((a, b) => {
  //   const order = { Jack: 1, Queen: 2, King: 3 };

  //   if (typeof a === "number" && typeof b === "number") {
  //     return a - b; // Sort numbers in ascending order
  //   } else if (typeof a === "number") {
  //     return -1; // Numbers come first
  //   } else if (typeof b === "number") {
  //     return 1; // Numbers come first
  //   } else {
  //     return order[a] - order[b]; // Sort based on custom order
  //   }
  // });

  // console.log(list);

  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
          borderBottom: "0.5px solid #fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          columnGap: 3,
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          px: 4,
        }}
      >
        <Box
          component={motion.div}
          animate={{
            scale: 1,
            x: 0,
          }}
          initial={{
            scale: 0.1,
          }}
          transition={{
            duration: 0.3,
            ease: "backInOut",
          }}
          sx={{ display: "flex", alignItems: "center", columnGap: 3, my: 2 }}
        >
          <IconButton
            onClick={toggleDrawer(true)}
            whileHover={{ scale: 1.5 }}
            whileTap={{ scale: 0.9, rotate: "2.5deg" }}
            transition={{
              duration: 0.2,
              ease: "easeInOut",
            }}
            component={motion.button}
          >
            <TbFilterSearch style={{ fontSize: "16px" }} />
          </IconButton>
          <TextField
            value={searchParams}
            onChange={handleSearch}
            size="small"
            sx={{ width: "250px" }}
            placeholder="Search contact, email or phone no"
            InputProps={{
              style: {
                background: "#fff",
                fontSize: "10px",
                color: "#151515",
              },
            }}
          />
        </Box>
        <Box
          component={motion.div}
          animate={{
            scale: 1,
            x: 0,
          }}
          initial={{
            scale: 0.1,
          }}
          transition={{
            duration: 0.3,
            ease: "backInOut",
          }}
          sx={{
            display: "flex",
            columnGap: 2,
            alignItems: "center",
            overflowX: "scroll",
            pt: 1,
          }}
        >
          <SendEmail selectedGuest={selectedRows} />
          <Button
            variant="contained"
            sx={{
              borderRadius: "8px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            startIcon={<CiImport />}
            onClick={handleOpenImport}
          >
            <InputLabel sx={{ color: "#fff" }}>
              {" "}
              Import existing guestbook
            </InputLabel>
          </Button>

          <CsvExport
            data={filteredData}
            name={`${restaurantName} Guest List - ${formatDate(new Date())}`}
          />
          <Button
            sx={{
              borderRadius: "8px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            variant="contained"
            startIcon={<TbFileTypePdf />}
            onClick={generatePDF}
          >
            <InputLabel sx={{ color: "#fff" }}>Export as PDF</InputLabel>
          </Button>
        </Box>
      </Box>
      <Box sx={{ px: 4 }}>
        <Box
          sx={{
            bgcolor: "background.card",
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            display: "flex",
            alignItems: "center",
            px: 4,
            columnGap: 2,
            height: "65px",
          }}
        >
          <Box sx={{ border: "none", width: "5%" }} align="left">
            <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
          </Box>
          <Typography
            sx={{
              border: "none",
              fontFamily: "outfit",
              fontSize: "12px",
              width: "20%",
            }}
          >
            Name
          </Typography>
          <Typography
            sx={{ fontFamily: "outfit", fontSize: "12px", width: "20%" }}
          >
            Email
          </Typography>

          <Typography
            sx={{ fontFamily: "outfit", fontSize: "12px", width: "15%" }}
          >
            Phone No
          </Typography>
          <Typography
            sx={{
              fontFamily: "outfit",
              fontSize: "12px",
              width: "20%",
              textAlign: "center",
            }}
          >
            Guest Tag
          </Typography>
          <Typography
            sx={{
              border: "none",
              fontFamily: "outfit",
              fontSize: "12px",
              width: "10%",
              textAlign: "center",
            }}
          >
            Total Visits
          </Typography>
          <Typography
            sx={{
              border: "none",
              fontFamily: "outfit",
              fontSize: "12px",
              width: "10%",
              textAlign: "center",
            }}
          >
            Avg Spend
          </Typography>
        </Box>

        {/* Wrapper div is required to maintain correct table structure */}
        {/* <div style={{ height: 800, overflow: "auto" }}> */}
        {filteredData.length === 0 || !filteredData.length ? (
          <>
            <Box
              sx={{
                height: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src={emptyIcon} />

              <Typography sx={{ mt: 3 }}>
                No guests available based on your filters.
              </Typography>
            </Box>
          </>
        ) : (
          <List
            ref={listRef}
            height={800} // Adjust as needed
            itemCount={filteredData.length}
            itemSize={getRowHeight} // Dynamic row height
            width={"100%"}
          >
            {Row}
          </List>
        )}

        {/* </div> */}
      </Box>

      <Drawer open={filterDrawer} onClose={toggleDrawer(false)}>
        <FilterBox updateFilteredData={updateFilteredData} data={data} />
      </Drawer>

      <ImportGuest
        open={openImport}
        onClose={() => setOpenImport(false)}
        action={callBack}
      />
    </>
  );
};

export default CRM;

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = date.toLocaleString("en-GB", { month: "short" });
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
};
