import { CloseOutlined } from "@mui/icons-material";
import {
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
  MenuItem,
  Divider,
  Checkbox,
  ListItemButton,
} from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../CustomField/CustomTextField";
import { createEvents } from "../../../api";
import Loader from "../../Common/Loader";
import { useSnackbar } from "notistack";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "./editor.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 600, md: 600, xs: 350 },
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 0,
  maxHeight: "70vh",
  overflow: "scroll",
  borderRadius: "10px",
};
const RecurringEvent = ({ action, open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [eventName, setEventName] = useState("");
  const [isTicketed, setIsTicketed] = useState("Yes");
  const [time, setTime] = useState(null);
  const [date, setDate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [textValue, setTextValue] = useState("");
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const [duration, setDuration] = useState({
    from: null,
    to: null,
  });

  const [buffer, setBuffer] = useState({
    checked: false,
    value: "00:15:00",
  });

  const handleChange = (value) => {
    setTextValue(value);
  };

  const handleClose = () => {
    onClose();
  };

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handleFile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURL = e.target.result;
        setSelectedFileURL(dataURL);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCreateTicketedEvent = async () => {
    setLoading(true);

    const buffer_value = buffer.checked ? buffer?.value : null;
    await createEvents(
      isTicketed,
      eventName,
      textValue,
      undefined,
      selectedDays,
      time,
      selectedFile,
      duration.from,
      duration.to,
      buffer_value
    )
      .then((res) => {
        setLoading(false);

        const { data } = res;
        if (data?.status) {
          handleAlert("success", `${data?.success_message}`);
          onClose();
          action();
        } else {
          handleAlert("error", `${data?.error_message}`);
        }
      })
      .catch((err) => {
        setLoading(false);

        handleAlert("error", `${err.message} `);
      });
  };
  const handleCreateNonTicketedEvent = async () => {
    setLoading(true);
    const buffer_value = buffer.checked ? buffer?.value : null;
    await createEvents(
      isTicketed,
      eventName,
      textValue,
      date,
      selectedDays,
      time,
      selectedFile,
      duration.from,
      duration.to,
      buffer_value
    )
      .then((res) => {
        setLoading(false);

        const { data } = res;
        if (data?.status) {
          handleAlert("success", `${data?.success_message}`);
          onClose();
          action();
        } else {
          handleAlert("error", `${data?.error_message}`);
        }
      })
      .catch((err) => {
        setLoading(false);

        handleAlert("error", `${err.message} `);
      });
  };

  const [selectedDays, setSelectedDays] = useState([]);

  const handleSelectDay = (value) => {
    const isAvialable = selectedDays.includes(value);
    if (isAvialable) {
      const newArray = selectedDays.filter((item) => item !== value);
      setSelectedDays(newArray);
    } else {
      setSelectedDays((prevDays) => [...prevDays, value]);
    }

    console.log(selectedDays);
  };

  return (
    <>
      {loading && <Loader />}
      {/* <Button variant="contained" onClick={() => setOpen(true)}>
        Create New Experience
      </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="hide_scrollbar" sx={style}>
          <Box
            sx={{
              display: "flex",
              p: 2,
              px: { md: 4, sm: 3, xs: 2 },
              bgcolor: "background.card",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #BC172F",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            <Typography
              sx={{ fontWeight: 700, fontFamily: "optima", fontSize: "18px" }}
            >
              Create Recurring Event
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlined sx={{}} />
            </IconButton>
          </Box>

          <Box
            sx={{
              p: 2,
              px: { md: 4, sm: 3, xs: 2 },
              mb: 5,
              borderRadius: "0px 0px 10px 10px",
              bgcolor: "background.paper",
              mt: 4,
            }}
          >
            {/* <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              Restaurant Experience
            </Typography> */}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 3,
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Box sx={{ width: "30%" }}>
                <Typography sx={{ fontSize: "14px" }}>
                  {" "}
                  Is this a ticketed event?
                </Typography>
              </Box>
              <Box sx={{ width: "70%" }}>
                <TextField
                  select
                  fullWidth
                  defaultValue={"Yes"}
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      height: "47px",
                    },
                  }}
                >
                  <MenuItem value="Yes" onClick={() => setIsTicketed("Yes")}>
                    Yes
                  </MenuItem>
                  <MenuItem onClick={() => setIsTicketed("No")} value="No">
                    {" "}
                    No
                  </MenuItem>
                </TextField>
              </Box>
            </Box>
            
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 3,
                justifyContent: "space-between",
                my: 3,
              }}
            >
              <Box sx={{ width: "30%" }}>
                <Typography sx={{ fontSize: "14px" }}> Event Day</Typography>
              </Box>
              <Box sx={{ width: "70%", display: "flex", alignItems: "center" }}>
                {daysData.map((day, index) => (
                  <ListItemButton
                    selected={selectedDays.includes(day?.bitmask)}
                    onClick={() => handleSelectDay(day?.bitmask)}
                    key={index}
                    sx={{
                      border: "0.5px solid #ccc",
                      "&.Mui-selected": { bgcolor: "primary.main" },
                    }}
                  >
                    <Typography>{day?.day}</Typography>
                  </ListItemButton>
                ))}
              </Box>
            </Box>
            {isTicketed === "No" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 3,
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Box sx={{ width: "30%" }}>
                  <Typography sx={{ fontSize: "14px" }}> Event Time</Typography>
                </Box>
                <Box
                  sx={{
                    width: "70%",
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                  }}
                >
                  <TextField
                    type="time"
                    fullWidth
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: "47px",
                      },
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 3,
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Box sx={{ width: "30%" }}>
                  <Typography sx={{ fontSize: "14px" }}>
                    {" "}
                    Event Duration
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "70%",
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
                  >
                    <Typography>From:</Typography>
                    <TextField
                      type="time"
                      value={duration.from}
                      onChange={(e) =>
                        setDuration({ ...duration, from: e.target.value })
                      }
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          height: "47px",
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
                  >
                    <Typography>To:</Typography>
                    <TextField
                      type="time"
                      value={duration.to}
                      onChange={(e) =>
                        setDuration({ ...duration, to: e.target.value })
                      }
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          height: "47px",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 3,
                justifyContent: "space-between",
                my: 4,
              }}
            >
              <Box sx={{ width: "30%" }}></Box>
              <Box
                sx={{
                  width: "70%",
                }}
              >
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                    my: 2,
                  }}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: 300 }}>
                    <Checkbox
                      value={buffer.checked}
                      onChange={(e) =>
                        setBuffer({ ...buffer, checked: e.target.checked })
                      }
                    />{" "}
                    Booking Buffer:
                  </Typography>
                  <TextField
                    defaultValue={"00:15:00"}
                    select
                    disabled={!buffer.checked}
                    value={buffer.value}
                    onChange={(e) =>
                      setBuffer({ ...buffer, value: e.target.value })
                    }
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: "47px",
                      },
                    }}
                  >
                    <MenuItem value="00:15:00">15 Minutes</MenuItem>
                    <MenuItem value="00:30:00">30 Minutes</MenuItem>
                    <MenuItem value="01:00:00">1 Hour</MenuItem>
                    <MenuItem value="02:00:00">2 Hours</MenuItem>
                    <MenuItem value="03:00:00">3 Hours</MenuItem>
                  </TextField>
                </Box>
                <Divider />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 3,
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Box sx={{ width: "30%" }}>
                <Typography sx={{ fontSize: "14px" }}>
                  Experience Name
                </Typography>
              </Box>
              <Box sx={{ width: "70%" }}>
                <TextField
                  placeholder="Name of event"
                  fullWidth
                  value={eventName}
                  onChange={(e) => setEventName(e.target.value)}
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      height: "47px",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                mt: 2,
              }}
            >
              <Box sx={{ mb: 1 }}>
                <Typography sx={{ fontSize: "14px" }}>
                  Experience Description
                </Typography>
              </Box>
              <Box sx={{}}>
                <ReactQuill
                  className="editor"
                  theme="snow"
                  value={textValue}
                  onChange={handleChange}
                />
              </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
              <CustomTextField
                accept="image/*"
                name="Experience Image"
                type="file"
                onChange={handleFile}
              />
            </Box>

            {selectedFileURL && (
              <Box
                sx={{
                  height: "200px",
                  backgroundImage: `url(${selectedFileURL})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  mt: 2,
                }}
              />
            )}

            <Grid item md={12} sm={12} xs={12}>
              <Button
                disabled={
                  !eventName ||
                  (selectedDays.length === 0) ||
                 
                  !duration.from ||
                  !duration.to ||
                  !textValue ||
                  !selectedFile
                }
                onClick={handleCreateTicketedEvent}
                variant="contained"
                sx={{
                  mt: 4,
                  py: 1.5,
                  borderRadius: "10px",
                  "&:disabled": {
                    bgcolor: "#333",
                    color: "#ccc",
                  },
                }}
                fullWidth
              >
                Create Experience
              </Button>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RecurringEvent;

const daysData = [
  {
    day: "Sun",
    bitmask: 1,
    long: "Sunday",
  },
  {
    day: "Mon",
    bitmask: 2,
    long: "Monday",
  },
  {
    day: "Tue",
    bitmask: 4,
    long: "Tuesday",
  },
  {
    day: "Wed",
    bitmask: 8,
    long: "Wednesday",
  },
  {
    day: "Thu",
    bitmask: 16,
    long: "Thursday",
  },
  {
    day: "Fri",
    bitmask: 32,
    long: "Friday",
  },
  {
    day: "Sat",
    bitmask: 64,
    long: "Saturday",
  },
];

function bitmaskToDays(bitmask) {
  const daysMap = {
    1: "Sunday",
    2: "Monday",
    4: "Tuesday",
    8: "Wednesday",
    16: "Thursday",
    32: "Friday",
    64: "Saturday",
  };

  return Object.keys(daysMap)
    .map(Number) // Convert keys to numbers
    .filter((dayBit) => (bitmask & dayBit) !== 0) // Check which bits are set
    .map((dayBit) => daysMap[dayBit]); // Get corresponding day names
}

// Example usage:
const bitmask = 37; // Binary: 100101 (Sunday, Wednesday, and Friday)
const selectedDays = bitmaskToDays(bitmask);
console.log(selectedDays); // Output: ["Sunday", "Wednesday", "Friday"]
