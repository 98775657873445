import Loader from "./Loader";
import Lottie from "react-lottie";
import React from "react";
import anime from "../../assets/anime/KY0fkZoRyj.json";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { Box, Button, Modal, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backdrop: {
    transition: "0.1s all linear",
    backdropFilter: "blur(0.7px)",
    backgroundColor: "#1a1a1ab7",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "#1a1a1a",
  boxShadow: 24,
  p: 2,
  borderRadius: "12px",
};

const DeleteModal = ({ isLoading, open, handleClose, handleDelete, name }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const classes = useStyles();
  return (
    <>
      {isLoading && <Loader />}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          className: classes.backdrop,
        }}
      >
        <Box sx={style}>
          <Box
            align="center"
            sx={{
              bgcolor: "#1a1a1a",

              alignItems: "center",
              p: 2,
              // height:{md:'400px', xs:''}
            }}
          >
            <Box sx={{ width: "30%" }}>
              <Lottie options={defaultOptions} width={100} height={100} />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{ color: "#fff", fontWeight: 600, fontSize: "16px" }}
              >
                {" "}
                Are you sure you want to delete this {name ?? "settings"}?
              </Typography>
              <Typography
                sx={{
                  color: "#ccc",
                  fontSize: "12px",
                  lineHeight: "20px",
                  mt: 0.5,
                }}
              >
                This {name ?? "settings"} will be deleted immediately. This
                action cannot be undone
              </Typography>

              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{ borderRadius: "8px", px: 5, height: "45px" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  sx={{ borderRadius: "8px", px: 5, ml: 2, height: "45px" }}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteModal;
