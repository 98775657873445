import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Modal,
  Grid,
  LinearProgress,
  MenuItem,
  ListItemButton,
  InputLabel,
  TextField,
  InputAdornment,
  Avatar,
} from "@mui/material";
import dp from "../../../assets/Icons/dp.svg";

import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import {
  createGuestBook,
  createNewReservation,
  getOpeningHours,
  getSeatingArea,
  searchGuestBook,
} from "../../../api";
import { useSnackbar } from "notistack";
import { CloseOutlined } from "@mui/icons-material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import Calender from "../../Common/Calender";
import CustomTextField from "../CustomField/CustomTextField";
import reserve from "../../../assets/Icons/reservation/reserve.svg";
import clock from "../../../assets/Icons/reservation/clock.svg";
import people from "../../../assets/Icons/reservation/people.svg";
import calenderIcon from "../../../assets/Icons/reservation/calendar.svg";

import { useSelector } from "react-redux";
import Loader from "../../Common/Loader";

const CssTextField = styled(TextField)({
  height: "30px",
  color: "#fff",
  fontWeight: 500,
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#333",
    color: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#333",
      color: "#fff",
      fontWeight: 500,
    },
    "&:hover fieldset": {
      borderColor: "#333",
      color: "#fff",
      fontWeight: 500,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#333",
      color: "#fff",
      fontWeight: 500,
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 700, md: 700, sm: 400, xs: 350 },
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  boxSizing: "border-box",
  p: 0,
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 640,
    flexShrink: 0,
    p: 4,
  },
  drawerPaper: {
    width: 640,
    p: 4,
  },
  toolbar: theme.mixins.toolbar,
}));

function formatDate(inputDate) {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dateParts = inputDate.split("-");
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Month is 0-based in JavaScript Date object
  const day = parseInt(dateParts[2], 10);

  const date = new Date(year, month, day);
  const dayOfWeek = daysOfWeek[date.getDay()];
  const monthName = months[date.getMonth()];

  return `${dayOfWeek}, ${monthName} ${day}`;
}
const NewReservation = ({ open, onClose, action }) => {
  const [value, setValue] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [progressValue, setProgressValue] = useState(0);
  const [experience, setExperience] = useState(null);
  const [meal, setMeal] = useState("");
  const [isCard, setIsCard] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [guestId, setGuestId] = useState("");
  const [partySize, setPartySize] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [timeList, setTimeList] = useState(null);
  const [time, setTime] = useState(null);
  const [isTimeLoading, setTimeLoading] = useState(false);
  const [timeError, setTimeError] = useState(null);
  const [addGuest, setAddGuest] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [country, setCountry] = useState("Nigeria");
  const [dob, setDob] = useState(null);
  const [sliceNumber, setSliceNumber] = useState(5);

  const [floorplan, setFloorPlan] = useState(null);

  const [searchLoading, setSearchLoading] = useState(false);

  const [weddingAnniversary, setWeddingAnniversary] = useState("");
  const [graduationAnniversary, setgraduationAnniversary] = useState("");
  const restaurantList = useSelector((state) => state.restaurants.data?.result);

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };
  const handleSearch = async () => {
    setSearchLoading(true);
    await searchGuestBook(searchParams, experience)
      .then((res) => {
        setSearchLoading(false);
        setData(res?.data?.result);
      })
      .catch((err) => {
        setSearchLoading(false);
      });
  };

  useEffect(() => {
    if (searchParams.length >= 3) {
      handleSearch();
    }
  }, [searchParams]);

  const [anchorEl, setAnchorEl] = React.useState(false);

  const handleCreateNewReservation = async () => {
    setIsLoading(true);

    await createNewReservation(
      experience,
      floorplan,
      value,
      time,
      guestId,
      partySize
    )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          handleAlert("success", `${res?.data?.success_message}`);
          onClose();
          setActiveStep(0);
          setIsCard(false);
          action();
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err}`);
      });
  };

  const handleCreateGuest = async () => {
    setIsLoading(true);

    await createGuestBook(
      firstName || null,
      lastName || null,
      phone || null,
      email || null,
      country,
      dob || null,
      graduationAnniversary,
      weddingAnniversary
    )
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        if (data?.status) {
          handleAlert("success", `${data?.success_message}`);
          // navigate("/dashboard/guest-book");
          setAddGuest(false);
          handleSearch();
        } else {
          handleAlert("error", `${data?.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err}`);
      });
  };

  const handleClick = (event) => {
    setSearchParams(event.target.value);
    if (searchParams.length > 1) {
      setAnchorEl(true);
    } else setAnchorEl(false);
  };

  const openPoper = Boolean(anchorEl);
  const id = openPoper ? "simple-popper" : undefined;

  const formattedTime = time ? dayjs(time).format("HH:mm") : "";

  const RestaurantName = restaurantList?.find((item) => item.Id === experience);

  const handleExperience = (value) => {
    setExperience(value);
  };

  useEffect(() => {
    const selectedRestaurant = localStorage.getItem("restaurantID");
    setExperience(selectedRestaurant);
  }, []);

  const [seatingArea, setSeatingArea] = useState(null);
  const [loadingSeatingArea, setLoadingSeatingArea] = useState(null);

  const handleGetSeatingArea = async () => {
    setLoadingSeatingArea(true);
    await getSeatingArea(experience)
      .then((res) => {
        setLoadingSeatingArea(false);
        if (res?.data?.status) {
          setSeatingArea(res?.data?.result);
        } else {
          handleAlert("error", `${data?.error_message}`);
        }

        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
        handleAlert("error", `${err}`);
      });
  };

  useEffect(() => {
    if (experience) {
      handleGetSeatingArea();
    }
  }, [experience]);

  useEffect(() => {
    if (activeStep === 0) {
      setProgressValue(0);
    } else if (activeStep === 1) {
      setProgressValue(25);
    } else if (activeStep === 2) {
      setProgressValue(50);
    } else if (activeStep === 3) {
      setProgressValue(75);
    }
  }, [activeStep]);

  const classes = useStyles();

  useEffect(() => {
    if (experience && activeStep === 2) {
      handleGetHours();
    }
  }, [experience, value, partySize, activeStep]);

  const handleGetHours = async () => {
    setTimeLoading(true);
    setTimeList(null);
    setTimeError(null);

    await getOpeningHours(experience, value, partySize)
      .then((res) => {
        setTimeLoading(false);

        if (res?.data?.status) {
          setTimeList(res?.data?.result[0]?.GroupedTime);
        } else {
          setTimeError(res?.data?.error_message);
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setTimeLoading(false);

        handleAlert("error", `${err}`);
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {isLoading && <Loader />}
        <Box
          sx={{ bgcolor: "#1a1a1a", overflowY: "scroll", maxHeight: "70vh" }}
        >
          <Box>
            <Box sx={{ py: 2, px: 3, boxSizing: "border-box" }}>
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid item lg={2} md={2} xs={2}>
                  {activeStep > 0 && (
                    <IconButton
                      onClick={() => {
                        if (isCard) {
                          setIsCard(false);
                        } else {
                          setActiveStep((prev) => prev - 1);
                        }
                      }}
                    >
                      <ArrowBackIosOutlinedIcon sx={{ color: "#fff" }} />
                    </IconButton>
                  )}
                </Grid>
                <Grid item lg={8} xs={8}>
                  <Typography
                    sx={{
                      color: "#fff",
                      textAlign: "center",
                      fontWeight: 700,
                      fontFamily: "optima",
                      fontSize: "16px",
                    }}
                  >
                    Create a reservation
                  </Typography>
                </Grid>
                <Grid item lg={2} xs={2} align="right">
                  <IconButton
                    onClick={() => {
                      onClose();
                      setIsAdd(false);
                    }}
                  >
                    <CloseOutlined sx={{ color: "#fff" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <LinearProgress variant="determinate" value={progressValue} />
            {isCard ? (
              <>
                <Box sx={{ p: 5, px: 4 }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: 500,
                    }}
                  >
                    Add guest information
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: {
                        lg: "row",
                        md: "row",
                        sm: "column",
                        xs: "column",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: { lg: "60%", md: "60%", sm: "100%", xs: "100%" },
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: "#1a1a1a",
                        }}
                      >
                        <Box
                          sx={{
                            mt: 2,
                            p: 2,
                            bgcolor: "#333",
                            borderRadius: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img src={dp} width={50} alt="profile_icon" />

                          <Typography
                            sx={{
                              mt: 2,
                              color: "#fff",
                              fontWeight: 500,
                              fontSize: {
                                lg: "12px",
                                md: "12px",
                                sm: "12px",
                                xs: "10px",
                              },
                            }}
                          >
                            {cardData?.FirstName} {cardData?.LastName}
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              color: "#fff",
                              fontWeight: 500,
                              fontSize: {
                                lg: "12px",
                                md: "12px",
                                sm: "12px",
                                xs: "10px",
                              },
                            }}
                          >
                            {cardData?.EmailAddress}{" "}
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              color: "#fff",
                              fontWeight: 500,
                              fontSize: {
                                lg: "12px",
                                md: "12px",
                                sm: "12px",
                                xs: "9px",
                              },
                            }}
                          >
                            {cardData?.PhoneNumber}{" "}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ mt: 3 }}>
                        <Button
                          onClick={handleCreateNewReservation}
                          variant="contained"
                          fullWidth
                          sx={{
                            textTransform: "initial",
                            borderRadius: "10px",
                            py: 1.5,
                            display: {
                              lg: "block",
                              md: "block",
                              sm: "none",
                              xs: "none",
                            },
                          }}
                        >
                          Complete Reservation{" "}
                        </Button>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: { lg: "35%", md: "35%", sm: "100%", xs: "100%" },
                        bgcolor: "#262626",
                        p: 0,
                        mt: 2,
                        borderRadius: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: {
                            lg: "flex",
                            md: "flex",
                            sm: "none",
                            xs: "none",
                          },
                          alignItems: "center",
                          columnGap: 2,
                          borderBottom: "1px solid red",
                          bgcolor: "#333",
                          borderRadius: "10px 10px 0px  0px",
                          p: 2,
                        }}
                      >
                        <img src={dp} width={30} alt="profile_icon" />
                        <Typography
                          sx={{
                            mt: 2,
                            color: "#fff",
                            fontWeight: 500,
                            fontSize: "10px",
                          }}
                        >
                          {cardData?.FirstName} {cardData?.LastName}
                        </Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                            columnGap: 2,
                          }}
                        >
                          <img src={reserve} width={20} alt="reserve_icon" />
                          <Typography sx={{ color: "#fff", fontSize: "10px" }}>
                            {RestaurantName?.Name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                            columnGap: 2,
                          }}
                        >
                          <img src={people} width={20} alt="people_icon" />
                          <Typography sx={{ color: "#fff", fontSize: "10px" }}>
                            {partySize} guest
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                            columnGap: 2,
                          }}
                        >
                          <img src={clock} width={20} alt="clock_icon" />
                          <Typography sx={{ color: "#fff", fontSize: "10px" }}>
                            {time}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                            columnGap: 2,
                          }}
                        >
                          <img
                            src={calenderIcon}
                            width={20}
                            alt="calender_icon"
                          />
                          <Typography sx={{ color: "#fff", fontSize: "10px" }}>
                            {formatDate(value)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                      <Button
                        onClick={handleCreateNewReservation}
                        variant="contained"
                        fullWidth
                        sx={{
                          textTransform: "initial",
                          borderRadius: "10px",
                          py: 1.5,
                          display: {
                            lg: "none",
                            md: "none",
                            sm: "block",
                            xs: "block",
                          },
                        }}
                      >
                        Complete Reservation{" "}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    bgcolor: "#1a1a1a",
                    p: { lg: 5, md: 5, sm: 3, xs: 2 },
                    px: { lg: 4, md: 4, sm: 2, xs: 2 },
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: 2,
                      border: "1px solid #fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      align="center"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        p: 1,
                        borderRight: "1px solid #fff",
                        width: "25%",
                        bgcolor: activeStep === 0 && "#333",
                        borderRadius: "10px 0px 0px 10px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                        }}
                      >
                        Select date
                      </Typography>
                    </Box>
                    <Box
                      align="center"
                      sx={{
                        p: 1,
                        borderRight: "1px solid #fff",
                        width: "25%",
                        bgcolor: activeStep === 1 && "#333",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                        }}
                      >
                        Party size
                      </Typography>
                    </Box>
                    <Box
                      align="center"
                      sx={{
                        p: 1,
                        borderRight: "1px solid #fff",
                        width: "25%",
                        bgcolor: activeStep === 2 && "#333",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 400,
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                        }}
                      >
                        Select time
                      </Typography>
                    </Box>
                    <Box
                      align="center"
                      sx={{
                        p: 1,
                        width: "25%",
                        bgcolor: activeStep === 3 && "#333",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 400,
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                        }}
                      >
                        Add guest info
                      </Typography>
                    </Box>
                  </Box>
                  {isAdd ? (
                    <></>
                  ) : (
                    <>
                      <Box sx={{ mt: { lg: 5, md: 5, sm: 3, xs: 2 } }}>
                        {activeStep === 0 && (
                          <>
                            <Box sx={{ display: "grid", placeItems: "center" }}>
                              <Box
                                sx={{
                                  width: {
                                    lg: "70%",
                                    md: "70%",
                                    sm: "90%",
                                    xs: "100%",
                                  },
                                  bgcolor: "#333",
                                  display: "grid",
                                  placeItems: "center",
                                  pt: 3,
                                  borderRadius: "10px",
                                }}
                              >
                                <Calender
                                  future={false}
                                  past={true}
                                  onChildValue={(value) => setValue(value)}
                                />
                              </Box>
                            </Box>
                            <Box align="right" sx={{ mt: 3 }}>
                              <Button
                                disabled={!value}
                                variant="contained"
                                onClick={() => setActiveStep(1)}
                                sx={{ textTransform: "initial", px: 4 }}
                              >
                                Proceed
                              </Button>
                            </Box>
                          </>
                        )}
                        {activeStep === 1 && (
                          <>
                            <Box sx={{ display: "grid", placeItems: "center" }}>
                              <Box
                                sx={{
                                  width: "100%",
                                  // height: "500px",

                                  borderRadius: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    p: { lg: 3, md: 3, sm: 2, xs: 1 },
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      bgcolor: "#333",
                                      width: {
                                        lg: "70%",
                                        md: "70%",
                                        sm: "90%",
                                        xs: "90%",
                                      },
                                      height: "50px",
                                      display: "grid",
                                      placeItems: "center",
                                      borderRadius: "8px 8px 0px 0px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#fff",
                                        fontWeight: 500,
                                        fontSize: {
                                          lg: "14px",
                                          md: "14px",
                                          sm: "12px",
                                          xs: "12px",
                                        },
                                      }}
                                    >
                                      Select the party size
                                    </Typography>
                                  </Box>
                                  {[1, 2, 3, 4, 5].map((item) => (
                                    <MenuItem
                                      selected={partySize === item}
                                      onClick={() => setPartySize(item)}
                                      sx={{
                                        bgcolor: "#333",
                                        width: {
                                          lg: "70%",
                                          md: "70%",
                                          sm: "90%",
                                          xs: "90%",
                                        },
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",

                                        borderTop: "1px solid #1a1a1a",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#fff",
                                          fontWeight: 500,
                                          fontSize: {
                                            lg: "14px",
                                            md: "14px",
                                            sm: "12px",
                                            xs: "12px",
                                          },
                                        }}
                                      >
                                        {item} Guests
                                      </Typography>
                                    </MenuItem>
                                  ))}

                                  <Box
                                    onClick={() => setSliceNumber(10)}
                                    sx={{
                                      bgcolor: "#333",
                                      width: {
                                        lg: "70%",
                                        md: "70%",
                                        sm: "90%",
                                        xs: "90%",
                                      },

                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: "0px 0px 8px 8px",
                                      borderTop: "1px solid #1a1a1a",
                                      columnGap: 3,
                                      py: 1,
                                    }}
                                  >
                                    <InputLabel>Custom party size:</InputLabel>
                                    <TextField
                                      type="number"
                                      sx={{
                                        mt: 1,
                                        "& .MuiOutlinedInput-root": {
                                          "& fieldset": {
                                            borderColor: "none",
                                            borderRadius: "8px",

                                            color: "#667085",
                                            // Default outline color
                                          },
                                        },
                                      }}
                                      onChange={(e) =>
                                        setPartySize(e.target.value)
                                      }
                                      value={partySize}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box align="right" sx={{ mt: 3 }}>
                              <Button
                                disabled={!partySize}
                                variant="contained"
                                onClick={() => setActiveStep(2)}
                                sx={{ textTransform: "initial", px: 4 }}
                              >
                                Proceed
                              </Button>
                            </Box>
                          </>
                        )}
                        {activeStep === 2 && (
                          <>
                            <Box
                              sx={{
                                display: "grid",
                                placeItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  width: {
                                    lg: "90%",
                                    md: "90%",
                                    sm: "90%",
                                    xs: "100%",
                                  },
                                }}
                              >
                                <Box
                                  sx={{
                                    maxHeight: "200px",
                                    overflow: "scroll",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#fff",
                                      fontWeight: 400,
                                      fontSize: {
                                        lg: "14px",
                                        md: "14px",
                                        sm: "12px",
                                        xs: "12px",
                                      },
                                    }}
                                  >
                                    Experience
                                  </Typography>
                                  <Grid container sx={{ mt: 2 }} spacing={2}>
                                    {restaurantList?.map((item, index) => (
                                      <Grid
                                        item
                                        lg={4}
                                        md={4}
                                        xs={6}
                                        key={index}
                                      >
                                        <ListItemButton
                                          selected={experience === item.Id}
                                          onClick={() =>
                                            handleExperience(item.Id)
                                          }
                                          sx={{
                                            borderRadius: 2,
                                            p: 1,
                                            height: "50px",
                                            bgcolor: "#333",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            "&.Mui-selected": {
                                              bgcolor: "#BC172F",
                                            },
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              color: "#fff",
                                              width: "60%",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {item?.Name}
                                          </Typography>
                                          {experience === item?.Id ? (
                                            <AdjustOutlinedIcon
                                              sx={{ color: "#fff", ml: 2 }}
                                            />
                                          ) : (
                                            <CircleOutlinedIcon
                                              sx={{ color: "#fff", ml: 2 }}
                                            />
                                          )}
                                        </ListItemButton>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </Box>
                                <Box sx={{ mt: 3 }}>
                                  <Typography
                                    sx={{
                                      color: "#fff",
                                      fontWeight: 500,
                                      fontSize: {
                                        lg: "14px",
                                        md: "14px",
                                        sm: "12px",
                                        xs: "12px",
                                      },
                                    }}
                                  >
                                    Seating Area
                                  </Typography>
                                  {loadingSeatingArea && (
                                    <Box sx={{ mt: 1 }}>
                                      <LinearProgress />
                                      <Typography
                                        sx={{
                                          mt: 2,
                                          textAlign: "center",
                                          color: "#ccc",
                                        }}
                                      >
                                        Getting seating areas...{" "}
                                      </Typography>
                                    </Box>
                                  )}
                                  <Grid container spacing={2} sx={{ mt: 2 }}>
                                    {!seatingArea ||
                                    seatingArea?.length === 0 ? (
                                      <>
                                        <Box>
                                          <Typography
                                            sx={{
                                              py: 2,
                                              textAlign: "center",
                                              color: "#fff",
                                            }}
                                          >
                                            No Seating Area Available
                                          </Typography>
                                        </Box>
                                      </>
                                    ) : (
                                      <>
                                        {seatingArea?.map((area, index) => (
                                          <Grid
                                            item
                                            lg={4}
                                            md={4}
                                            sm={6}
                                            xs={6}
                                            key={index}
                                          >
                                            <ListItemButton
                                              selected={floorplan === area.Id}
                                              onClick={() =>
                                                setFloorPlan(area?.Id)
                                              }
                                              sx={{
                                                borderRadius: 2,
                                                p: 2,
                                                width: "100%",
                                                bgcolor: "#333",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                "&.Mui-selected": {
                                                  bgcolor: "#BC172F",
                                                },
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  color: "#fff",
                                                  width: "60%",
                                                }}
                                              >
                                                {area?.Name}
                                              </Typography>
                                              {floorplan === area.Id ? (
                                                <AdjustOutlinedIcon
                                                  sx={{ color: "#fff", ml: 2 }}
                                                />
                                              ) : (
                                                <CircleOutlinedIcon
                                                  sx={{ color: "#fff", ml: 2 }}
                                                />
                                              )}
                                            </ListItemButton>
                                          </Grid>
                                        ))}
                                      </>
                                    )}
                                  </Grid>
                                </Box>
                                <Box sx={{ mt: 4 }}>
                                  <Typography
                                    sx={{
                                      color: "#fff",
                                      fontWeight: 500,
                                      fontSize: {
                                        lg: "14px",
                                        md: "14px",
                                        sm: "12px",
                                        xs: "12px",
                                      },
                                    }}
                                  >
                                    Time
                                  </Typography>
                                  {isTimeLoading ? (
                                    <>
                                      <Box sx={{ mt: 1 }}>
                                        <LinearProgress />
                                        <Typography
                                          sx={{
                                            mt: 2,
                                            textAlign: "center",
                                            color: "#ccc",
                                          }}
                                        >
                                          Getting available time...{" "}
                                        </Typography>
                                      </Box>
                                    </>
                                  ) : (
                                    <>
                                      {timeError && (
                                        <Typography color="primary" sx={{}}>
                                          {timeError}
                                        </Typography>
                                      )}
                                      {timeList && (
                                        <Grid
                                          container
                                          spacing={2}
                                          sx={{
                                            mt: 2,
                                            maxHeight: "200px",
                                            overflow: "scroll",
                                          }}
                                        >
                                          {timeList[0]?.map((item, index) => (
                                            <Grid
                                              item
                                              md={3}
                                              sm={4}
                                              xs={4}
                                              key={index}
                                            >
                                              <Box
                                                onClick={() =>
                                                  setTime(item?.Time)
                                                }
                                                sx={{
                                                  bgcolor: "#333",
                                                  height: "50px",
                                                  borderRadius: "5px",
                                                  cursor: "pointer",
                                                  display: "grid",
                                                  placeItems: "center",
                                                  transition: "0.2s all linear",
                                                  ...(time === item?.Time && {
                                                    bgcolor: "#BC172F",
                                                  }),
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    color: "#fff",
                                                    fontWeight: 500,
                                                    textAlign: "center",
                                                    fontSize: {
                                                      lg: "12px",
                                                      md: "12px",
                                                      sm: "12px",
                                                      xs: "10px",
                                                    },
                                                  }}
                                                >
                                                  {item?.Time}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                          ))}
                                          {timeList[1] &&
                                            timeList[1]?.map((item, index) => (
                                              <Grid
                                                item
                                                md={3}
                                                sm={4}
                                                xs={4}
                                                key={index}
                                              >
                                                <Box
                                                  onClick={() =>
                                                    setTime(item?.Time)
                                                  }
                                                  sx={{
                                                    bgcolor: "#333",
                                                    height: "50px",
                                                    borderRadius: "5px",
                                                    cursor: "pointer",
                                                    display: "grid",
                                                    placeItems: "center",
                                                    transition:
                                                      "0.2s all linear",
                                                    ...(time === item?.Time && {
                                                      bgcolor: "#BC172F",
                                                    }),
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      color: "#fff",
                                                      fontWeight: 500,
                                                      textAlign: "center",
                                                      fontSize: {
                                                        lg: "12px",
                                                        md: "12px",
                                                        sm: "12px",
                                                        xs: "10px",
                                                      },
                                                    }}
                                                  >
                                                    {item?.Time}
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                            ))}
                                        </Grid>
                                      )}
                                    </>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            <Box align="right" sx={{ mt: 3 }}>
                              <Button
                                disabled={!experience || !time || !floorplan}
                                sx={{ textTransform: "initial", px: 5 }}
                                variant="contained"
                                onClick={() => setActiveStep(3)}
                              >
                                Proceed
                              </Button>
                            </Box>
                          </>
                        )}

                        {activeStep === 3 && (
                          <>
                            {addGuest ? (
                              <>
                                {isLoading && <Loader />}
                                <Box
                                  sx={{ maxHeight: "72vh", overflow: "scroll" }}
                                >
                                  <Box sx={{ mt: 4, px: 2, pl: 4 }}>
                                    <Typography
                                      sx={{
                                        color: "#fff",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                      }}
                                    >
                                      Contact Information
                                    </Typography>
                                    <Box
                                      sx={{
                                        mt: 3,
                                        display: "grid",
                                        placeItems: "center",
                                      }}
                                    >
                                      <img src={dp} alt="dp_icon" />
                                    </Box>
                                    <Box sx={{ mt: 3, bgcolor: "#333" }}>
                                      <Box
                                        sx={{
                                          borderBottom: "0.5px solid #fff",
                                          py: 2,
                                          px: 3,
                                        }}
                                      >
                                        <Grid container>
                                          <Grid item lg={6}>
                                            <Typography
                                              sx={{
                                                color: "#fff",
                                                fontSize: "13px",
                                                fontWeight: 400,
                                              }}
                                            >
                                              First Name *
                                            </Typography>
                                          </Grid>
                                          <Grid item lg={6}>
                                            <Box sx={{}}>
                                              <CssTextField
                                                fullWidth
                                                value={firstName}
                                                onChange={(e) =>
                                                  setFirstName(e.target.value)
                                                }
                                                InputProps={{
                                                  style: {
                                                    fontFamily: "outfit",
                                                    fontSize: "13px",
                                                    borderRadius: "10px",
                                                    color: "#fff",
                                                    fontWeight: 500,
                                                  },
                                                }}
                                                size="small"
                                                placeholder="Enter first name"
                                                id="custom-css-outlined-input"
                                              />
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                      <Box
                                        sx={{
                                          borderBottom: "0.5px solid #fff",
                                          py: 2,
                                          px: 3,
                                        }}
                                      >
                                        <Grid container>
                                          <Grid item lg={6}>
                                            <Typography
                                              sx={{
                                                color: "#fff",
                                                fontSize: "13px",
                                                fontWeight: 400,
                                              }}
                                            >
                                              Last Name
                                            </Typography>
                                          </Grid>
                                          <Grid item lg={6}>
                                            <Box sx={{}}>
                                              <CssTextField
                                                fullWidth
                                                value={lastName}
                                                onChange={(e) =>
                                                  setLastName(e.target.value)
                                                }
                                                InputProps={{
                                                  style: {
                                                    fontFamily: "outfit",
                                                    fontSize: "13px",
                                                    borderRadius: "10px",
                                                    color: "#fff",
                                                    fontWeight: 500,
                                                  },
                                                }}
                                                size="small"
                                                placeholder="Last Name"
                                                id="custom-css-outlined-input"
                                              />
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                      <Box
                                        sx={{
                                          borderBottom: "0.5px solid #fff",
                                          py: 2,
                                          px: 3,
                                        }}
                                      >
                                        <Grid container>
                                          <Grid item lg={6}>
                                            <Typography
                                              sx={{
                                                color: "#fff",
                                                fontSize: "13px",
                                                fontWeight: 400,
                                              }}
                                            >
                                              Phone Number
                                            </Typography>
                                          </Grid>
                                          <Grid item lg={6}>
                                            <Box sx={{}}>
                                              <CssTextField
                                                type="number"
                                                fullWidth
                                                value={phone}
                                                onChange={(e) =>
                                                  setPhone(e.target.value)
                                                }
                                                InputProps={{
                                                  style: {
                                                    fontFamily: "outfit",
                                                    fontSize: "13px",
                                                    borderRadius: "10px",
                                                    color: "#fff",
                                                    fontWeight: 500,
                                                  },
                                                }}
                                                size="small"
                                                placeholder="Phone Number"
                                                id="custom-css-outlined-input"
                                              />
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                      <Box
                                        sx={{
                                          borderBottom: "0.5px solid #fff",
                                          py: 2,
                                          px: 3,
                                        }}
                                      >
                                        <Grid container>
                                          <Grid item lg={6}>
                                            <Typography
                                              sx={{
                                                color: "#fff",
                                                fontSize: "13px",
                                                fontWeight: 400,
                                              }}
                                            >
                                              Email Address
                                            </Typography>
                                          </Grid>
                                          <Grid item lg={6}>
                                            <Box sx={{}}>
                                              <CssTextField
                                                fullWidth
                                                value={email}
                                                onChange={(e) =>
                                                  setEmail(e.target.value)
                                                }
                                                InputProps={{
                                                  style: {
                                                    fontFamily: "outfit",
                                                    fontSize: "13px",
                                                    borderRadius: "10px",
                                                    color: "#fff",
                                                    fontWeight: 500,
                                                  },
                                                }}
                                                size="small"
                                                placeholder="Email address"
                                                id="custom-css-outlined-input"
                                              />
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                      <Box
                                        sx={{
                                          borderBottom: "0.5px solid #fff",
                                          py: 2,
                                          px: 3,
                                        }}
                                      >
                                        <Grid container>
                                          <Grid item lg={6}>
                                            <Typography
                                              sx={{
                                                color: "#fff",
                                                fontSize: "13px",
                                                fontWeight: 400,
                                              }}
                                            >
                                              Birthday
                                            </Typography>
                                          </Grid>
                                          <Grid item lg={6}>
                                            <Box sx={{}}>
                                              <CssTextField
                                                type="date"
                                                value={dob}
                                                required
                                                onChange={(e) =>
                                                  setDob(e.target.value)
                                                }
                                                InputProps={{
                                                  style: {
                                                    fontFamily: "outfit",
                                                    fontSize: "13px",
                                                    borderRadius: "10px",
                                                    color: "#fff",
                                                    fontWeight: 500,
                                                  },
                                                }}
                                                size="small"
                                                placeholder="e.g Jan 1"
                                                id="custom-css-outlined-input"
                                              />
                                            </Box>
                                          </Grid>
                                        </Grid>

                                        <Box sx={{ my: 3, mt: 6 }}>
                                          <Button
                                            fullWidth
                                            sx={{
                                              textTransform: "initial",
                                              borderRadius: "10px",
                                              py: 2,
                                            }}
                                            variant="contained"
                                            disabled={
                                              !firstName || (!email && !phone)
                                            }
                                            onClick={handleCreateGuest}
                                          >
                                            {isLoading
                                              ? "Creating guest..."
                                              : "Create Guest"}
                                          </Button>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ) : (
                              <>
                                <Box
                                  sx={{
                                    display: "grid",
                                    placeItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: {
                                        lg: "90%",
                                        md: "90%",
                                        sm: "100%",
                                        xs: "100%",
                                      },
                                    }}
                                  >
                                    <>
                                      <Box sx={{ p: 2 }} aria-describedby={id}>
                                        <InputLabel
                                          sx={{
                                            color: "#fff",
                                            fontSize: "12px",
                                            fontWeight: 400,
                                          }}
                                        >
                                          Add guest information
                                        </InputLabel>

                                        <TextField
                                          fullWidth
                                          value={searchParams}
                                          onChange={handleClick}
                                          aria-describedby={id}
                                          placeholder="Enter guest  name, email or phone number"
                                          margin="normal"
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <SearchIcon
                                                    sx={{ color: "#fff" }}
                                                  />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  onClick={() => {
                                                    setAnchorEl(false);
                                                    setSearchParams("");
                                                  }}
                                                >
                                                  <CloseOutlined
                                                    sx={{ color: "#fff" }}
                                                  />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            style: {
                                              fontFamily: "outfit",
                                              fontSize: "13px",
                                              borderRadius: "10px",
                                              border: "1px solid #BC172F",
                                              color: "#fff",
                                              offset: " 1px solid #fff",
                                            },
                                          }}
                                        />
                                        <Typography
                                          sx={{
                                            color: "#fff",
                                            fontSize: "10px",
                                            textAlign: "center",
                                          }}
                                        >
                                          You can either choose a guest that
                                          already exists in the search results
                                          or create a new guest entry based on
                                          your search.
                                        </Typography>

                                        {anchorEl && (
                                          <>
                                            <Box
                                              sx={{
                                                bgcolor: "#333",
                                                borderRadius: 2,
                                                maxHeight: "300px",
                                                overflow: "scroll",

                                                mt: 1,
                                              }}
                                            >
                                              <MenuItem
                                                onClick={() =>
                                                  setAddGuest(true)
                                                }
                                                sx={{
                                                  borderBottom:
                                                    "1px solid #BC172F",
                                                  py: 1,
                                                  px: 2,
                                                  display: "flex",
                                                  columnGap: 3,
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    bgcolor: "#555",
                                                    display: "grid",
                                                    placeItems: "center",
                                                    width: "40px",
                                                    height: "40px",
                                                    borderRadius: 1,
                                                  }}
                                                >
                                                  <AddIcon
                                                    sx={{ color: "#fff" }}
                                                  />
                                                </Box>
                                                <Typography
                                                  sx={{
                                                    color: "#fff",
                                                    fontFamily: "outfit",
                                                    fontSize: {
                                                      lg: "14px",
                                                      md: "14px",
                                                      sm: "12px",
                                                      xs: "12px",
                                                    },
                                                    fontWeight: 400,
                                                  }}
                                                >{`Add "${searchParams}" to guestbook`}</Typography>
                                              </MenuItem>

                                              {!data || data.length === 0 ? (
                                                <>
                                                  {/* <MenuItem
                                            onClick={() => setIsAdd(true)}
                                            sx={{
                                              borderBottom: "1px solid #BC172F",
                                              py: 1,
                                              px: 2,
                                              display: "flex",
                                              columnGap: 3,
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                bgcolor: "#555",
                                                display: "grid",
                                                placeItems: "center",
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: 1,
                                              }}
                                            >
                                              <AddIcon sx={{ color: "#fff" }} />
                                            </Box>
                                            <Typography
                                              sx={{
                                                color: "#fff",
                                                fontFamily: "outfit",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                              }}
                                            >{`Add "${searchParams}" to guestbook`}</Typography>
                                          </MenuItem> */}
                                                  {searchLoading && (
                                                    <LinearProgress />
                                                  )}

                                                  <Box sx={{ p: 2, mt: 2 }}>
                                                    <Typography
                                                      sx={{
                                                        color: "#fff",
                                                        fontFamily: "outfit",
                                                        fontSize: "12px",
                                                        fontWeight: 500,
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {searchLoading
                                                        ? "Searching..."
                                                        : ` "${searchParams}"not found in the Guestbook  `}
                                                    </Typography>
                                                  </Box>
                                                </>
                                              ) : (
                                                <>
                                                  {isLoading && (
                                                    <LinearProgress />
                                                  )}
                                                  {data?.map((item, index) => (
                                                    <MenuItem
                                                      sx={{
                                                        columnGap: 2,
                                                        py: 1,
                                                      }}
                                                      key={index}
                                                      onClick={() => {
                                                        setIsCard(true);
                                                        setCardData(item);
                                                        setGuestId(item.Id);
                                                      }}
                                                    >
                                                      <Avatar />
                                                      <Box>
                                                        <Typography
                                                          sx={{
                                                            color: "#fff",
                                                            fontSize: {
                                                              lg: "12px",
                                                              md: "12px",
                                                              sm: "11px",
                                                              xs: "10px",
                                                            },
                                                            fontFamily:
                                                              "outfit",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          {`${item?.FirstName} ${item?.LastName}`}
                                                        </Typography>
                                                        <Typography
                                                          sx={{
                                                            color: "#fff",
                                                            fontSize: "10px",
                                                            fontFamily:
                                                              "outfit",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          {item?.EmailAddress}
                                                        </Typography>
                                                        <Typography
                                                          sx={{
                                                            color: "#fff",
                                                            fontSize: "10px",
                                                            fontFamily:
                                                              "outfit",
                                                            fontWeight: 300,
                                                          }}
                                                        >
                                                          {item?.PhoneNumber}
                                                        </Typography>
                                                      </Box>
                                                    </MenuItem>
                                                  ))}
                                                </>
                                              )}
                                            </Box>
                                          </>
                                        )}
                                      </Box>
                                    </>
                                  </Box>
                                </Box>
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default NewReservation;
