import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  InputLabel,
  TextField,
  IconButton,
  Avatar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import emptyIcon from "../../../assets/img/emptyRes.svg";
import userAdd from "../../../assets/Icons/user-add.svg";
import { Link } from "react-router-dom";
import TableCard from "../../Components/Tables/TableCard";
import { getFloorPlan } from "../../../api";
import Loader from "../../Common/Loader";
import { Helmet } from "react-helmet-async";
import SEO from "../../../Utility/SEO";
import { ThemeContext } from "../../../STYLES/theme/ThemeContextProvider";
import NoMobile from "../Screens/NoMobile";

const Tables = () => {
  const [show, setShow] = useState(true);
  const [planData, setPlanData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleGetFloorPlan = async () => {
    setLoading(true);
    await getFloorPlan()
      .then((res) => {
        setLoading(false);
        const { data } = res;

        if (data.status) {
          setPlanData(data?.result);
        } else {
          setPlanData(null);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    handleGetFloorPlan();
  }, []);

  const { isDarkTheme } = useContext(ThemeContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <SEO
        title="Reisty | Restaurant Tables Management"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
        canonicalUrl="https://restaurant.reisty.com/dashboard/table-rooms"
      />
      {isMobile ? (
        <>
          <NoMobile />
        </>
      ) : (
        <>
          {loading && <Loader />}
          {!planData || planData?.length === 0 ? (
            <Box
              sx={{
                // border: "1px solid red",
                // height: "80vh",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Box align="center" sx={{ width: "70%", mt: 10 }}>
                <img src={emptyIcon} alt="icon" onClick={() => setShow(true)} />
                <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                  No rooms or tables
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: 300, mt: 2 }}>
                  Your haven’t setup the tables for your restaurant. Click on
                  create table button below to create a Table
                </Typography>
                <Link to="/floor-plan">
                  <Button
                    variant="contained"
                    sx={{
                      mt: 3,
                      px: 3,
                    }}
                  >
                    Create Room
                  </Button>
                </Link>
              </Box>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: isDarkTheme ? "#1a1a1a" : "#ffff",
                  px: 3,

                  borderBottom: "0.5px solid #ccccccb7",
                  height: "80px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "24px",
                        md: "24px",
                        sm: "14px",
                        xs: "14px",
                      },
                      fontWeight: 700,
                      fontFamily: "optima",
                    }}
                  >
                    Floor Plan
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: "12px" }}
                    className="two-line-ellipsis"
                  >
                    Visualize and manage your restaurant's seating layout, track
                    table status, and optimize reservations in real-time.
                  </Typography>
                </Box>
                <Link to="/floor-plan">
                  <Button variant="contained">Create Floor Plan</Button>
                </Link>
              </Box>
              <Box sx={{ p: 3 }}>
                <Grid container spacing={2}>
                  {planData &&
                    planData.map((item, index) => (
                      <Grid item lg={3} md={3} sm={4} xs={12} key={index}>
                        <Box
                          sx={{
                            cursor: "pointer",
                            transition: "0.2s all linear",
                            "&:hover": { bgcolor: "#ffffff1a" },
                          }}
                        >
                          <TableCard
                            img={item?.Logo}
                            name={item?.Name}
                            id={item?.Id}
                            action={handleGetFloorPlan}
                            data={item}
                          />
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Tables;
