import {
  Box,
  AppBar,
  Grid,
  IconButton,
  Typography,
  List,
  ListItemButton,
  useMediaQuery,
  useTheme,
  Avatar,
  Popover,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import logo from "../../assets/Logo/single.svg";
import logo2 from "../../assets/Logo/logo-red.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import Drawers from "./Drawers";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/userSlice";
import Loader from "../Common/Loader";
import { ThemeContext } from "../../STYLES/theme/ThemeContextProvider";
import { PATHS } from "../../Routes/route.path";
import { getRestuarents } from "../../store/restaurantSlice";

const TopNav = () => {
  const { isDarkTheme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const router = useLocation();
  const user = JSON.parse(window.localStorage.getItem("userInfo"));
  const restaurantName = localStorage.getItem("restaurantName");
  const restaurantLogo = localStorage.getItem("restaurantLogo");
  const userPermissions = useSelector(
    (state) => state?.user?.user?.Permissions
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(()=>{
dispatch(getRestuarents())
  },[])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const backHome = () => {
    navigate(PATHS.HOME);
  };
  const handleLogout = () => {
    dispatch(logoutUser());
    localStorage.removeItem("userInfo");
    navigate("/login");
  };

  const location = useLocation();

  return (
    <>
      <AppBar
        sx={{
          background: isDarkTheme ? "#000000" : "#fefcfc",
          height: "70px",
          py: 1,
          borderBottom: "0.2px solid #333",
          boxShadow: "none",
        }}
      >
        {isMatch ? (
          <>
            <Drawers
              restaurantName={restaurantName}
              name={`${user.first_name} ${" " + " "} ${user.last_name.charAt(
                0
              )}.`}
              logout={handleLogout}
            />
          </>
        ) : (
          <Grid
            container
            sx={{
              margin: "0 auto",
              width: "98%",
              display: "flex",
              // height: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item lg={6} md={6}>
              <Box
                sx={{
                  display: "flex",

                  alignItems: "center",
                }}
              >
                <Link to="/dashboard">
                  {isDarkTheme ? (
                    <img src={logo} alt="reisty_logo" />
                  ) : (
                    <img src={logo2} alt="reisty_logo" />
                  )}
                </Link>
                <List sx={{ display: "flex", ml: 3 }}>
                  {userPermissions?.AccessReservations && (
                    <Link to="/reservation-management">
                      <ListItemButton
                        selected={router.pathname === "/reservation-management"}
                        variant="caption"
                        sx={{
                          fontSize: "14px",
                          fontFamily: "outfit",
                          "&.Mui-selected": {
                            background: "none",

                            borderBottom: "4px solid #BC172F",
                          },
                        }}
                      >
                        Reservations
                      </ListItemButton>
                    </Link>
                  )}
                  <Link to="/guest-floorplan">
                    <ListItemButton
                      selected={router.pathname === "/guest-floorplan"}
                      variant="caption"
                      sx={{
                        fontSize: "14px",
                        fontFamily: "outfit",
                        "&.Mui-selected": {
                          background: "none",

                          borderBottom: "4px solid #BC172F",
                        },
                      }}
                    >
                    Floorplan
                    </ListItemButton>
                  </Link>
                  <Link to={PATHS.DASHBOARD.GUESTBOOK}>
                    <ListItemButton
                      selected={router.pathname === PATHS.DASHBOARD.GUESTBOOK}
                      variant="caption"
                      sx={{
                        fontSize: "14px",
                        fontFamily: "outfit",
                        "&.Mui-selected": {
                          background: "none",

                          borderBottom: "4px solid #BC172F",
                        },
                      }}
                    >
                    Guest
                    </ListItemButton>
                  </Link>
                  {/* <ListItemButton
                  variant="caption"
                  sx={{
          
                    fontSize: "14px",
                    fontFamily: "outfit",
                  }}
                >
                  POS
                </ListItemButton> */}
                  <Link to="/dashboard/restaurantprofile">
                    <ListItemButton
                      selected={
                        router.pathname === "/dashboard/restaurantprofile"
                      }
                      variant="caption"
                      sx={{
                        fontSize: "14px",
                        fontFamily: "outfit",
                        "&.Mui-selected": {
                          background: "none",

                          borderBottom: "4px solid #BC172F",
                        },
                      }}
                    >
                      Settings
                    </ListItemButton>
                  </Link>
                  <Link to="/dashboard/advanced-reports">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ListItemButton
                        selected={router.pathname?.includes(
                          "/dashboard/advanced-reports"
                        )}
                        variant="caption"
                        sx={{
                          fontSize: "14px",
                          fontFamily: "outfit",
                          "&.Mui-selected": {
                            background: "none",

                            borderBottom: "4px solid #BC172F",
                          },
                        }}
                      >
                        Advanced Reports
                      </ListItemButton>
                      <Typography
                        sx={{
                          color: "#BC172F",
                          border: "1px solid #BC172F",
                          borderRadius: "50px",
                          width: "fit-content",
                          px: 1,
                          fontSize: "8px",
                          fontWeight: 500,
                          lineHeight: "16px",
                          opacity: 0.6,
                        }}
                      >
                        New
                      </Typography>
                    </Box>
                  </Link>
                </List>
              </Box>
            </Grid>
            <Grid item xl={1.5} lg={2} md={3.5}>
              <Box
                // align="right"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Avatar variant="rounded" src={restaurantLogo} />
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "outfit",
                        fontWeight: 500,
                        textTransform: "capitalize",
                        width: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "text.primary",
                      }}
                    >
                      {restaurantName ? restaurantName : ""}
                    </Typography>

                    <Typography
                      sx={{
                        color: "text.primary",
                        fontSize: "10px",
                        fontFamily: "outfit",
                        fontWeight: 400,
                        width: "100px",

                        lineHeight: "12px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {`${user.first_name} ${" " + " "} ${user.last_name.charAt(
                        0
                      )}.`}
                    </Typography>
                  </Box>
                </Box>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon sx={{ color: "text.primary" }} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        )}
      </AppBar>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 1 }}>
          <Link
            to="/dashboard/select"
            state={{ redirectTo: location.pathname }}
          >
            <MenuItem onClick={backHome}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "text.primary",
                }}
              >
                {" "}
                Select Restaurant
              </Typography>
            </MenuItem>
          </Link>
          <MenuItem onClick={backHome}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: 400, color: "text.primary" }}
            >
              {" "}
              Back to Homepage
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleLogout} sx={{ borderTop: "0.5px solid " }}>
            {" "}
            <Typography
              sx={{ fontSize: "12px", fontWeight: 400, color: "text.primary" }}
            >
              {" "}
              Logout
            </Typography>
          </MenuItem>
        </Box>
      </Popover>
    </>
  );
};

export default TopNav;
