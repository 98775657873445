import React from "react";
import { saveAs } from "file-saver";
import { unparse } from "papaparse";
import { Button, InputLabel } from "@mui/material";
import { TbFileTypeCsv } from "react-icons/tb";

const CsvExport = ({ data, name }) => {
  const handleExport = () => {
    const csv = unparse(data);

    const blob = new Blob([csv], { type: "text/csv; charset=utf-8;" });

    saveAs(blob, `${name}.csv`);
  };
  return (
    <>
      <Button
        startIcon={<TbFileTypeCsv />}
        variant="contained"
        onClick={handleExport}
        sx={{
          borderRadius: "8px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        <InputLabel sx={{ color: "#fff" }}>Export as CSV</InputLabel>
      </Button>
    </>
  );
};

export default CsvExport;
