import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";

import Approve from "./Actions/Approve";
import MoveToSeated from "./Actions/MoveToSeated";
import Cancel from "./Actions/Cancel";
import MoveToFInished from "./Actions/MoveToFInished";
import ManageReservation from "./Actions/ManageReservation";
import CountdownTimer from "../../../../helpers/CountDownTimer";

import img1 from "../../../../assets/Icons/guestMgt/1.svg";
import img2 from "../../../../assets/Icons/guestMgt/2.svg";
import img3 from "../../../../assets/Icons/guestMgt/3.svg";
import img4 from "../../../../assets/Icons/guestMgt/4.svg";
import img5 from "../../../../assets/Icons/guestMgt/5.svg";
import crown from "../../../../assets/Icons/crown.svg";

import people from "../../../../assets/Icons/people.svg";
import note from "../../../../assets/Icons/document-text.svg";
import forbidden from "../../../../assets/Icons/forbidden.svg";
import SellIcon from "@mui/icons-material/Sell";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { BsBookmarkCheckFill } from "react-icons/bs";
import { MdFileDownloadDone } from "react-icons/md";
import { MdChairAlt } from "react-icons/md";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { MdOutlineAirlineSeatReclineNormal } from "react-icons/md";
import { HiOutlineStar } from "react-icons/hi2";
import { CloseOutlined } from "@mui/icons-material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import Person4OutlinedIcon from "@mui/icons-material/Person4Outlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { MdManageHistory } from "react-icons/md";

import InternalNotes from "../Notes/InternalNotes";
import Notes from "../Notes/Notes";

import {
  Box,
  Typography,
  IconButton,
  Button,
  Grid,
  Modal,
  InputLabel,
  Divider,
} from "@mui/material";
import { changeBookingStatus } from "../../../../api";
import Loader from "../../../Common/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 700, md: 700, sm: 400, xs: 350 },
  maxHeight: "70vh",
  overflow: "scroll",
  bgcolor: "#1a1a1a",
  borderRadius: 2,
  boxShadow: 24,
  p: 0,
};

const ReservationCard = ({
  borderColor,
  data,
  action,
  finished,
  upcoming,
  active_id,
}) => {
  const [openApprove, setOpenApprove] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openSeated, setOpenSeated] = React.useState(false);
  const [openCancel, setOpenCancel] = React.useState(false);
  const [openFinished, setOpenFinished] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (active_id === data?.BoookingId) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [active_id, data]);

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = (newOpen) => {
    setOpenDrawer(newOpen);
  };

  const [isLoading, setIsLoading] = useState(false);

  const restaurantName = localStorage.getItem("restaurantName");
  const isNostalgia = data?.RestaurantName === "NOSTALGIA LAGOS";

  const handleModifyReservation = async (status) => {
    setIsLoading(true);
    await changeBookingStatus(data?.BoookingId, status)
      .then((res) => {
        setIsLoading(false);
        action();
        setOpenFinished(false);
        setOpenSeated(false);
        setOpenCancel(false);
        setOpen(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // console.log(data, "AND" , active_id)

  return (
    <>
      {isLoading && <Loader />}
      <Box
        onClick={handleOpen}
        sx={{
          p: 1,
          border: `2px solid ${borderColor}`,
          borderRadius: 1,
          height: "150px",
          cursor: "pointer",
          display: "flex",
          overflow: "hidden",
          justifyContent: "space-between",
          flexDirection: "column",
          "&:hover": {
            bgcolor: "#333",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 1,
            flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" },
            rowGap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
              width: {md:"60%", xs:'90%'}
            }}
          >
            <SellIcon sx={{ color: "#fff", fontSize: "12px" }} />

            <InputLabel
              sx={{
                color: "#fff",
                fontWeight: 500,
                fontSize: { lg: "12px", md: "12px", sm: "12px", xs: "10px" },
              }}
            >
              {data?.bookingUserDetails?.Name
                ? data?.bookingUserDetails?.Name
                : "No Name"}
            </InputLabel>
          </Box>
          <Box
            sx={{
              bgcolor: "rgba(223, 142, 20, 0.40)",
              px: 1,
              borderRadius: 1,
              display: "flex",
              alignItems: "center",
              columnGap: 1,
              maxWidth: {md:"38%", xs:'70%'}
            }}
          >
            <img src={crown} alt="crown_icon" />
            <InputLabel
              sx={{ color: "#fff", fontSize: "10px", lineHeight: "15px" }}
            >
              {data?.RestaurantName ? data?.RestaurantName : "--"}
            </InputLabel>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
          <TourOutlinedIcon sx={{ color: "#565660", fontSize: "12px" }} />
          <Typography
            sx={{
              color: "#fff",
              fontWeight: 400,
              fontSize: { lg: "12px", md: "12px", sm: "10px", xs: "10px" },
            }}
          >
            {data?.TableName ? data?.TableName : "--"}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", columnGap: 2, mt: 0 }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
              width: "100%",
            }}
          >
            <WidgetsOutlinedIcon sx={{ color: "#565660", fontSize: "12px" }} />
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 400,
                fontSize: { lg: "12px", md: "12px", sm: "10px", xs: "10px" },
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
                columnGap: 0.7,
              }}
            >
              {`${data?.BookingTime} -  `}
              {(data?.TimeCount && upcoming) && (
                <CountdownTimer initialTime={data?.TimeCount} />
              )}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
            <Person4OutlinedIcon sx={{ color: "#565660", fontSize: "12px" }} />
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 400,
                fontSize: { lg: "12px", md: "12px", sm: "10px", xs: "10px" },
              }}
            >
              {data?.Guest ? data?.Guest : "--"}
            </Typography>
          </Box>

          <IconButton>
            {isNostalgia && data?.IsConfirmed ? (
              <MdFileDownloadDone
                style={{ fontSize: "20px", color: "#47E673" }}
              />
            ) : (
              <MoreHorizOutlinedIcon sx={{ color: "#fff" }} />
            )}
          </IconButton>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="hide_scrollbar">
          <Box
            sx={{
              p: 2,
              px: { lg: 4, md: 4, sm: 2, xs: 2 },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: `1px solid ${borderColor}`,
            }}
          >
            <Typography
              sx={{ color: borderColor, fontWeight: 400, fontFamily: "optima" }}
            >
              Reservation
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlined sx={{ color: "#fff" }} />
            </IconButton>
          </Box>
          <Box sx={{ py: 3, px: { lg: 4, md: 4, sm: 2, xs: 2 } }}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    p: 2,
                    py: 1,
                    borderRadius: "10px 10px 0px 0px",
                    bgcolor: "#4d4d4d",
                    borderBottom: `1px solid ${borderColor}`,
                    display: "flex",
                    columnGap: { lg: 8, md: 8, sm: 5, xs: 4 },
                    alignItems: "center",
                    justifyContent: "space-between",
                    overflow: "scroll",
                    flexDirection:{md:'row', xs:'column'}
                  }}
                >
           

        
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <AccountCircleRoundedIcon
                      sx={{
                        color: "#D9D9D9",
                        fontSize: {
                          lg: "40px",
                          md: "40px",
                          sm: "30px",
                          xs: "20px",
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: "#fff",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "12px",
                          xs: "10px",
                        },
                        textTransform: "capitalize",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data?.bookingUserDetails?.Name
                        ? data?.bookingUserDetails?.Name
                        : "No Name"}
                    </Typography>
                  </Box>
<Box sx={{display:'flex', alignItems:'center',justifyContent:'space-between', flex:1 , width:'100%', mt:{md:0, xs:1}}}>
        <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                    }}
                  >
                    <img src={people} width={20} alt="people_icon" />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#fff",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "12px",
                          xs: "10px",
                        },
                      }}
                    >
                      {data?.Guest ? data?.Guest : "--"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                      mr: 2,
                    }}
                  >
                    <MdChairAlt style={{ fontSize: "20px" }} />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#fff",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "12px",
                          xs: "10px",
                        },
                      }}
                    >
                      {data?.Guest ? data?.TableInfo?.SeatingArea : "--"}
                    </Typography>
                  </Box>
</Box>
                  </Box>
            
            
                <Box sx={{ bgcolor: "#333", py: 3, px: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img src={img1} width={15} alt="icon" />{" "}
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              lg: "12px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                            fontWeight: 500,
                          }}
                        >
                          {data?.RestaurantName ? data?.RestaurantName : "--"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img src={img2} width={15} alt="icon" />{" "}
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              lg: "12px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                            fontWeight: 500,
                          }}
                        >
                          {data?.BookingTime ? data?.BookingTime : "--"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6}  sm={6} xs={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img src={img3} width={15} alt="icon" />{" "}
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              lg: "12px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                            fontWeight: 500,
                          }}
                        >
                          {data?.bookingUserDetails?.PhoneNumber
                            ? data?.bookingUserDetails?.PhoneNumber
                            : "No Name"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6}  sm={6} xs={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img src={img4} width={15} alt="icon" />{" "}
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              lg: "12px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                            fontWeight: 500,
                          }}
                        >
                          {data?.BookingDate ? data?.BookingDate : "--"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6}  sm={6} xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img src={img5} width={15} alt="icon" />{" "}
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              lg: "12px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                            fontWeight: 500,
                          }}
                        >
                          {data?.bookingUserDetails?.Email
                            ? data?.bookingUserDetails?.Email
                            : "No Name"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6}  sm={6} xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <HiOutlineStar />{" "}
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              lg: "12px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                            fontWeight: 500,
                          }}
                        >
                          {data?.SpecialOccasion || "No Special Occasion"}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  {upcoming && isNostalgia && !data?.IsConfirmed && (
                    <>
                      <Box sx={{display:'grid', placeContent:{md:'right', xs:'center'}, mt:1}}>
                        <Button
                          onClick={() => setOpenApprove(true)}
                          endIcon={<BsBookmarkCheckFill />}
                          variant="outlined"
                          sx={{
                            color: "#fff",
                            fontSize: "12px",
                            borderColor: "#fff",
                          }}
                        >
                          Confirm Reservation
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>

                {upcoming && (
                  <>
                    <Divider />
                    <Button
                      onClick={() => {
                        toggleDrawer(true);
                        handleClose();
                      }}
                      sx={{
                        color: borderColor,

                        borderRadius: "0px 0px 8px 8px",
                        // border: "1px solid #E6475E",
                        fontWeight: 400,
                        fontSize: "12px",
                        bgcolor: "#000",
                        py: 1.5,
                      }}
                      fullWidth
                      size="large"
                      startIcon={
                        <MdManageHistory
                          sx={{ color: "#fff", fontSize: "12px" }}
                        />
                      }
                    >
                      Manage Reservation
                    </Button>
                  </>
                )}

                <Box sx={{ mt: 5 }}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          border: "1px dashed #fff",
                          p: 1.5,
                          borderRadius: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 2,
                          }}
                        >
                          <img src={note} width={15} alt="icon" />
                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: 400,
                              fontSize: "12px",
                            }}
                          >
                            Internal Notes
                          </Typography>
                        </Box>
                        <InternalNotes
                          value={data?.InternalNote ? data?.InternalNote : null}
                          id={data?.BoookingId}
                          type="visit"
                          borderColor={borderColor}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "10px",
                              textDecoration: "underline",
                            }}
                          >
                            Manage
                          </Typography>
                        </InternalNotes>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box
                        sx={{
                          border: "1px dashed #fff",
                          p: 1.5,
                          borderRadius: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 2,
                          }}
                        >
                          <img src={note} width={15} alt="icon" />
                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: 400,
                              fontSize: "12px",
                            }}
                          >
                            Visit Notes
                          </Typography>
                        </Box>
                        <Notes
                          value={
                            data?.VisitNote ? data?.VisitNote : "No Visit  Note"
                          }
                          type="visit"
                          borderColor={borderColor}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "10px",
                              textDecoration: "underline",
                            }}
                          >
                            View
                          </Typography>
                        </Notes>
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box
                        sx={{
                          border: "1px dashed #fff",
                          p: 1.5,
                          borderRadius: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 2,
                          }}
                        >
                          <img src={forbidden} width={15} alt="icon" />
                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: 400,
                              fontSize: "12px",
                            }}
                          >
                            Dietary Restrictions
                          </Typography>
                        </Box>
                        <Notes
                          type="dietary"
                          value={
                            data?.DietaryRestriction
                              ? data?.DietaryRestriction
                              : "No Dietary Restrictions"
                          }
                          borderColor={borderColor}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "10px",
                              textDecoration: "underline",
                            }}
                          >
                            View
                          </Typography>
                        </Notes>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              px: 4,
              py: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderTop: "0.5px solid #fff",
              bgcolor: "#333",
              columnGap: 2,
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              rowGap: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {!finished && (
                <Button
                  onClick={() => setOpenSeated(true)}
                  variant="outlined"
                  sx={{
                    color: "#fff",
                    mr: 2,
                    borderRadius: 2,
                    border: "1px solid #fff",
                    fontWeight: 500,
                    fontSize: "12px",
                  }}
                  size="large"
                  startIcon={
                    <MdOutlineAirlineSeatReclineNormal
                      style={{ fontSize: "18px" }}
                    />
                  }
                >
                  SEATED
                </Button>
              )}

              <Button
                onClick={() => setOpenCancel(true)}
                variant="outlined"
                sx={{
                  color: "#E6475E",

                  borderRadius: 2,
                  border: "1px solid #E6475E",
                  fontWeight: 500,
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                }}
                size="large"
                startIcon={
                  <HighlightOffOutlinedIcon
                    sx={{ color: "#E6475E", fontSize: "12px" }}
                  />
                }
              >
                CANCEL RESERVATION
              </Button>
            </Box>
            {!finished && (
              <Button
                onClick={() => setOpenFinished(true)}
                variant="contained"
                fullWidth
                startIcon={
                  <IoCheckmarkDoneCircleOutline style={{ fontSize: "18px" }} />
                }
                sx={{
                  // color: "#2b2b2b",
                  borderRadius: 2,
                  bgcolor: "#4787E6",
                  fontWeight: 500,
                  "&:hover": {
                    bgcolor: "#4787E6b7",
                  },
                }}
                size="large"
              >
                FINISHED
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      <Approve
        data={data}
        handleAlert={handleAlert}
        action={action}
        handleClose={() => setOpenApprove(false)}
        open={openApprove}
      />

      <Cancel
        open={openCancel}
        handleClose={() => setOpenCancel(false)}
        data={data}
        handleModifyReservation={handleModifyReservation}
      />
      <MoveToSeated
        data={data}
        handleModifyReservation={handleModifyReservation}
        open={openSeated}
        handleClose={() => setOpenSeated(false)}
      />
      <MoveToFInished
        data={data}
        open={openFinished}
        handleClose={() => setOpenFinished(false)}
        handleModifyReservation={handleModifyReservation}
      />
      <ManageReservation
        data={data}
        handleAlert={handleAlert}
        openDrawer={openDrawer}
        toggleDrawer={toggleDrawer}
        borderColor={borderColor}
        action={action}
      />
    </>
  );
};

export default ReservationCard;
