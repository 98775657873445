import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Header";
import emptyRes from "../../../assets/img/emptyRes.svg";

import editIcon from "../../../assets/Icons/edit.svg";
import deleteIcon from "../../../assets/Icons/delete.svg";
import { Link } from "react-router-dom";
import { deleteRestaurant, getRestuarentProfile } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { getRestuarents } from "../../../store/restaurantSlice";
import { CgExtensionAdd } from "react-icons/cg";
import { RiDeleteBin7Fill } from "react-icons/ri";
import Modal from "@mui/material/Modal";
import { useSnackbar } from "notistack";
import Loader from "../../Common/Loader";
import { Helmet } from "react-helmet-async";
import SEO from "../../../Utility/SEO";
import anime from "../../../assets/anime/KY0fkZoRyj.json";
import Lottie from "react-lottie";
import { ThemeContext } from "../../../STYLES/theme/ThemeContextProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 500, md: 500, sm: 400, xs: 350 },
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  borderRadius: "10px",
};

const RestaurantProfile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const [created, setCreated] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (newValue, name) => {
    setId(newValue);
    setOpen(true);
    setName(name);
  };
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const restaurantList = useSelector((state) => state.restaurants.data.result);

  useEffect(() => {
    dispatch(getRestuarents());
  }, []);

  const handleDeleteRestaurant = async () => {
    setIsLoading(true);
    await deleteRestaurant(id)
      .then((res) => {
        const { data } = res;

        setIsLoading(false);
        if (data?.status) {
          setOpen(false);
          dispatch(getRestuarents());
          handleAlert("success", `Deleted successfully`);
        } else {
          handleAlert("error", `Not Deleted `);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err.message} `);
      });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { isDarkTheme } = useContext(ThemeContext);

  return (
    <>
      <SEO
        title="Reisty | Restaurant Profile Management"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
        canonicalUrl="https://restaurant.reisty.com/dashboard/restaurantprofile"
      />

      <Box>
        {isLoading && <Loader />}
        <Header
          title="Restaurant Profile"
          description="Manage and create restaurants"
        />
        {!restaurantList ? (
          <Box
            sx={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                px: 2,
              }}
            >
              <Box>
                <img src={emptyRes} alt="emptyRes" />
              </Box>
              <Box align="center">
                <Typography
                  variant="caption"
                  sx={{ fontFamily: "outfit", fontSize: "16px" }}
                >
                  No restaurant profile found
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontSize: "12px",
                  }}
                >
                  You have no restaurant profile . Click on “add new” to add a
                  new restaurant profile
                </Typography>
              </Box>
              <Link to="/quick-setup">
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    background: "text.primary",
                    color: "#1a1a1a",
                    "&:hover": { background: "#dadada", color: "black" },
                  }}
                >
                  Quick Setup
                </Button>
              </Link>
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={{ px: 2, py: 3, height: "80vh", overflow: "scroll" }}>
              <Grid container spacing={2}>
                <Grid item lg={2} md={3} xs={12}>
                  <Box>
                    <Link to="/quick-setup">
                      <Box
                        sx={{
                          border: "0.5px dashed",
                          borderColor: "text.primary",
                          height: "200px",
                          display: "grid",
                          placeItems: "center",
                          p: 1.7,
                          cursor: "pointer",
                          borderRadius: "10px",
                        }}
                      >
                        <Box align="center">
                          <CgExtensionAdd
                            style={{
                              color: isDarkTheme ? "#FFF" : "#333",
                              fontSize: "40px",
                            }}
                          />
                          <Typography
                            sx={{
                              color: "text.primary",
                              fontWeight: 500,
                              fontSize: "12px",
                            }}
                          >
                            Create Restaurant
                          </Typography>
                        </Box>
                      </Box>
                    </Link>
                  </Box>
                </Grid>
                {restaurantList.map((item) => (
                  <Grid item lg={2} md={3} xs={6}>
                    <Box
                      sx={{
                        border: "1px solid ",
                        borderColor: "text.primary",
                        height: "200px",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "10px",
                        p: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "220px",
                          backgroundImage: `url(${item.Logo})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          borderRadius: "8px",
                        }}
                      >
                        <IconButton
                          onClick={() => handleOpen(item?.Id, item.Name)}
                          sx={{
                            borderRadius: "0px",
                            bgcolor: "#333",
                            m: 1,
                            borderRadius: "5px",
                            "&:hover": { bgcolor: "#2b2b2bb7" },
                          }}
                        >
                          <RiDeleteBin7Fill
                            style={{ color: "#fff", fontSize: "14px" }}
                          />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mt: 2,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: "optima",
                              fontSize: "16px",
                              fontWeight: 500,
                              textAlign: "center",
                            }}
                          >
                            {item.Name}
                          </Typography>
                        </Box>
                        <Box></Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        )}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 2,
                borderRadius: "10px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "30%" }}>
                <Lottie options={defaultOptions} width={100} height={100} />
              </Box>
              <Box sx={{}}>
                <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                  {" "}
                  Are you sure you want to delete{" "}
                  <b style={{ textDecoration: "underline" }}>{name}</b>{" "}
                  restaurant?
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "12px",
                    lineHeight: "20px",
                    width: "100%",
                    mt: 1,
                    textAlign: "center",
                  }}
                >
                  This restaurant will be deleted immediately, you can’t undo
                  this action.
                </Typography>

                <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    sx={{ borderRadius: "8px", px: 4 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: "8px", px: 4, ml: 2 }}
                    onClick={handleDeleteRestaurant}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default RestaurantProfile;
