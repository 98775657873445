import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Box,
  Grid,
  Typography,
  Popover,
  Button,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import tag from "../../../../assets/Icons/table/filled/tag.svg";
import clock from "../../../../assets/Icons/table/filled/clock.svg";
import flag from "../../../../assets/Icons/table/filled/flag.svg";
import emptyIcon from "../../../../assets/img/emptyRes.svg";
import calendarIcon from "../../../../assets/Icons/calendar-tick.svg";
import ReservationPieChart from "./ReservationPieChart";
import Calender from "../../../Common/Calender";
import { format, parseISO } from "date-fns";
import Statistics from "./Statistics";
import dataSet from "../AppWidget/Chart/data";
import { getDashboardReviews } from "../../../../api";
import ReservationTable from "./ReservationTable";
import ListDrawer from "./ListDrawer";
import { PATHS } from "../../../../Routes/route.path";
import { useNavigate } from "react-router-dom";

const GeneralOverview = ({
  data,
  handleFilter,
  handleDetailsScreen,
  values,
  handleUpdateDate,
  tableData,
  action,
  valueLoading,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dateObject = new Date();
  const toDate = dateObject.toISOString().split("T")[0];
  const [value, setValue] = React.useState(toDate);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const parsedDate = parseISO(value);
  const formattedDate1 = format(parsedDate, "EEEE, MMMM d");
  // const date = new Date();

  // const formatDate = () => {
  //   const options = { weekday: "long", month: "long", day: "numeric" };
  //   return new Intl.DateTimeFormat("en-US", options).format(date);
  // };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleUpdateDate(value);
  }, [value]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [data2, setData2] = useState(dataSet.Today);

  const fetchCustomData = (key) => {
    setData2(dataSet[key]);
  };

  const [reviews, setReviews] = useState(["sam"]);

  const handleGetReviews = async () => {
    setIsLoading(true);
    await getDashboardReviews()
      .then((res) => {
        if (res?.data?.status) {
          setReviews(res?.data?.result);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetReviews();
  }, []);

  const [openListDrawer, setOpenListDrawer] = useState(false);

  const [drawerList, setDrawerList] = useState(null);



  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <Box
              onClick={() => {
                if (values?.TodayOngoingReservations?.length > 0) {
                  handleDetailsScreen(values?.TodayOngoingReservations);
                }
              }}
              sx={{
                bgcolor: "#19A662",
                p: 3,
                cursor:
                  values?.TodayOngoingReservations?.length > 0
                    ? "pointer"
                    : "not-allowed",
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    fontFamily: "optima",
                  }}
                >
                  Ongoing Reservations
                </Typography>
                {!values?.TodayOngoingReservations ||
                values?.TodayOngoingReservations?.length === 0 ? (
                  <>
                    <Typography sx={{ color: "#ccc", fontSize: "12px" }}>
                      No ongoing reservation available
                    </Typography>
                  </>
                ) : (
                  <Box sx={{ mt: 1 }}>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={tag} width={13} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {
                          values?.TodayOngoingReservations[0]
                            ?.bookingUserDetails?.Name
                        }
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={flag} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayOngoingReservations[0]?.TableName}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={clock} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayOngoingReservations[0]?.BookingTime}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box
              sx={{
                pl: 1,
                pt: 1,
                bgcolor: "#bc172fb7",
                height: "135px",
                borderRadius: "10px",
              }}
            >
              <Box
                onClick={() => {
                  if (values?.TodayUpcomingReservations?.length === 1) {
                    navigate(`${PATHS.DASHBOARD.RESERVATION_MANAGEMENT}?panel=Expected&BookingID=${   values?.TodayUpcomingReservations[0]
                              ?.BoookingId}`);
                  } else if (values?.TodayUpcomingReservations?.length > 1) {
                    navigate(`${PATHS.DASHBOARD.RESERVATION_MANAGEMENT}?panel=Expected`);
                  }
                }}
                sx={{
                  bgcolor: "#BC172F",
                  p: 3,

                  cursor:
                    values?.TodayUpcomingReservations?.length > 0
                      ? "pointer"
                      : "not-allowed",
                  borderRadius: "10px",
                  height: "140px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  boxSizing: "border-box",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "14px",
                      fontWeight: 500,
                      fontFamily: "optima",
                    }}
                  >
                    Upcoming Reservations
                  </Typography>
                  {!values?.TodayUpcomingReservations ||
                  values?.TodayUpcomingReservations?.length === 0 ? (
                    <>
                      {valueLoading ? (
                        <Skeleton sx={{ width: "100%", height: "40px" }} />
                      ) : (
                        <Typography sx={{ color: "#ccc", fontSize: "12px" }}>
                          No upcoming reservation available
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Box sx={{ mt: 1 }}>
                      <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                        <img src={tag} width={13} />
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "10px",
                            fontWeight: 400,
                          }}
                        >
                          {
                            values?.TodayUpcomingReservations[0]
                              ?.bookingUserDetails?.Name
                          }
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                        <img src={flag} width={12} />
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "10px",
                            fontWeight: 400,
                          }}
                        >
                          {values?.TodayUpcomingReservations[0]?.TableName}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                          <img src={clock} width={12} />
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "10px",
                              fontWeight: 400,
                            }}
                          >
                            {values?.TodayUpcomingReservations[0]?.BookingTime}
                          </Typography>
                        </Box>

                        <Typography>See more</Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box
              onClick={() => {
                if (values?.TodayFinishedReservations?.length > 0) {
                  handleDetailsScreen(values?.TodayFinishedReservations);
                }
              }}
              sx={{
                bgcolor: "#4787E6",
                p: 3,
                cursor:
                  values?.TodayFinishedReservations?.length > 0
                    ? "pointer"
                    : "not-allowed",
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    fontFamily: "optima",
                  }}
                >
                  Finished Reservations
                </Typography>
                {!values?.TodayFinishedReservations ||
                values?.TodayFinishedReservations?.length === 0 ? (
                  <>
                    <Typography sx={{ color: "#ccc", fontSize: "12px" }}>
                      No finished reservation available
                    </Typography>
                  </>
                ) : (
                  <Box sx={{ mt: 1 }}>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={tag} width={13} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {
                          values?.TodayFinishedReservations[0]
                            ?.bookingUserDetails?.Name
                        }
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={flag} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayFinishedReservations[0]?.TableName}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={clock} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayFinishedReservations[0]?.BookingTime}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box
              sx={{
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
                height: "400px",
                mt: 3,
              }}
            >
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#BC172F",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "10px 10px 0px 0px",
                  px: 2,
                  height: "60px",
                }}
              >
                <Typography
                  sx={{ color: "#fff", fontSize: "14px", fontFamily: "optima" }}
                >
                  Reservation Notes
                </Typography>
                {/* <Button
                  endIcon={<TiArrowRight />}
                  sx={{ color: "#fff", fontSize: "12px" }}
                >
                  See More
                </Button> */}
              </Box>
              <Box
                sx={{
                  p: 2,
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <Typography sx={{ fontSize: "12px", color: "#fff" }}>
                  Today
                </Typography> */}

                <img src={emptyIcon} width={100} />
                <Typography sx={{ fontSize: "10px", color: "#fff", mt: 2 }}>
                  There are no reservation notes currently
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box
              sx={{
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
                height: "400px",
                mt: 3,
              }}
            >
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#BC172F",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "10px 10px 0px 0px",
                  px: 2,
                  height: "60px",
                }}
              >
                <Typography
                  sx={{ color: "#fff", fontSize: "14px", fontFamily: "optima" }}
                >
                  Reviews
                </Typography>
              </Box>
              {!reviews || reviews.length === 0 ? (
                <>
                  <Box
                    sx={{
                      p: 2,
                      height: "350px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={emptyIcon} width={100} />
                    <Typography sx={{ fontSize: "10px", color: "#fff", mt: 2 }}>
                      There are no reviews currently
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      p: 2,
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "28px", fontWeight: 500, mt: 1 }}
                    >
                      4.5
                    </Typography>
                    <Rating
                      readOnly
                      precision={0.5}
                      value={4.5}
                      sx={{ mt: 1 }}
                    />

                    <Box
                      sx={{
                        bgcolor: "#333333",
                        borderRadius: "8px",
                        p: 1.5,
                        boxSizing: "border-box",
                        width: "95%",
                        mt: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 1,
                          }}
                        >
                          <Avatar></Avatar>
                          <Box>
                            <InputLabel sx={{ fontWeight: 600, color: "#fff" }}>
                              Modupe Asake
                            </InputLabel>
                            <Typography
                              sx={{ fontSize: "10px", color: "#717171" }}
                            >
                              December 2022
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 0.5,
                            }}
                          >
                            <IoMdStar style={{ color: "#DF8E14" }} />
                            <Typography sx={{ fontSize: "10px" }}>
                              4.5
                            </Typography>
                          </Box>
                          <Typography sx={{ fontSize: "10px" }}>
                            a month ago
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{mt:1}}>
                        <Typography sx={{fontSize:'10px', lineHeight:'15px'}}>Absolutely delightful dining experience! The food was exceptional, and the service was top-notch. We'll definitely be coming back for more.</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#333333",
                        borderRadius: "8px",
                        p: 1.5,
                        boxSizing: "border-box",
                        width: "95%",
                        mt: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 1,
                          }}
                        >
                          <Avatar></Avatar>
                          <Box>
                            <InputLabel sx={{ fontWeight: 600, color: "#fff" }}>
                              Modupe Asake
                            </InputLabel>
                            <Typography
                              sx={{ fontSize: "10px", color: "#717171" }}
                            >
                              December 2022
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 0.5,
                            }}
                          >
                            <IoMdStar style={{ color: "#DF8E14" }} />
                            <Typography sx={{ fontSize: "10px" }}>
                              4.5
                            </Typography>
                          </Box>
                          <Typography sx={{ fontSize: "10px" }}>
                            a month ago
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{mt:1}}>
                        <Typography sx={{fontSize:'10px', lineHeight:'15px'}}>Absolutely delightful dining experience! The food was exceptional, and the service was top-notch. We'll definitely be coming back for more.</Typography>
                      </Box>
                    </Box>
                  </Box> */}
                </>
              )}
            </Box>
          </Grid>

          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box
              sx={{
                bgcolor: "background.paper",
                height: "400px",
                borderRadius: "10px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{}}>Reservations</Typography>
                  <Box
                    sx={{
                      bgcolor: "#1A1A1A",
                      cursor: "pointer",
                      px: 1.5,
                      py: 0.5,
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      border: "1px solid #fff",
                    }}
                    onClick={handleClick}
                  >
                    <Box>
                      <img src={calendarIcon} alt="calendar" width="50%" />
                    </Box>
                    <Typography
                      sx={{ color: "#fff", display: "flex", fontSize: "12px" }}
                    >
                      {formattedDate1}
                    </Typography>
                  </Box>
                </Box>
           
                    <ReservationPieChart Rdata={data} />
               
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{}}>
            <Box
              sx={{
                bgcolor: "background.paper",
                height: "400px",
                borderRadius: "10px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography sx={{}}>Statistics</Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "300px",
                    display: "grid",
                    placeContent: "center",
                    // border:'1px solid red'
                  }}
                >
                  <Statistics data={data} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    columnGap: 3,
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <FiberManualRecordIcon sx={{ color: "#FF0025" }} />
                    <Typography sx={{ fontSize: "10px" }}>
                      {data?.TotalOnline || 0} Booked Online
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <FiberManualRecordIcon sx={{ color: "#fff" }} />
                    <Typography sx={{ fontSize: "10px" }}>
                      {data?.TotalWalkIn || 0} Walk-ins
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <ReservationTable
              tableData={tableData}
              action={action}
              handleDetailsScreen={handleDetailsScreen}
            />
          </Grid>
        </Grid>
      </Box>

      {/* CALENDER POPUP */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Calender
          future={false}
          past={false}
          onChildValue={(value) => setValue(value)}
        />
        <Box sx={{ p: 2 }} align="right">
          <Button
            onClick={() => {
              handleClose();
              handleFilter();
            }}
            variant="contained"
            sx={{ px: 4 }}
          >
            Filter
          </Button>
        </Box>
      </Popover>

      <ListDrawer
        open={openListDrawer}
        handleClose={() => setOpenListDrawer(false)}
      />
    </>
  );
};

export default GeneralOverview;
