import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import svgSquare from "../../../assets/img/elements/Seating Plan.svg";
import svgRec from "../../../assets/img/elements/Seating Plan_rec.svg";
import svgCircular from "../../../assets/img/elements/Seating Plan_circle.svg";
import svgOval from "../../../assets/img/elements/Seating Plan_oval.svg";

import group from "../../../assets/Icons/Group.svg";

const CustomElement = ({ data, handleRightClick, selectedElement }) => {
  const [borderColor, setBorderColor] = useState(null);
  useEffect(() => {
    if (data?.status) {
      if(data?.status === "Expected"){
setBorderColor("#E6BF47")
      }
      else if(data?.status === "Seated"){
        setBorderColor("#47E673")
      }
      else if(data?.status === "Finished"){
setBorderColor("#4787E6")
      }
      else if(data?.status === "Cancelled"){
setBorderColor("#E6475E")
      }
    }
  }, [data]);
  return (
    <>
      {data?.Type === "tableRec" && (
        <Box
          sx={{
            background: `url('${svgRec}')`,
            width: "130px",
            height: "130px",
            backgroundSize: "130px",
            transition: "0.2s all linear",
                          backgroundRepeat:'no-repeat',
            border: data?.status && `3px dashed ${borderColor}`,
            // boxShadow:`10px 10px 2005px 2px ${borderColor} `,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 2,
            ...(selectedElement === data && {
              cursor: "move",
              borderRadius: "5px",
              border: "1px dashed red",
            }),
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontSize: "10px",
              fontWeight: 500,
            }}
          >
            {data?.Name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 1,
            }}
          >
            <img src={group} width={8} />

            <Typography
              sx={{
                color: "#fff",
                fontSize: "8px",
                fontWeight: 500,
              }}
            >
              {`${data?.MinSize}-${data?.MaxSize}`}
            </Typography>
          </Box>
        </Box>
      )}
      {data?.Type === "tableSquare" && (
        <IconButton>
          <Box
            sx={{
              background: `url('${svgSquare}')`,
              width: "230px",
              height: "125px",
              backgroundSize: "230px",
              transition: "0.2s all linear",
                            backgroundRepeat:'no-repeat',
    border: data?.status && `3px dashed ${borderColor}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
              ...(selectedElement === data && {
                cursor: "move",
                borderRadius: "5px",
                border: "1px dashed red",
              }),
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "10px",
                fontWeight: 500,
              }}
            >
              {data?.Name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <img src={group} width={8} />

              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                {`${data?.MinSize}-${data?.MaxSize}`}
              </Typography>
            </Box>
          </Box>
        </IconButton>
      )}
      {data?.Type === "tableCircle" && (
        <IconButton>
          <Box
            sx={{
              background: `url('${svgCircular}')`,
              width: "200px",
              height: "200px",
              backgroundSize: "200px",
              transition: "0.2s all linear",
                            backgroundRepeat:'no-repeat',
    border: data?.status && `3px dashed ${borderColor}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              ...(selectedElement === data && {
                cursor: "move",
                borderRadius: "5px",
                border: "1px dashed red",
              }),
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "10px",
                fontWeight: 500,
              }}
            >
              {data?.Name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <img src={group} width={8} />

              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                {`${data?.MinSize}-${data?.MaxSize}`}
              </Typography>
            </Box>
          </Box>
        </IconButton>
      )}
      {data?.Type === "tableOval" && (
        <IconButton>
          <Box
            sx={{
              background: `url('${svgOval}')`,
              width: "150px",
              height: "150px",
              backgroundSize: "150px",
              transition: "0.2s all linear",
                            backgroundRepeat:'no-repeat',
    border: data?.status && `3px dashed ${borderColor}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              ...(selectedElement === data && {
                cursor: "move",
                borderRadius: "5px",
                border: "1px dashed red",
              }),
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "10px",
                fontWeight: 500,
              }}
            >
              {data?.Name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <img src={group} width={8} />

              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                {`${data?.MinSize}-${data?.MaxSize}`}
              </Typography>
            </Box>
          </Box>
        </IconButton>
      )}

      {data?.Type === "tableRec3" && (
        <IconButton>
          {" "}
          <Box
            sx={{
              background: `url('${svgRec}')`,
              width: "130px",
              height: "130px",
              backgroundSize: "130px",
              transform: "rotate(50deg)",
              backgroundRepeat:'no-repeat',
              transition: "0.2s all linear",
                  border: data?.status && `3px dashed ${borderColor}`,
              "&:hover": { cursor: "move", borderRadius: "5px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
              ...(selectedElement === data && {
                cursor: "move",
                borderRadius: "5px",
                border: "1px dashed red",
              }),
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "10px",
                fontWeight: 500,
              }}
            >
              {data?.Name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <img src={group} width={8} />

              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "8px",
                  fontWeight: 500,
                }}
              >
                {`${data?.MinSize}-${data?.MaxSize}`}
              </Typography>
            </Box>
          </Box>
        </IconButton>
      )}

      {data?.Type === "tableRec2" && (
        <IconButton>
          {" "}
          <Box
            sx={{
              background: `url('${svgRec}')`,
              width: "110px",
              height: "110px",
              backgroundSize: "110px",
              transition: "0.2s all linear",
                            backgroundRepeat:'no-repeat',
                  border: data?.status && `3px dashed ${borderColor}`,
              "&:hover": { cursor: "move", borderRadius: "5px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
              ...(selectedElement === data && {
                cursor: "move",
                borderRadius: "5px",
                border: "1px dashed red",
              }),
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "8px",
                fontWeight: 500,
              }}
            >
              {data?.Name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <img src={group} width={7} />

              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "8px",
                  fontWeight: 500,
                }}
              >
                {`${data?.MinSize}-${data?.MaxSize}`}
              </Typography>
            </Box>
          </Box>
        </IconButton>
      )}

      {data?.Type === "tableSquare2" && (
        <IconButton>
          <Box
            sx={{
              background: `url('${svgSquare}')`,
              width: "230px",
              height: "125px",
              transform: "rotate(90deg)",
                            backgroundRepeat:'no-repeat',
              backgroundSize: "230px",
              transition: "0.2s all linear",
                  border: data?.status && `3px dashed ${borderColor}`,
              "&:hover": { cursor: "move", borderRadius: "5px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
              ...(selectedElement === data && {
                cursor: "move",
                borderRadius: "5px",
                border: "1px dashed red",
              }),
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "10px",
                fontWeight: 500,
              }}
            >
              {data?.Name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <img src={group} width={8} />

              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                {`${data?.MinSize}-${data?.MaxSize}`}
              </Typography>
            </Box>
          </Box>
        </IconButton>
      )}
    </>
  );
};

export default CustomElement;
