import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  IconButton,
  Grid,
  InputLabel,
  LinearProgress,
  ListItemButton,
  Divider,
} from "@mui/material";
import CustomTextField from "../../CustomField/CustomTextField";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import Loader from "../../../Common/Loader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { useSnackbar } from "notistack";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CustomInput from "../../CustomField/CustomInput";
import { createWalkinNewGuest } from "../../../../api";
import { convertTime } from "../../../../helpers/Format";

const NewGuestWalkin = ({
  seatingArea,
  loadingSeatingArea,
  handleClose,
  action,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };
  const [guest, setGuest] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const [reservationDetails, setReservationDetails] = useState({
    partySize: 0,
    time: "",
    date: "",
  });

  const [floorplan, setFloorPlan] = useState(null);

  const { firstName, lastName, email, phoneNumber } = guest;
  const { time, partySize, date } = reservationDetails;

  const [isLoading, setIsLoading] = useState(false);

  const handleCreateWalkin = async () => {
    setIsLoading(true);
    const convertedTime = convertTime(time);
    await createWalkinNewGuest(
      floorplan,
      date,
      convertedTime,
      firstName,
      lastName,
      phoneNumber,
      email,
      partySize
    )
      .then((res) => {
        if (res?.data?.status) {
          handleAlert("success", `${res?.data?.success_message}`);
          handleClose();
          action();
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        handleAlert("error", `${err}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [checked, setIsChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      setGuest({
        firstName: "Nil",
        lastName: "Nil",
        email: "Not-Provided",
        phoneNumber: "Not-Provided",
      });
    } else {
      setGuest({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      });
    }
  }, [checked]);
  return (
    <>
      {isLoading && <Loader />}
      <Box
        className="hide_scrollbar"
        sx={{
          overflow: "scroll",
          bgcolor: "#1a1a1a",
          borderRadius: "0px 0px 10px 10px",
        }}
      >
        <Box align="right" sx={{ my: 2, pr: 3 }}>
          <Checkbox
            size="medium"
            checked={checked}
            sx={{ transform: "scale(1.5)" }}
            onChange={(e) => setIsChecked(e.target.checked)}
          />{" "}
          <span
            style={{
              color: "#fff",
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Diner info not provided
          </span>
        </Box>
        <Box sx={{ mt: 3, px: { lg: 3, md: 3, sm: 2, xs: 2 } }}>
          {!checked && (
            <>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    name="First Name"
                    value={firstName}
                    onChange={(e) =>
                      setGuest({ ...guest, firstName: e.target.value })
                    }
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    name="Last Name"
                    value={lastName}
                    onChange={(e) =>
                      setGuest({ ...guest, lastName: e.target.value })
                    }
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 2 }}>
                <CustomTextField
                  name="Email address"
                  value={email}
                  onChange={(e) =>
                    setGuest({ ...guest, email: e.target.value })
                  }
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <CustomTextField
                  name="Phone Number"
                  value={phoneNumber}
                  onChange={(e) =>
                    setGuest({ ...guest, phoneNumber: e.target.value })
                  }
                />
              </Box>
            </>
          )}

          <Divider sx={{ mt: 2 }} />
          <Box sx={{ mt: 2, mb: 1 }}>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 500,
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
              }}
            >
              Seating Area
            </Typography>
            {loadingSeatingArea && (
              <Box sx={{ mt: 1 }}>
                <LinearProgress />
                <Typography
                  sx={{
                    mt: 2,
                    textAlign: "center",
                    color: "#ccc",
                  }}
                >
                  Getting seating areas...{" "}
                </Typography>
              </Box>
            )}
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {!seatingArea || seatingArea?.length === 0 ? (
                <>
                  <Box>
                    <Typography
                      sx={{
                        py: 2,
                        textAlign: "center",
                        color: "#fff",
                        px: 2,
                      }}
                    >
                      No Seating Area Available
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  {seatingArea?.map((area, index) => (
                    <Grid item lg={4} md={4} sm={6} xs={6} key={index}>
                      <ListItemButton
                        selected={floorplan === area.Id}
                        onClick={() => setFloorPlan(area?.Id)}
                        sx={{
                          borderRadius: 2,
                          p: 2,
                          width: "100%",
                          bgcolor: "#333",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          "&.Mui-selected": {
                            bgcolor: "#BC172F",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#fff",
                            width: "60%",
                          }}
                        >
                          {area?.Name}
                        </Typography>
                        {floorplan === area.Id ? (
                          <AdjustOutlinedIcon sx={{ color: "#fff", ml: 2 }} />
                        ) : (
                          <CircleOutlinedIcon sx={{ color: "#fff", ml: 2 }} />
                        )}
                      </ListItemButton>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          </Box>

          <Divider />
          <Box sx={{ mt: 3 }}>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 500,
                fontSize: "14px",
                fontFamily: "outfit",
              }}
            >
              Party Size
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 1,
              }}
            >
              <IconButton
                sx={{ p: 2, bgcolor: "#333", borderRadius: 2 }}
                onClick={() => {
                  if (partySize > 1) {
                    setReservationDetails({
                      ...reservationDetails,
                      partySize: partySize - 1,
                    });
                  }
                }}
              >
                <RemoveIcon sx={{ color: "#fff" }} />
              </IconButton>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "#333",
                  borderRadius: 2,
                  width: { lg: "70%", md: "70%", sm: "50%", xs: "50%" },
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                {partySize}
              </Box>
              <IconButton
                sx={{ p: 2, bgcolor: "#333", borderRadius: 2 }}
                onClick={() =>
                  setReservationDetails({
                    ...reservationDetails,
                    partySize: partySize + 1,
                  })
                }
              >
                <AddIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item md={6} xs={12}>
                <InputLabel sx={{ color: "#fff", fontWeight: 500, mb: 0.5 }}>
                  Time
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    // disablePast

                    value={time}
                    onChange={(newValue) =>
                      setReservationDetails({
                        ...reservationDetails,
                        time: newValue,
                      })
                    }
                    sx={{
                      fontFamily: "outfit",
                      fontSize: "13px",
                      borderRadius: "10px",
                      offset: " 1px solid #ccc",
                      fontWeight: 500,
                      color: "#ccc",
                      border: "1px solid #ccc",
                      width: "100%",
                      // Replace with your desired font family
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <InputLabel sx={{ color: "#fff", fontWeight: 500, mb: 0.5 }}>
                  Date
                </InputLabel>
                <CustomInput
                  value={date}
                  size="large"
                  onChange={(e) =>
                    setReservationDetails({
                      ...reservationDetails,
                      date: e.target.value,
                    })
                  }
                  type="date"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        className="hide_scrollbar"
        sx={{
          height: "80px",
          bgcolor: "#333",
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          mt: 2,
          px: { lg: 3, md: 3, sm: 2, xs: 2 },
        }}
      >
        <Button
          onClick={handleCreateWalkin}
          variant="contained"
          disabled={
            !firstName || !lastName || !email || !time || !date || !floorplan
          }
          sx={{
            textTransform: "initial",
            fontSize: {
              lg: "14px",
              md: "14px",
              sm: "12px",
              xs: "12px",
            },
            width: "180px",
            fontWeight: 500,
            borderRadius: "8px",
            height: "48px",
          }}
        >
          Add Walk-In
        </Button>
      </Box>
    </>
  );
};

export default NewGuestWalkin;
