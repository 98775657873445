import React from 'react'
import { Modal , Box, Typography, Button, } from '@mui/material'
import { MdOutlineCancel } from 'react-icons/md';


const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md: 400, sm: 400, xs: 350 },
  maxHeight: "70vh",
  overflow: "scroll",
  bgcolor: "#1a1a1a",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  boxSizing: "border-box",
};


const Cancel = ({open, handleClose,  data,handleModifyReservation }) => {


  return (
<>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#fff",
              textAlign: "center",
              textDecoration: "line-through",
            }}
          >
            Cancel{" "}
            <b style={{ fontSize: "12px" }}>
              {" "}
              {data?.bookingUserDetails?.Name?.split(" ")[0] || "--"}
            </b>{" "}
            to reservation?
          </Typography>
          <Typography
            sx={{ color: "#ccc", fontSize: "12px", textAlign: "center" }}
          >
            This action cannot be reversed.
          </Typography>
          <Box sx={{ display: "grid", placeContent: "center", mt: 2 }}>
            <MdOutlineCancel style={{ fontSize: "40px" }} />
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: 2, mt: 3 }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              fullWidth
              sx={{ color: "#fff", borderColor: "#fff" }}
            >
              Not yet
            </Button>
            <Button
              onClick={() => handleModifyReservation("Cancelled")}
              variant="contained"
              fullWidth
              sx={{}}
            >
              Yes, Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
</>
  )
}

export default Cancel