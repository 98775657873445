import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Popover,
  Button,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Modal,
} from "@mui/material";

import { useSnackbar } from "notistack";
import searchIcon from "../../../../assets/Icons/search.svg";
import { MdInfoOutline } from "react-icons/md";
import { BsBookmarkCheckFill } from "react-icons/bs";
import Pagination from "../../../Common/Pagination";
import { formatDate2, formatTime } from "../../../../helpers/Format";
import { confirmReservation } from "../../../../api";
import emptyImg from '../../../../assets/img/emptyRes.svg'


const style = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  boxSizing: "border-box",
  borderRadius: "8px",
};
const ReservationTable = ({ tableData, action, handleDetailsScreen }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rows) => {
    setRowsPerPage(rows);
    setPage(0);
  };

  const [open3, setOpen3] = useState(false);
  const restaurantName = localStorage.getItem("restaurantName");

  const [singleReservation, setSingleReservation] = useState(null);

  const isNostalgia = restaurantName === "NOSTALGIA LAGOS";

  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const handleApproveReservation = async () => {
    setIsLoading(true);
    await confirmReservation(singleReservation?.ReservationId)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          handleAlert(
            "success",
            `${
              res?.data?.success_message || "Reservation successfully approved"
            }`
          );
          action();
          setOpen3(false);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        handleAlert("error", `${err.message}`);
      });
  };

  const today = new Date()?.toISOString().split("T")[0];


  const [sortedReservations, setSortedReservation] = useState([])

  useEffect(()=>{
    if(tableData){
  const sortedReservations = [...tableData]?.sort((a, b) => {
    const dateTimeA = new Date(`${a.Date}T${a.Time}`);
    const dateTimeB = new Date(`${b.Date}T${b.Time}`);
    const isTodayA = a.Date === today;
    const isTodayB = b.Date === today;

    // Group by today, past, or future
    if (isTodayA && !isTodayB) return -1; // A is today
    if (!isTodayA && isTodayB) return 1; // B is today
    if (a.Date < today && b.Date >= today) return 1; // A is past, B is today or future
    if (a.Date >= today && b.Date < today) return -1; // A is today or future, B is past

    // Sort within groups: descending for past, ascending for future
    return a.Date < today ? dateTimeB - dateTimeA : dateTimeA - dateTimeB;
  });
  setSortedReservation(sortedReservations)
    }
  }, [tableData])


  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: "10px",
          p: { lg: 3, md: 3, sm: 2, xs: 1 },
          boxSizing: "border-box",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "column",
              xs: "column",
            },
            rowGap: 2,
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              Upcoming Reservation
            </Typography>
            {/* <Typography
              sx={{
                fontWeight: 300,
                fontSize: "10px",
                color: "#ccc",
                display: "flex",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <MdInfoOutline />
              Double click to view more details
            </Typography> */}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
            <TextField
              size="small"
              sx={{ width: { md: "300px", sm: "200px", xs: "100%" } }}
              placeholder="Search experiences"
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <img src={searchIcon} style={{ marginRight: "8px" }} />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "8px",

                  fontSize: "12px",
                },
              }}
            />
          </Box>
        </Box>
      {
        (!sortedReservations || sortedReservations?.length === 0 ) ? (
          <>
              <Box
            sx={{
              height: "300px",
              bgcolor: "#1a1a1a",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={emptyImg} alt="icon" />
            <Typography
              sx={{ color: "#fff", fontSize: "16px", fontWeight: 400, mt: 3 }}
            >
              You have no upcoming guest{" "}
            </Typography>
           
          
          </Box>
          </>
        ):(
          <>
            <TableContainer>
          <Table sx={{ mt: 2 }}>
            <TableHead
              sx={{ bgcolor: "background.default", borderRadius: "5px" }}
            >
              <TableRow>
                <TableCell sx={{ whiteSpace: "nowrap" }}>Date & Time</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  Name of diner
                </TableCell>
                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                  Party Size
                </TableCell>
                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                  Seating Area
                </TableCell>
                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedReservations &&
                sortedReservations
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ bgcolor: "#333" }}
                      hover
                      onDoubleClick={() => handleDetailsScreen(item)}
                      onClick={() => !isNostalgia && handleDetailsScreen(item)}
                    >
                      <TableCell sx={{ fontSize: "12px" }}>
                        {formatDate2(item?.Date)} - {formatTime(item?.Time)}
                      </TableCell>
                      <TableCell sx={{ fontSize: "12px" }}>
                        {item?.DinnerName}
                      </TableCell>
                      <TableCell align="center" sx={{ fontSize: "12px" }}>
                        {item?.PartySize}
                      </TableCell>
                      <TableCell align="center" sx={{ fontSize: "12px" }}>
                        {item?.SeatingArea}
                      </TableCell>
                      {isNostalgia && !item?.IsConfirmed ? (
                        <TableCell
                          onClick={() => {
                            setSingleReservation(item);
                            setOpen3(true);
                          }}
                          align="center"
                          sx={{ fontSize: "12px" }}
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              color: "#fff",
                              fontSize: "12px",
                              borderColor: "#fff",
                            }}
                            endIcon={<BsBookmarkCheckFill />}
                          >
                            Confirm Reservation
                          </Button>
                        </TableCell>
                      ) : (
                        <TableCell align="center" sx={{ fontSize: "12px" }}>
                          {item?.Status === "Expected"
                            ? "Reservation Confirmed"
                            : item?.Status}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
          </>
        )
      }
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          totalRows={tableData?.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Modal
        open={open3}
        onClose={() => setOpen3(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ fontSize: "14px", color: "#fff", textAlign: "center" }}
          >
            Are you sure you want to approve{" "}
            <b style={{ color: "#19A662" }}>
              {" "}
              {singleReservation?.DinnerName || "--"}
            </b>{" "}
            Reservation
          </Typography>
          <Box sx={{ display: "grid", placeContent: "center", mt: 2 }}>
            <BsBookmarkCheckFill style={{ fontSize: "40px" }} />
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: 2, mt: 3 }}
          >
            <Button
              variant="outlined"
              onClick={() => setOpen3(false)}
              fullWidth
              sx={{ color: "#fff", borderColor: "#fff" }}
            >
              No, Close
            </Button>
            <Button
              onClick={() => handleApproveReservation()}
              variant="contained"
              fullWidth
              sx={{ bgcolor: "#19A662", "&:hover": { bgcolor: "#19A662b7" } }}
            >
              Yes, Approve
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ReservationTable;
