import React, { useState, useEffect, useRef } from "react";
import TopNav from "../../Layout/TopNav";
import {
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  Paper,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Guest from "../../Components/Reservations/Guest";
import Waitlist from "../../Components/Reservations/Waitlist";
import { Outlet } from "react-router-dom";
import WalkinHeader from "../../Common/WalkinHeader";
import { Helmet } from "react-helmet-async";
import SEO from "../../../Utility/SEO";
import BluePrint from "../../Components/Reservations/BluePrint";
import {
  getFloorPlan,
  getReservations,
  getSingleFloorPlan,
} from "../../../api";
import { MdOutlineChevronRight } from "react-icons/md";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader";
import { ThemeContextProvider } from "../../../STYLES/theme/ThemeContextProvider";

const Reservations = () => {
  const NAV = {
    WIDTH: 70,
  };

  const [bluePrintElement, setBluePrintElement] = useState([]);
  const [planData, setPlanData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [data, setData] = useState(null);
  const [name, setName] = useState("");

  const updateSelectedFloor = (id) => {
    setSelectedFloor(id);
  };

  const handleGetFloorPlan = async () => {
    setLoading(true);
    await getFloorPlan()
      .then((res) => {
        setLoading(false);
        const { data } = res;
        if (data.status) {
          setSelectedFloor(data?.result[0]?.Id);
          setPlanData(data?.result);
        } else {
          setPlanData(null);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    handleGetFloorPlan();
  }, []);

  const handleGetSingleFloor = async (id) => {
    setIsLoading(true);
    await getSingleFloorPlan(id)
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        if (data.status) {
          setData(data?.result[0].Tables);
          setName(data?.result[0]?.Name);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    handleGetSingleFloor(selectedFloor);
  }, [selectedFloor]);

  const inputDate = new Date();

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(inputDate.getDate()).padStart(2, "0");

  const formattedDateString = `${year}-${month}-${day}`;

  const [formattedDate, setFormattedDate] = useState(formattedDateString);

  const filterDate = (value) => {
    setFormattedDate(value);
  };

  const [selectedReservation, setSelectedReservation] = useState(null);

  const handleSelectReservation = (newData) => {
    setSelectedReservation(newData);
  };

  useEffect(() => {
    if (selectedReservation) {
      const updatedTables = data?.map((table) => {
        if (table.Name === selectedReservation?.TableName) {
          return {
            ...table,
            BookingStatus: selectedReservation?.BookingStatus, // Add BookingStatus to the matched table object
          };
        }
        return table; // Return the table object as is if no match
      });
      setBluePrintElement(updatedTables);
    } else {
      setBluePrintElement(data);
    }
  }, [data, selectedReservation]);

  const [reservation, setReservation] = useState(null);

  const handleGetReservations = async () => {
    setIsLoading(true);
    await getReservations(formattedDate)
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        setReservation(data?.result);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <SEO
        title="Reisty | Restaurant Reservation Management"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
        canonicalUrl="https://restaurant.reisty.com/dashboard"
      />
      <ThemeContextProvider>
        <TopNav />
        <Box
          className="hide_scrollbar"
          sx={{
            height: "100vh",
            overflow: "scroll",
            background: "#000",
            display: "flex",
            minHeight: 1,
            pt: { xl: 8, lg: 8, md: 8 },
          }}
        >
          <Guest
            floorPlan={planData}
            updateSelectedFloor={updateSelectedFloor}
            formattedDate={formattedDate}
            handleSelectReservation={handleSelectReservation}
            selectedReservation={selectedReservation}
            reservations={reservation}
            tables={bluePrintElement}
          />
          <Box
            className="hide_scrollbar"
            sx={{
              boxSizing: "border-box",
              height: "100vh",

              width: {
                lg: `calc(100% - ${NAV.WIDTH}px)`,
                md: `calc(100% - ${NAV.WIDTH}px)`,
                sm: "100%",
                xs: "100%",
              },
              overflow: "scroll",
              pb: 4,

              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Box
              sx={{
                bgcolor: "#000",
                boxSizing: "border-box",
                height: "60px",
                borderBottom: "1px solid #fff",
              }}
            >
              <WalkinHeader
                updateDate={filterDate}
                action={handleGetReservations}
              />
            </Box>
            <BluePrint
              bluePrintElement={bluePrintElement}
              floorplan={planData}
              updateSelectedFloor={updateSelectedFloor}
              selectedFloor={selectedFloor}
              selectedReservation={selectedReservation}
            />
          </Box>
        </Box>
      </ThemeContextProvider>
    </>
  );
};

export default Reservations;
