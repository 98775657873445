import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  Button,
  ThemeProvider,
  createTheme,
  MenuItem,
  InputLabel,
  CircularProgress,
  Autocomplete,
  Chip,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import avat from "../../../../assets/Icons/avat.svg";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import img1 from "../../../../assets/Icons/guest/1.svg";
import img2 from "../../../../assets/Icons/guest/2.svg";
import img3 from "../../../../assets/Icons/guest/3.svg";
import img4 from "../../../../assets/Icons/guest/4.svg";
import img5 from "../../../../assets/Icons/guest/5.svg";
import img6 from "../../../../assets/Icons/guest/6.svg";
import {
  createGuestBook,
  createGuestTags,
  getGuestTags,
} from "../../../../api";
import Loader from "../../../Common/Loader";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CiImport } from "react-icons/ci";
import { RiAddLine } from "react-icons/ri";
import CustomModal from "../../../Common/CustomModal";

const CssTextField = styled(TextField)({
  height: "30px",

  fontWeight: 500,
  "& label.Mui-focused": {},
  "& .MuiInput-underline:after": {},
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",

      fontWeight: 500,
    },
    "&:hover fieldset": {
      border: "none",

      fontWeight: 500,
    },
    "&.Mui-focused fieldset": {
      border: "none",

      fontWeight: 500,
    },
  },
});

const NewGuest = ({ handleCloses, callBack }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const updateCloses = () => {
    handleCloses(false);
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [country, setCountry] = useState("Nigeria");
  const [dob, setDob] = useState(null);
  const [weddingAnniversary, setWeddingAnniversary] = useState("");
  const [graduationAnniversary, setgraduationAnniversary] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const formatDate = (dateStr) => {
    // Get the current year
    const currentYear = new Date().getFullYear();

    // Parse the month and day from the date string
    const [monthStr, dayStr] = dateStr.split(" ");

    // Create a Date object with the current year and parsed month and day
    const dateObj = new Date(
      currentYear,
      monthToIndex(monthStr),
      parseInt(dayStr)
    );

    // Format the date as YYYY-MM-DD
    const formattedDate = dateObj.toISOString().slice(0, 10);

    return formattedDate;
  };

  // Helper function to convert month string to index
  const monthToIndex = (monthStr) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months.indexOf(monthStr);
  };

  const handleCreateGuest = async () => {
    setIsLoading(true);

    await createGuestBook(
      firstName,
      lastName || null,
      phone || null,
      email || null,
      country,
      dob ? formatDate(dob) : null,
      graduationAnniversary,
      weddingAnniversary
    )
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        if (data?.status) {
          handleAlert("success", `${data?.success_message}`);
          // navigate("/dashboard/guest-book");
          callBack();
          handleCloses();
        } else {
          handleAlert("error", `${data?.result[0]}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err}`);
      });
  };

  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    if (!validateEmail(emailValue)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    if (!email) {
      setEmailError("");
    }
  }, [email]);
  useEffect(() => {
    if (!phone) {
      setMobileError("");
    }
  }, [phone]);

  const validateMobileNumber = (number) => {
    const mobilePattern = /^[0-9]{8,15}$/; // Minimum 8 digits, maximum 15 digits
    return mobilePattern.test(number);
  };

  const handleMobileChange = (e) => {
    const mobileValue = e.target.value;
    setPhone(mobileValue);

    if (!validateMobileNumber(mobileValue)) {
      setMobileError("Please enter a valid mobile number.");
    } else {
      setMobileError("");
    }
  };

  // const [selectedGuest ]

  return (
    <>
      <>
        {isLoading && <Loader />}
        <Box
          sx={{
            height: "60px",
            bgcolor: "background.card",
            borderBottom: "1px solid #BC172F",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              px: 2,
              py: 2,
            }}
          >
            <Grid item lg={4}>
              {step > 0 && (
                <>
                  <IconButton onClick={() => setStep((prev) => prev - 1)}>
                    <ArrowBackIcon sx={{}} />
                  </IconButton>
                </>
              )}
            </Grid>
            <Grid item lg={4}>
              <Typography
                sx={{
                  fontFamily: "optima",

                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                New Guest
              </Typography>
            </Grid>
            <Grid item align="right" lg={4}>
              <IconButton onClick={updateCloses}>
                <CloseIcon sx={{}} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        {step === 0 && (
          <Box
            className="hide_scrollbar"
            sx={{
              bgcolor: "background.paper",
              px: 5,
              // height: "600px",
              overflow: "scroll",
              py: 2,
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            <Box
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img src={avat} alt="user_avatar" width={50} />
              {/* <Button startIcon={<CiImport />} onClick={handleOpen}>
                Import existing guestbook
              </Button> */}
            </Box>
            <Box
              sx={{ mt: 3, bgcolor: "background.card", borderRadius: "8px" }}
            >
              <Box sx={{ pt: 4, px: 3 }}>
                <Grid container>
                  <Grid item lg={4} md={4}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                      First Name *
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8}>
                    <Box sx={{}}>
                      <CssTextField
                        fullWidth
                        variant="standard"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        InputProps={{
                          style: {
                            fontFamily: "outfit",
                            fontSize: "13px",
                            borderRadius: "10px",

                            fontWeight: 500,
                          },
                        }}
                        placeholder="Enter first name"
                        id="custom-css-outlined-input"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ pt: 4, px: 3 }}>
                <Grid container>
                  <Grid item lg={4} md={4}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                      Last Name *
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8}>
                    <Box sx={{}}>
                      <CssTextField
                        variant="standard"
                        fullWidth
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        InputProps={{
                          style: {
                            fontFamily: "outfit",
                            fontSize: "13px",
                            borderRadius: "10px",

                            fontWeight: 500,
                          },
                        }}
                        placeholder="Last Name"
                        id="custom-css-outlined-input"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ pt: 4, px: 3 }}>
                <Grid container>
                  <Grid item lg={4} md={4}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                      Phone Number
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8}>
                    <Box sx={{}}>
                      <CssTextField
                        type="number"
                        variant="standard"
                        fullWidth
                        value={phone}
                        onChange={handleMobileChange}
                        error={mobileError}
                        helperText={mobileError}
                        InputProps={{
                          style: {
                            fontFamily: "outfit",
                            fontSize: "13px",
                            borderRadius: "10px",

                            fontWeight: 500,
                          },
                        }}
                        placeholder="Phone Number"
                        id="custom-css-outlined-input"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ pt: 4, px: 3 }}>
                <Grid container>
                  <Grid item lg={4} md={4}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                      Email Address
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8}>
                    <Box sx={{}}>
                      <TextField
                        fullWidth
                        variant="standard"
                        value={email}
                        error={emailError}
                        helperText={emailError}
                        onChange={handleEmailChange}
                        InputProps={{
                          style: {
                            fontFamily: "outfit",
                            fontSize: "13px",
                            borderRadius: "10px",

                            fontWeight: 500,
                          },
                        }}
                        size="small"
                        placeholder="Email address"
                        id="custom-css-outlined-input"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ py: 2, px: 3, mt: 1 }}>
                <Grid container>
                  <Grid item lg={4} md={4}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                      Birthday
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8}>
                    <Box sx={{}}>
                      <TextField
                        fullWidth
                        variant="standard"
                        name="date"
                        value={dob}
                        required
                        onChange={(e) => setDob(e.target.value)}
                        InputProps={{
                          style: {
                            fontFamily: "outfit",
                            fontSize: "13px",
                            borderRadius: "10px",

                            fontWeight: 500,
                          },
                        }}
                        size="small"
                        placeholder="e.g Jan 1"
                        id="custom-css-outlined-input"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ mt: 2, mb: 3 }} align="right">
              <Button
                disabled={
                  !firstName || (!email && !phone) || emailError || mobileError
                }
                variant="contained"
                onClick={handleCreateGuest}
                sx={{
                  "&:disabled": {
                    bgcolor: "#d4d4d4",
                    color: "#333",
                    opacity: 0.8,
                  },
                  py: 2,
                  borderRadius: "12px",
                  px: 6,
                }}
              >
                Proceed
              </Button>
            </Box>
          </Box>
        )}
        {step === 4 && (
          <Box
            sx={{
              bgcolor: "#1A1A1A",
              px: 5,
              // height: "600px",
              overflow: "scroll",
              py: 2,
            }}
          >
            <Box sx={{ mt: 3, bgcolor: "#333" }}>
              <Box sx={{ borderBottom: "0.5px solid #fff", py: 2, px: 3 }}>
                <Grid container>
                  <Grid item lg={6}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                      Home Address
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Box sx={{}}>
                      <CssTextField
                        InputProps={{
                          style: {
                            fontFamily: "outfit",
                            fontSize: "13px",
                            borderRadius: "10px",

                            fontWeight: 500,
                          },
                        }}
                        size="small"
                        placeholder="Home Address"
                        id="custom-css-outlined-input"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ borderBottom: "0.5px solid #fff", py: 2, px: 3 }}>
                <Grid container>
                  <Grid item lg={6}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                      office Address
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Box sx={{}}>
                      <CssTextField
                        InputProps={{
                          style: {
                            fontFamily: "outfit",
                            fontSize: "13px",
                            borderRadius: "10px",

                            fontWeight: 500,
                          },
                        }}
                        size="small"
                        placeholder="Office Address"
                        id="custom-css-outlined-input"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ mt: 2, mb: 3 }} align="right">
              <Button
                variant="contained"
                sx={{ color: "#333" }}
                onClick={() => setStep((prev) => prev + 2)}
              >
                Skip
              </Button>
              <Button
                variant="contained"
                sx={{ ml: 3 }}
                onClick={() => setStep((prev) => prev + 1)}
              >
                Proceed
              </Button>
            </Box>
          </Box>
        )}
        {step === 1 && (
          <Box
            sx={{
              bgcolor: "#1A1A1A",
              px: 5,
              // height: "600px",
              overflow: "scroll",
              py: 2,
            }}
          >
            <Box sx={{ mt: 3, bgcolor: "#333" }}>
              <Box sx={{ borderBottom: "0.5px solid #fff", py: 2, px: 3 }}>
                <Grid container></Grid>
              </Box>
            </Box>
            <Box sx={{ mt: 2, mb: 3 }} align="right">
              <Button
                variant="contained"
                sx={{ ml: 3 }}
                disabled={isLoading || !dob}
                // onClick={() => setStep((prev) => prev + 1)}
                onClick={handleCreateGuest}
              >
                Proceed
              </Button>
            </Box>
          </Box>
        )}
        {step === 2 && (
          <Box
            sx={{
              bgcolor: "#1A1A1A",
              px: 5,
              // height: "600px",
              overflow: "scroll",
              py: 2,
            }}
          >
            <Box sx={{ mt: 5 }}>
              <Grid container spacing={2}>
                <Grid item lg={6}>
                  <Box
                    sx={{
                      border: "1px dashed #fff",
                      p: 1.5,
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img1} alt="note_icon" />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        Visit Notes
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        cursor: "pointer",
                        color: "#fff",
                        fontWeight: 400,
                        fontSize: "12px",
                        textDecoration: "underline",
                      }}
                    >
                      Add
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Box
                    sx={{
                      border: "1px dashed #fff",
                      p: 1.5,
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img4} alt="icon" />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        special Relationship
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        cursor: "pointer",
                        color: "#fff",
                        fontWeight: 400,
                        fontSize: "12px",
                        textDecoration: "underline",
                      }}
                    >
                      Add
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Box
                    sx={{
                      border: "1px dashed #fff",
                      p: 1.5,
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img2} alt="guest_icon" />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        Guest diary restrictions
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        cursor: "pointer",
                        color: "#fff",
                        fontWeight: 400,
                        fontSize: "12px",
                        textDecoration: "underline",
                      }}
                    >
                      Add
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Box
                    sx={{
                      border: "1px dashed #fff",
                      p: 1.5,
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img5} alt="sit_icon" />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        Siting preference
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        cursor: "pointer",
                        color: "#fff",
                        fontWeight: 400,
                        fontSize: "12px",
                        textDecoration: "underline",
                      }}
                    >
                      Add
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Box
                    sx={{
                      border: "1px dashed #fff",
                      p: 1.5,
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img3} alt="food_icon" />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        Food & drink preference
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        cursor: "pointer",
                        color: "#fff",
                        fontWeight: 400,
                        fontSize: "12px",
                        textDecoration: "underline",
                      }}
                    >
                      Add
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Box
                    sx={{
                      border: "1px dashed #fff",
                      p: 1.5,
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <img src={img6} alt="alert_icon" />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        Alert the chef
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        cursor: "pointer",
                        color: "#fff",
                        fontWeight: 400,
                        fontSize: "12px",
                        textDecoration: "underline",
                      }}
                    >
                      Add
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 2, mb: 3 }} align="right">
              <Button
                variant="contained"
                sx={{ ml: 3 }}
                onClick={() => setStep((prev) => prev + 1)}
              >
                Done
              </Button>
            </Box>
          </Box>
        )}
      </>



      {/* <ImportGuest open={open} onClose={handleClose}  action={callBack} closeCreateModal = {handleCloses}/> */}
    </>
  );
};

export default NewGuest;
