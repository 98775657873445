import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dataSet from "./AppWidget/Chart/data";
import ReGraph from "./AppWidget/Chart/ReGraph";
import searchIcon from "../../../assets/Icons/search.svg";
import { GoDotFill } from "react-icons/go";

import UpgradeOverlay from "../../Common/UpgradeOverlay";

const SalesSummary = ({ values, chartData, handleUpdateDayInterval }) => {
  // const [data2, setData2] = useState(dataSet.Today);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [dayInterval, setDayInterval] = useState(7);
  const [dropdownLabel, setDropdownLabel] = useState("Last_7_days");

  // const handleClickMenu = (value, label) => {
  //   handleClose();
  //   setDayInterval(value);
  //   setDropdownLabel(label);
  // };
  useEffect(() => {
    handleUpdateDayInterval(dayInterval);
  }, [dayInterval]);

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
                p: 3,
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                  Total Reservation Deposit
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "optima",
                    fontSize: "16px",
                    fontWeight: 400,
                    mt: 1,
                  }}
                >
                  NGN {values?.TotalReservationDeposit?.toLocaleString()}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
                p: 3,
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Total Reservations
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "optima",
                    fontSize: "16px",
                    fontWeight: 400,
                    mt: 1,
                  }}
                >
                  {values?.TotalReservation}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
                p: 3,
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Total Party size
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "optima",
                    fontSize: "16px",
                    fontWeight: 400,
                    mt: 1,
                  }}
                >
                  {values?.TotalPartySize}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* ANALYSIS CHARTS */}

          <Grid item md={12}>
            <UpgradeOverlay>
              <Box sx={{ p: 3, bgcolor: "#1a1a1a", borderRadius: "10px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ fontSize: "13px", color: "#fff" }}>
                    Reservations & Walk-ins Overview
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                      }}
                    >
                      <GoDotFill style={{ color: "#BC172F" }} />
                      <Typography sx={{ fontSize: "12px", color: "#fff" }}>
                        Walk-ins
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                      }}
                    >
                      <GoDotFill style={{ color: "#47E673" }} />
                      <Typography sx={{ fontSize: "12px", color: "#fff" }}>
                        Reservations
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <ReGraph data={chartData} />
                </Box>
              </Box>
            </UpgradeOverlay>
          </Grid>

          {/* EXPERIECE TABLE */}

          {/* <Grid item md={12} xs={12}>
            <Box
              sx={{
                bgcolor: "background.paper",
                borderRadius: "10px",
                p: 3,
                boxSizing: "border-box",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
                  Experiences Overview
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                >
                  <TextField
                    size="small"
                    sx={{ width: { md: "300px", sm: "200px", xs: "150px" } }}
                    placeholder="Search experiences"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <img
                            src={searchIcon}
                            style={{ marginRight: "8px" }}
                          />
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "8px",

                        fontSize: "12px",
                      },
                    }}
                  />
                   <TextField select placeholder="Samm">
                    <MenuItem>Sammuek</MenuItem>
                    <MenuItem>Sammuek</MenuItem>
                    <MenuItem>Sammuek</MenuItem>
                    <MenuItem>Sammuek</MenuItem>
                  </TextField>
                </Box>
              </Box>
               <Table sx={{ mt: 2 }}>
                <TableHead sx={{ bgcolor: "#262626", borderRadius: "5px" }}>
                  <TableRow>
                    <TableCell sx={{ color: "#ccc" }}>Date</TableCell>
                    <TableCell>Name of experience</TableCell>
                    <TableCell>No of Tickets sold</TableCell>
                    <TableCell>Amount Made</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ bgcolor: "#333" }}>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      4/12/2023
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      Bistro at the Theatre
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      200
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      ₦1,154,400
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ bgcolor: "#333" }}>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      4/12/2023
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      Bistro at the Theatre
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      200
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      ₦1,154,400
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ bgcolor: "#333" }}>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      4/12/2023
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      Bistro at the Theatre
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      200
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      ₦1,154,400
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ bgcolor: "#333" }}>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      4/12/2023
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      Bistro at the Theatre
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      200
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      ₦1,154,400
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table> 
            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export default SalesSummary;
