import React, { useState } from "react";

import { TbFilterSearch } from "react-icons/tb";
import { RiSearch2Line } from "react-icons/ri";
import { GoDotFill } from "react-icons/go";
import { MdOutlineLayersClear } from "react-icons/md";
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  List,
  Checkbox,
  ListItemButton,
  Grid,
  InputLabel,
  IconButton,
  Button,
} from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import indicator from "../../../../assets/Icons/indicator.svg";
import { filterCrmGuests } from "../../../../api";
import Loader from "../../../Common/Loader";

const FilterBox = ({ data, updateFilteredData }) => {
  const [tags, setTags] = useState({
    toggle: true,
    value: [],
  });
  const [status, setStatus] = useState({
    toggle: true,
    value: [],
  });

  const handleCheckboxChange = (tag) => {
    setTags((prevTags) => {
      const isAlreadyChecked = prevTags.value.includes(tag);

      return {
        ...prevTags,
        value: isAlreadyChecked
          ? prevTags.value.filter((item) => item !== tag) // Remove if checked
          : [...prevTags.value, tag], // Add if unchecked
      };
    });
  };

  const handleCheckboxStatus = (status) => {
    setStatus((prevStatus) => {
      const isAlreadyChecked = prevStatus.value.includes(status);

      return {
        ...prevStatus,
        value: isAlreadyChecked
          ? prevStatus.value.filter((item) => item !== status) // Remove if checked
          : [...prevStatus.value, status], // Add if unchecked
      };
    });
  };
  const today = new Date().toISOString().split("T")[0];
  const [reservationDates, setReservationDates] = useState({
    start: null,
    end: today,
  });

  const handleClearFilter = () => {
    setReservationDates({
      start: null,
      end: null,
    });

    setTags({
      toggle: true,
      value: [],
    });
    setStatus({
      toggle: true,
      value: [],
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleCrmFilter = async () => {
    setIsLoading(true);
    await filterCrmGuests(reservationDates, tags.value, status.value)
      .then((res) => {
        // console.log(res);
        updateFilteredData(res?.data?.result);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // console.log(reservationDates)
  return (
    <>
      {isLoading && <Loader />}
      <Box
        className="hide_scrollbar"
        sx={{ width: "30vw", height: "100vh", overflowY: "scroll" }}
      >
        <Box
          sx={{ width: "100%", background: "#1a1a1a", height: "100vh", p: 3 }}
        >
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
            <TbFilterSearch style={{ color: "#fff", fontSize: "20px" }} />
            <Typography
              sx={{ color: "#fff", fontWeight: 600, fontSize: "16px" }}
            >
              Filter
            </Typography>
          </Box>
          {/* <Box sx={{ mt: 4 }}>
            <TextField
              value={searchParams}
              onChange={handleSearch}
              fullWidth
              placeholder="Search contact, email or phone no"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiSearch2Line style={{ color: "333" }} />
                  </InputAdornment>
                ),
                style: {
                  background: "#fff",
                  fontSize: "12px",
                  color: "#151515",
                  borderRadius: "8px",
                },
              }}
            />
          </Box> */}

          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 4,
              }}
            >
              <Typography sx={{ color: "#B3B3B3" }}>
                SORT BY RESERVATION DATES
              </Typography>
              <IconButton onClick={() => handleClearFilter()}>
                <MdOutlineLayersClear style={{ fontSize: "25px" }} />
              </IconButton>
            </Box>

            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                <Box>
                  <InputLabel
                    sx={{ color: "#b3b3b3", fontWeight: 400, fontSize: "12px" }}
                  >
                    FROM
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={reservationDates.start}
                    onChange={(e) =>
                      setReservationDates({
                        ...reservationDates,
                        start: e.target.value,
                      })
                    }
                    margin="dense"
                    type="date"
                    InputProps={{
                      style: {
                        fontSize: "12px",
                        borderRadius: "8px",
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6}>
                <Box>
                  <InputLabel
                    sx={{ color: "#b3b3b3", fontWeight: 400, fontSize: "12px" }}
                  >
                    TO
                  </InputLabel>
                  <TextField
                    fullWidth
                    margin="dense"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    // inputProps={{ min: today }}
                    value={reservationDates.end}
                    onChange={(e) =>
                      setReservationDates({
                        ...reservationDates,
                        end: e.target.value,
                      })
                    }
                    InputProps={{
                      style: {
                        fontSize: "12px",
                        borderRadius: "8px",
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: 4 }}>
            <Typography sx={{ color: "#b3b3b3" }}>SORT BY</Typography>
            <List sx={{ mt: 2 }}>
              <ListItemButton
                onClick={() => setTags({ ...tags, toggle: !tags.toggle })}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 1,
                }}
              >
                <Typography sx={{ color: "#fff" }}>
                  GUEST TAG{" "}
                  {tags.value.length > 0 && <img src={indicator} width={15} />}
                </Typography>
                <IconButton>
                  <IoIosArrowDown />
                </IconButton>
              </ListItemButton>
              <Box
                sx={{
                  p: 2,
                  pt: 0,
                  display: tags.toggle ? "block" : "none",
                  transition: "0.2s all linear",
                  overflow: "hidden",
                  boxSizing: "border-box",
                }}
              >
                {GuestTag.map((tag, index) => (
                  <Box
                    key={index}
                    sx={{ display: "flex", columnGap: 2, alignItems: "center" }}
                  >
                    <Checkbox
                      size="medium"
                      checked={tags.value.includes(tag)}
                      onChange={() => handleCheckboxChange(tag)}
                    />
                    <Typography sx={{ textTransform: "uppercase" }}>
                      {tag}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <ListItemButton
                onClick={() => setStatus({ ...status, toggle: !status.toggle })}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 1,
                }}
              >
                <Typography sx={{ color: "#fff" }}>
                  RESERVATION STATUS{" "}
                  {status.value.length > 0 && (
                    <img src={indicator} width={15} />
                  )}
                </Typography>
                <IconButton>
                  <IoIosArrowDown />
                </IconButton>
              </ListItemButton>
              <Box
                sx={{
                  p: 2,
                  pt: 0,
                  display: status.toggle ? "block" : "none",
                  transition: "0.2s all linear",
                  overflow: "hidden",
                  boxSizing: "border-box",
                }}
              >
                {Status.map((sta, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      columnGap: 2,
                      alignItems: "center",
                      mb: 0.4,
                    }}
                  >
                    <Checkbox
                      size="medium"
                      checked={status.value.includes(sta.code)}
                      onChange={() => handleCheckboxStatus(sta.code)}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          height: "10px",
                          width: "10px",
                          bgcolor: sta.color,
                          borderRadius: "3px",
                        }}
                      />
                      <Typography sx={{ textTransform: "uppercase" }}>
                        {sta.value}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </List>

            <Box sx={{ mt: 4, px: 7 }}>
              <Button
                variant="contained"
                onClick={handleCrmFilter}
                fullWidth
                sx={{ borderRadius: "10px", height: "50px" }}
              >
                Apply Filter
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FilterBox;

const GuestTag = ["Birthday", "Anniversary", "Graduation", "Business"];
const Status = [
  { code: "Seated", value: "ongoing reservations", color: "#4787E6" },
  { code: "Finished", value: "FINISHED RESERVATIONS", color: "#47E673" },
  { code: "Cancelled", value: "CANCELLED RESERVATIONS", color: "#E6475E" },
  { code: "Expected", value: "UPCOMING RESERVATIONS", color: "#DF8E14" },
  { code: "NoShow", value: "NO SHOWS", color: "#CCCCCC" },
];
