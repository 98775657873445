import {
  Box,
  Modal,
  Typography,
  Button,
  Grid,
  IconButton,
  InputLabel,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CustomTextField from "../../CustomField/CustomTextField";
import CustomRadio from "../../CustomField/CustomRadio";
import { editCancellationPolicy, editOfferings } from "../../../../api";
import Loader from "../../../Common/Loader";
import { useSnackbar } from "notistack";
import CustomCheckbox from "../../CustomField/CustomCheckbox";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: "5px",
  boxShadow: 24,
  bgcolor: "background.paper",
  overflowY: "scroll",
maxHeight:'75vh'
,  pb: 3,
};

const RestaurantExperienceEdit = ({ data, action }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const [cancellationPolicy, setCancellationPolicy] = useState("");
  const [depositPerPerson, setDepositPerPerson] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (description === "<p><br></p>") {
      setDescription(null);
    }
  }, [description]);

  useEffect(() => {
    setCancellationPolicy(data?.Experience?.CancellationPolicy);
    setDepositPerPerson(data?.Experience?.DepositPerPerson);
    setDescription(data?.Experience?.HouseRules);
  }, [data]);

  const handleEdit = async () => {
    setIsLoading(true);
    await editCancellationPolicy(
      data?.Experience ? data?.Experience?.RestaurantExperienceId : null,
      cancellationPolicy,
      depositPerPerson,
      description,
      data?.Experience ? true : false
    )
      .then((res) => {
        setIsLoading(false);
        setOpen(false);
        action();
        if (res?.data?.status) {
          handleAlert("success", `${res?.data?.success_message}`);
        } else {
          handleAlert("error", `${res?.data?.result.map((item) => item)}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err.message}}`);
      });
  };

  const handleChange = (value) => {
    setDescription(value);
  };
  return (
    <>
      <Button variant="outlined" sx={{ width: "100px" }} onClick={handleOpen}>
        Edit
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="show_scrollbar"  sx={style}>
          {isLoading && <Loader />}
          <Box
            sx={{
              height: "60px",
              bgcolor: "background.card",
              borderBottom: "1px solid #BC172F",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: 2,
                py: 2,
              }}
            >
              <Grid item lg={3} md={3}></Grid>
              <Grid item lg={5} md={5}>
                <Typography
                  sx={{
                    fontFamily: "optima",
                    fontWeight: 700,
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  Restaurant & Restauranteer Experience
                </Typography>
              </Grid>
              <Grid item align="right" lg={4} md={4}>
                <IconButton onClick={handleClose}>
                  <CloseIcon sx={{}} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ margin: "0 auto", width: "85%", mt: 3 }}>
            <Typography sx={{ fontWeight: 500 }}>
              Entice guests by showcasing what sets your business apart.
            </Typography>

            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12}>
                  <CustomTextField
                    name="Deposit Per Person"
                    value={depositPerPerson}
                    onChange={(e) => setDepositPerPerson(e.target.value)}
                  />
                </Grid>
                <Grid item lg={12} md={12}>
                  {/* <CustomTextField
                    multiLine={true}
                    rows={4}
                    name="House Rules"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  /> */}
                  <InputLabel sx={{ mb: 1 }}>House Rules</InputLabel>
                  <ReactQuill
                    className="editor"
                    theme="snow"
                    value={description}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item lg={12} md={12}>
                  <CustomTextField
                    multiLine={true}
                    rows={4}
                    name="Cancellation Policy"
                    value={cancellationPolicy}
                    onChange={(e) => setCancellationPolicy(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box align="right" sx={{ mt: 2 }}>
              <Button
                variant="contained"
                sx={{ borderRadius: "10px", px: 6, py: 2 }}
                onClick={handleEdit}
              >
                Save Changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RestaurantExperienceEdit;
