import { Drawer } from '@mui/material'
import React from 'react'

const ListDrawer = ({open, handleClose}) => {
  return (
<>
<Drawer 
open={open}
onClose={handleClose}
anchor='right'
>

</Drawer>

</>
  )
}

export default ListDrawer