import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from './Expected';
import { Box, Typography, Grid, CircularProgress, LinearProgress } from '@mui/material'
import SquareIcon from "@mui/icons-material/Square";
import ReservationCard from '../Cards/ReservationCard';

const Canceled =({expanded, cancelled, handleChange, handleGetReservations, loader}) => {
  return (
<>
       <Accordion
                expanded={expanded === "Cancelled"}
                onChange={handleChange("Cancelled")}
                sx={{ background: expanded === "Cancelled" ? "#1A1A1A" : "#333" }}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  sx={{ border: "0.5px solid #fff" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", columnGap: 2 }}>
                      <SquareIcon
                        sx={{ color: "#BC172F", width: "20px", height: "20px" }}
                      />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "10px",
                            xs: "10px",
                          },
                        }}
                      >
                        CANCELLED
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "10px",
                          xs: "10px",
                        },
                        fontWeight: 500,
                      }}
                    >
                      {cancelled ?  `${cancelled.length} guests` :              <CircularProgress size={20} />}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {!cancelled || cancelled.length === 0 ? (
                      <>
                        <Box
                          sx={{
                            p: 4,
                            display: "grid",
                            placeItems: "center",
                            width: "100%",
                          }}
                        >
                               {
                                            loader &&   <Box sx={{width:'40%', mb:1}}>
                                              <LinearProgress/>
                                            </Box>
                                          }
                          <Typography
                            sx={{ textAlign: "center", color: "#fff" }}
                          >
                            No Reservations Available
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      cancelled &&
                      cancelled.map((item, index) => (
                        <Grid item lg={3} md={3} sm={6} xs={6} key={index}>
                          <ReservationCard
                            action={handleGetReservations}
                            borderColor="#E6475E"
                            data={item}
                          />
                        </Grid>
                      ))
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
</>
  )
}

export default Canceled;