import { CloseOutlined } from "@mui/icons-material";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  InputLabel,
  Button,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { SlCloudUpload } from "react-icons/sl";
import Papa from "papaparse";
import { CiFolderOn } from "react-icons/ci";
import trashIcon from "../../../../assets/Icons/trash.svg";
import { createMultipleGuestBook } from "../../../../api";
import { useSnackbar } from "notistack";
import Loader from "../../../Common/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
};
const expectedHeaders = [
  "firstName",
  "lastName",
  "phoneNumber",
  "emailAddress",
  "country",
  "birthday",
  "graduationAnniversary",
  "weddingAnniversary",
];

const ImportGuest = ({ open, onClose, action }) => {
  const [csvData, setCsvData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [columnMapping, setColumnMapping] = useState({});
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      Papa.parse(file, {
        complete: (result) => {
          if (!result.data || result.data.length === 0) {
            alert("Empty CSV file.");
            return;
          }

          const detectedHeaders = Object.keys(result.data[0]); // Extract headers
          const dataRows = result.data; // Data starts immediately since header=true

          const autoMapping = {};
          expectedHeaders.forEach((expectedHeader) => {
            const matchedHeader = detectedHeaders.find(
              (h) => h.toLowerCase().trim() === expectedHeader.toLowerCase()
            );
            if (matchedHeader) {
              autoMapping[expectedHeader] = matchedHeader;
            }
          });

          setHeaders(detectedHeaders);
          setColumnMapping(autoMapping);

          const formattedData = dataRows.map((row) => {
            let obj = {};
            expectedHeaders.forEach((field) => {
              obj[field] = row[autoMapping[field]] || ""; // Use header names directly
            });
            return obj;
          });

          setCsvData(formattedData);
        },
        header: true, // Ensures the first row is treated as headers
        skipEmptyLines: true,
      });
    }
  };



  const handleMappingChange = (expectedHeader, newColumn) => {
    setColumnMapping((prevMapping) => ({
      ...prevMapping,
      [expectedHeader]: newColumn,
    }));
  };

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };
  const handleUpload = async () => {
    if (!csvData.length) {
      alert("Please upload a valid CSV file.");
      return;
    }

    setUploading(true);
    await createMultipleGuestBook(csvData)
      .then((res) => {

        if (res?.data?.status) {
          handleAlert("success", `Guestbook successfully uploaded ✅`);
          onClose();
       
          action()
        }else{
                handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        // console.log(err);
              handleAlert("error", `${err.message} `);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  // console.log(selectedFile);
  return (
    <>
      {uploading && <Loader />}
      <Modal
        open={open}
        // onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              bgcolor: "#333",
              borderBottom: "1px solid ",
              borderColor: "primary.main",
              borderRadius: "8px 8px 0px 0px",
              px: 3,
              py: 1.5,
            }}
          >
            <Typography
              sx={{ fontFamily: "optima", fontWeight: 700, fontSize: "16px" }}
            >
              Import Guestbook
            </Typography>
            <IconButton onClick={onClose}>
              <CloseOutlined sx={{fontSize:'20px'}} />
            </IconButton>
          </Box>

          <Box sx={{ borderRadius: "0px 0px 8px 8px", p: 3 }}>
            <Typography sx={{ textAlign: "center", color: "#ccc" }}>
              <span style={{ fontWeight: 300 }}>
                Before uploading your files. ensure they have the following
                columns:
              </span>
              <br /> First Name, Last Name, Email Address and Phone number
            </Typography>

            {selectedFile ? (
              <>
                <Box
                  sx={{
                    mt: 3,
                    bgcolor: "#333",
                    border: "1px solid #fff",
                    borderRadius: "6px",
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", columnGap: 1 }}>
                    <CiFolderOn />
                    <Box>
                      <InputLabel sx={{ fontSize: "12px", color: "#fff" }}>
                        {selectedFile?.name}
                      </InputLabel>
                      <Typography sx={{ color: "#ccc", fontSize: "10px" }}>
                        1 csv file
                      </Typography>
                    </Box>
                  </Box>

                  <IconButton onClick={() => setSelectedFile(null)}>
                    <img src={trashIcon} alt="trash_icon" />
                  </IconButton>
                </Box>
              </>
            ) : (
              <Box
                onClick={() => fileInputRef.current.click()}
                sx={{
                  mt: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  border: "0.4px dashed #fff",
                  height: "200px",
                  cursor: "pointer",
                }}
              >
                <SlCloudUpload style={{ fontSize: "20px" }} />
                <Typography sx={{ color: "primary.main" }}>
                  Import your files
                </Typography>
                <Typography
                  sx={{ color: "#ccc", fontStyle: "italic", fontWeight: 200 }}
                >
                  Click here to import your csv files
                </Typography>
                <input
                  type="file"
                  accept=".csv"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                />
              </Box>
            )}

            <Box align="right">
              <Button
                disabled={csvData.length === 0}
                variant="contained"
                onClick={handleUpload}
                sx={{
                  width: "150px",
                  height: "45px",
                  borderRadius: "8px",
                  mt: 3,
                }}
              >
                Import{" "}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ImportGuest;
