import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { PiCoinsLight } from "react-icons/pi";

import CreatePoint from "../../Components/PointSystem/CreatePoint";
import PointList from "../../Components/PointSystem/PointList";
import { getPointGuestList, getPoints } from "../../../api";
import Loader from "../../Common/Loader";
import CreatePrice from "../../Components/PointSystem/CreatePrice";
import emptyIcon from '../../../assets/img/emptyRes.svg'

const PointSystem = () => {
  const [createPointModal, setCreatePointModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [point, setPoint] = useState(null);

  const [guest_list, setGuest_list] = useState(null)

  const handleGetPointList = async () => {
    setIsLoading(true);
    await getPoints()
      .then((res) => {
        // console.log(res);
        setPoint(res?.data?.result);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGetGuest  = async () => {
    setIsLoading(true);
    await getPointGuestList()
      .then((res) => {
        // console.log(res);
        setGuest_list(res?.data?.result);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetPointList();
    handleGetGuest();
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <Header
        title="Point System Management"
        description="Manage and update essential restaurant details, including name, location, contact information, and logo"
      />
      <Box
        sx={{
          margin: "0 auto",
          width: "95%",
          // height: "100%",
          overflow: "scroll",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: 7,
            border: "1px solid #1a1a1a",
            mt: 3,
            boxSizing: "border-box",
            borderRadius: "8px",
            height: "200px",
            p: 3,
          }}
        >
          <Box
            sx={{
              width: "250px",
              height: "100%",
              backgroundColor: "rgb(120, 16, 32)",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 3,
            }}
          >
            <Typography>Points Given</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
                mt: 1,
              }}
            >
              <PiCoinsLight style={{ fontSize: "20px" }} />
              <Typography
                sx={{ fontSize: "20px", fontFamily: "optima", fontWeight: 700 }}
              >
                {`${point?.TotalPoint || "0"}`} Points
              </Typography>
            </Box>
            <Typography sx={{ fontSize: "10px", mt: 0.2, color: "#ccc" }}>
              +{point?.TodayPoint || "--"} today
            </Typography>
            <Button
              onClick={() => setCreatePointModal(true)}
              variant="outlined"
              sx={{
                color: "#fff",
                bgcolor: "#000",
                fontWeight: 400,
                mt: 1,
                px: 3,
                borderRadius: "8px",
              }}
            >
              Create point
            </Button>
          </Box>
          <Box sx={{ flex: 1 }}>
            <PointList point_list={point?.PointAnalysis} />
          </Box>
        </Box>

        <Box
          sx={{
            bgcolor: " #151515",
            mt: 3,
            boxSizing: "border-box",
            borderRadius: "8px",
            p: 3,
          }}
        >
          <Typography sx={{ fontSize: "18px", fontFamily: "optima" }}>
            Redeem Guest
          </Typography>
          <Divider sx={{ mt: 1 }} />

          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item lg={4}>
        <CreatePrice/>
              </Grid>
              <Grid item lg={8}>
                <Box>

            {
            (  !guest_list || guest_list.length === 0) ? (
              <>
              <Box sx={{height:'300px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
<img src={emptyIcon}/>
<Typography sx={{mt:2}}>No guest list available for point winners</Typography>
              </Box>
              </>
            ):(
                    <Grid container spacing={2}>

                    {guest_list?.map((guest, index) => (
                      <Grid item lg={4}>
                        <Box
                        key={index}
                          sx={{
                            border: "1px solid #333333",
                            borderRadius: "8px",
                            height: "200px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            p: 3,
                          }}
                        >
                          <Avatar sx={{ height: "50px", width: "50px" }}>
                            PL
                          </Avatar>
                          <Box sx={{ width: "100%" }}>
                            <Typography sx={{ mt: 1 }}>
                              Alimi Samuel Ayomide
                            </Typography>
                            <Typography
                              sx={{ fontSize: "12px", color: "#333" }}
                            >
                              alimisamuel@gmail.com
                            </Typography>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                columnGap: 1,
                              }}
                            >
                              <LinearProgress
                                value={30}
                                variant="determinate"
                                sx={{ flex: 1 }}
                              />
                              <Typography sx={{ fontSize: "12px" }}>
                                <PiCoinsLight /> 300
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  textDecoration: "underline",
                                  color: "#ccc",
                                  fontWeight: 300,
                                  mt: 2,
                                  cursor: "pointer",
                                  fontSize: "12pxs",
                                }}
                              >
                                Redeem Now{" "}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
            )
            }
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <CreatePoint
        open={createPointModal}
        handleClose={() => setCreatePointModal(false)}
        action={handleGetPointList}
      />
    </>
  );
};

export default PointSystem;
