export const formatDate2 = (dateString) => {
  const date = new Date(dateString);

  return date.toLocaleDateString("en-US", {
    weekday: "short", // "Tue"
    year: "numeric", // "2024"
    month: "short", // "Oct"
    day: "2-digit", // "22"
  });

  // Output = Tue, Jan 21, 2025
};

export const formatTime = (time) => {
  const [hours, minutes] = time.split(":");
  const date = new Date();
  date.setHours(hours, minutes);

  return new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(date);

  // Outtput = 3:00 PM
};

export function getFormattedDateTime() {
  const now = new Date();

  // Format the date
  const options = { month: "long", day: "numeric", year: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(now);

  // Format the time
  let hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const isAM = hours < 12;

  // Convert to 12-hour format
  hours = hours % 12 || 12;
  const period = isAM ? "am" : "pm";

  const formattedTime = `${hours}:${minutes}${period}`;

  return `${formattedDate}. ${formattedTime}`;


  // Output January 25, 2025. 11:50am

}

 export const convertTime = (utcString) => {
    const date = new Date(utcString);
    
    // Convert to Nigeria's timezone (UTC+1)
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: "Africa/Lagos", // Nigeria's timezone
    });
  };

