import {
  Box,
  Button,
  IconButton,
  Typography,
  Grid,
  InputLabel,
  MenuItem,
  Tooltip,
  CssBaseline,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import bookImg from "../../../assets/Icons/book.svg";
import app from "../../../assets/Icons/app.svg";
import docIcon from "../../../assets/Icons/document-text.svg";
import caution from "../../../assets/Icons/caution.svg";
import bookmark from "../../../assets/Icons/archive-book.svg";
import thunder from "../../../assets/Icons/thunder.svg";
import { IoMdTime } from "react-icons/io";
import {
  changeBookingStatus,
  changeReservationTable,
  confirmReservation,
  getReservations,
  getReservationWaitlist,
} from "../../../api";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";
import { TbLayoutSidebarLeftExpand } from "react-icons/tb";
import { motion } from "framer-motion";

import SquareIcon from "@mui/icons-material/Square";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";

import Loader from "../../Common/Loader";
import Notes from "../RManagement/Notes/Notes";
import DietaryRestriction from "./DietaryRestriction";
import { RiTokenSwapLine } from "react-icons/ri";
import { useSnackbar } from "notistack";
import { BsBookmarkCheckFill } from "react-icons/bs";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  boxSizing: "border-box",
  borderRadius: "8px",
};
const style2 = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  boxSizing: "border-box",
  borderRadius: "8px",
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  background: "#333",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ExpandCircleDownOutlinedIcon
        sx={{ fontSize: "1.5rem", color: "#fff" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Guest = ({
  handleSelectReservation,
  formattedDate,
  selectedReservation,
  reservations,
  tables,
}) => {
  const [expanded, setExpanded] = React.useState("panel3");
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [finished, setFinished] = useState(null);
  const [seated, setSeated] = useState(null);
  const [upcoming, setUpcoming] = useState(null);
  const [cancelled, setIsCancelled] = useState(null);
  const restaurantName = localStorage.getItem("restaurantName");

  const [waitlist, setWaitlist] = useState(null);

  const handleGetWaitlistReservation = async () => {
    setIsLoading(true);
    await getReservationWaitlist()
      .then((res) => {
        setWaitlist(res?.data?.result);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    handleGetWaitlistReservation();
  }, []);

  useEffect(() => {
    if (reservations) {
      setData(reservations);
    }
  }, [reservations]);

  const handleGetReservations = async () => {
    setIsLoading(true);
    await getReservations(formattedDate)
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        setData(data?.result);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const filterFinished = data?.filter(
    (item) =>
      item.BookingStatus == "Finished" && item?.RestaurantName == restaurantName
  );
  const filterExpected = data?.filter(
    (item) =>
      item.BookingStatus == "Expected" && item?.RestaurantName == restaurantName
  );
  const filterSeated = data?.filter(
    (item) =>
      item.BookingStatus == "Seated" && item?.RestaurantName == restaurantName
  );
  const filterCancelled = data?.filter(
    (item) =>
      item.BookingStatus === "Cancelled" &&
      item?.RestaurantName == restaurantName
  );

  useEffect(() => {
    if (data) {
      setIsCancelled(filterCancelled || []);
      setUpcoming(filterExpected || []);
      setFinished(filterFinished || []);
      setSeated(filterSeated || []);
    }
  }, [data]);

  useEffect(() => {
    handleGetReservations();
  }, [formattedDate]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [shrink, setShrink] = useState(false);

  return (
    <>
      {isLoading && <Loader />}

      <Box
        className="hide_scrollbar"
        sx={{
          height: "100vh",
          width: shrink ? 55 : 400,

          boxSizing: "border-box",
          overflow: "scroll",
          borderRight: "0.5px solid #fff",
          transition: "0.2s all ease-in-out",
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar for WebKit browsers
          },
          bgcolor: "000",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #fff",
            px: 2,
            pb: 2,
            pt: 1,
            height: "70px",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
            <IconButton
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9, rotate: "2.5deg" }}
              transition={{
                duration: 0.2,
                ease: "easeInOut",
              }}
              component={motion.button}
              onClick={() => setShrink(!shrink)}
            >
              {shrink ? (
                <TbLayoutSidebarLeftExpand style={{ color: "#fff" }} />
              ) : (
                <TbLayoutSidebarRightExpand style={{ color: "#fff" }} />
              )}
            </IconButton>

            <Typography sx={{ color: "#fff", fontSize: "12px" }}>
              Manage Reservations
            </Typography>
          </Box>

          {/* <Button
            startIcon={<AiOutlineEdit style={{ fontSize: "12px" }} />}
            variant="outlined"
            sx={{ border: "1px solid #fff", color: "#fff", fontSize: "12px" }}
          >
            Edit
          </Button> */}
        </Box>
        {shrink ? (
          <></>
        ) : (
          <>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={{ background: expanded === "panel3" ? "#1A1A1A" : "#333" }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ border: "0.5px solid #fff" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", columnGap: 2, alignItems: "center" }}
                  >
                    <SquareIcon
                      sx={{ color: "#E6BF47", width: "15px", height: "15px" }}
                    />
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: 500,
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "12px",
                          xs: "10px",
                        },
                      }}
                    >
                      UPCOMING
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: {
                        lg: "12px",
                        md: "12px",
                        sm: "12px",
                        xs: "10px",
                      },
                    }}
                  >
                    {upcoming && `${upcoming.length} guests`}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {!upcoming || upcoming.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          p: 4,
                          display: "grid",
                          placeItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ textAlign: "center", color: "#fff" }}>
                          No Reservations Available
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    upcoming &&
                    upcoming.map((item, index) => (
                      <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                        <CardComponents
                          action={handleGetReservations}
                          handleSelectReservation={handleSelectReservation}
                          selectedReservation={selectedReservation}
                          borderColor="#E6BF47"
                          data={item}
                          tables={tables}
                        />
                      </Grid>
                    ))
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{ background: expanded === "panel2" ? "#1A1A1A" : "#333" }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ border: "0.5px solid #fff" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", columnGap: 2, alignItems: "center" }}
                  >
                    <SquareIcon
                      sx={{ color: "#47E673", width: "15px", height: "15px" }}
                    />
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: 500,
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "10px",
                          xs: "10px",
                        },
                      }}
                    >
                      SEATED
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: 500,
                      fontSize: {
                        lg: "12px",
                        md: "12px",
                        sm: "10px",
                        xs: "10px",
                      },
                    }}
                  >
                    {seated && `${seated.length} guests`}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {!seated || seated.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          p: 4,
                          display: "grid",
                          placeItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ textAlign: "center", color: "#fff" }}>
                          No Reservations Available
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    seated &&
                    seated.map((item, index) => (
                      <Grid item lg={12} md={12} sm={12} xs={6} key={index}>
                        <CardComponents
                          borderColor="#47E673"
                          data={item}
                          action={handleGetReservations}
                          handleSelectReservation={handleSelectReservation}
                          selectedReservation={selectedReservation}
                          tables={tables}
                        />
                      </Grid>
                    ))
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ background: expanded === "panel1" ? "#1A1A1A" : "#333" }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ border: "0.5px solid #fff" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", columnGap: 2, alignItems: "center" }}
                  >
                    <SquareIcon
                      sx={{ color: "#4787E6", width: "15px", height: "15px" }}
                    />
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: 500,
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "10px",
                          xs: "10px",
                        },
                      }}
                    >
                      FINISHED
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: 500,
                      fontSize: {
                        lg: "12px",
                        md: "12px",
                        sm: "10px",
                        xs: "10px",
                      },
                    }}
                  >
                    {finished && `${finished.length} guests`}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {!finished || finished.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          p: 4,
                          display: "grid",
                          placeItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ textAlign: "center", color: "#fff" }}>
                          No Reservations Available
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    finished &&
                    finished.map((item, index) => (
                      <Grid item lg={12} md={12} sm={12} xs={6} key={index}>
                        <CardComponents
                          finished
                          action={handleGetReservations}
                          borderColor="#4787E6"
                          handleSelectReservation={handleSelectReservation}
                          selectedReservation={selectedReservation}
                          data={item}
                          tables={tables}
                        />
                      </Grid>
                    ))
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              sx={{ background: expanded === "panel4" ? "#1A1A1A" : "#333" }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ border: "0.5px solid #fff" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", columnGap: 2, alignItems: "center" }}
                  >
                    <SquareIcon
                      sx={{ color: "#BC172F", width: "15px", height: "15px" }}
                    />
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: 500,
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "10px",
                          xs: "10px",
                        },
                      }}
                    >
                      CANCELLED
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        lg: "12px",
                        md: "12px",
                        sm: "10px",
                        xs: "10px",
                      },
                      fontWeight: 500,
                    }}
                  >
                    {cancelled && `${cancelled.length} guests`}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {!cancelled || cancelled.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          p: 4,
                          display: "grid",
                          placeItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ textAlign: "center", color: "#fff" }}>
                          No Reservations Available
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    cancelled &&
                    cancelled.map((item, index) => (
                      <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                        <CardComponents
                          action={handleGetReservations}
                          handleSelectReservation={handleSelectReservation}
                          borderColor="#BC172F"
                          data={item}
                          selectedReservation={selectedReservation}
                        />
                      </Grid>
                    ))
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
              sx={{ background: expanded === "panel5" ? "#1A1A1A" : "#333" }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ border: "0.5px solid #fff" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", columnGap: 2, alignItems: "center" }}
                  >
                    <IoMdTime style={{ fontSize: "17px" }} />
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: 500,
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "10px",
                          xs: "10px",
                        },
                      }}
                    >
                      WAITLIST
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        lg: "12px",
                        md: "12px",
                        sm: "10px",
                        xs: "10px",
                      },
                      fontWeight: 500,
                    }}
                  >
                    {waitlist && `${waitlist.length} guests`}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {!waitlist || waitlist.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          p: 4,
                          display: "grid",
                          placeItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ textAlign: "center", color: "#fff" }}>
                          No Reservations Available
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    waitlist &&
                    waitlist.map((item, index) => (
                      <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                        <WaitlistCard
                          action={handleGetReservations}
                          handleSelectReservation={handleSelectReservation}
                          borderColor="#5C5C5C"
                          data={item}
                          selectedReservation={selectedReservation}
                        />
                      </Grid>
                    ))
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>
    </>
  );
};

export default Guest;

const CardComponents = ({
  data,
  borderColor,
  action,
  handleSelectReservation,
  selectedReservation,
  tables,
}) => {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const [isLoading, setIsLoading] = useState(false);

  const [selectedTable, setSelectedTable] = useState(null);

  const handleModifyReservation = async (status) => {
    setIsLoading(true);
    await changeBookingStatus(data?.BoookingId, status)
      .then((res) => {
        setIsLoading(false);
        action();
        setOpen(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleChangeTable = async () => {
    setIsLoading(true);

    await changeReservationTable(data?.BoookingId, selectedTable)
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        if (data?.status) {
          handleAlert("success", `${data?.success_message}`);

          action();
          setOpen2(false);
        } else {
          handleAlert("error", `${data?.result[0]}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert("error", `${err}`);
      });
  };
  const restaurantName = localStorage.getItem("restaurantName");

  const handleApproveReservation = async () => {
    setIsLoading(true);
    await confirmReservation(data?.BoookingId)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          handleAlert(
            "success",
            `${
              res?.data?.success_message || "Reservation successfully approved"
            }`
          );
          action();
          setOpen3(false);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        handleAlert("error", `${err.message}`);
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Box
          sx={{
            border: `1px solid ${borderColor}`,
            borderRadius: "5px",
            transition: "0.1s all linear",
            cursor: "pointer",
            ...(selectedReservation === data && {
              border: `1.5px dashed ${borderColor}`,
            }),
          }}
          onClick={() => handleSelectReservation(data)}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              color: "#fff",
              p: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
              <InputLabel
                sx={{ color: "#fff", fontWeight: 500, fontSize: "12px" }}
              >
                {data?.bookingUserDetails?.Name
                  ? data?.bookingUserDetails?.Name
                  : "--"}
              </InputLabel>
              <InputLabel
                sx={{ color: "#fff", fontWeight: 400, fontSize: "12px" }}
              >
                {data?.RestaurantName ? data?.RestaurantName : "--"}
              </InputLabel>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "10px" }}
                >
                  {data?.Guest ? data?.Guest : "--"}
                </Typography>
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "10px" }}
                >
                  {`${data?.BookingTime} - ${data?.TimeCount}`}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                bgcolor: "#333",
                borderRadius: "3px",

                height: "35px",
                width: "35px",
                display: "grid",
                placeItems: "center",
                boxSizing: "border-box",
              }}
            >
              <Typography
                sx={{ color: "#fff", fontWeight: 500, fontSize: "12px" }}
              >
                {data?.TableName ? data?.TableName : "--"}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              bgcolor: "#333",
              p: 1,
              borderRadius: "0px 0px 10px 10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Notes
              value={data?.VisitNote ? data?.VisitNote : "No Visit  Note"}
              type="visit"
              borderColor={borderColor}
            >
              <Tooltip
                title={
                  <Typography sx={{ fontSize: "8px" }}>Visit notes</Typography>
                }
                followCursor
                arrow
              >
                <IconButton>
                  <img src={docIcon} width={20} alt="icon" />
                </IconButton>
              </Tooltip>
            </Notes>

            {data?.BookingStatus !== "Cancelled" && (
              <Tooltip
                title={
                  <Typography sx={{ fontSize: "8px" }}>
                    cancel reservation
                  </Typography>
                }
                followCursor
                arrow
              >
                <IconButton onClick={() => handleOpen()}>
                  <img src={caution} width={20} alt="icon" />
                </IconButton>
              </Tooltip>
            )}

            <DietaryRestriction
              value={
                data?.DietaryRestriction
                  ? data?.DietaryRestriction
                  : "No Dietary Restriction"
              }
              type="visit"
              borderColor={borderColor}
            >
              <Tooltip
                title={
                  <Typography sx={{ fontSize: "8px" }}>
                    Dietary Restriction
                  </Typography>
                }
                followCursor
                arrow
              >
                <IconButton>
                  <img src={bookmark} width={20} alt="icon" />
                </IconButton>
              </Tooltip>
            </DietaryRestriction>

            <Tooltip
              title="Change table"
              sx={{ fontFamily: "outfit", fontSize: "4px" }}
              followCursor
              arrow
            >
              <IconButton onClick={() => setOpen2(true)}>
                <RiTokenSwapLine style={{ fontSize: "20px" }} />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={
                <Typography sx={{ fontSize: "8px" }}>
                  Approve reservation
                </Typography>
              }
              followCursor
              arrow
            >
              <IconButton onClick={() => setOpen3(!open3)}>
                <BsBookmarkCheckFill />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ fontSize: "14px", color: "#fff", textAlign: "center" }}
          >
            Are you sure you want to cancel{" "}
            <b style={{ color: "#E6475E" }}>
              {" "}
              {data?.bookingUserDetails?.Name
                ? data?.bookingUserDetails?.Name
                : "--"}
            </b>{" "}
            Reservation
          </Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: 2, mt: 3 }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              fullWidth
              sx={{ color: "#fff", borderColor: "#fff" }}
            >
              No, Close
            </Button>
            <Button
              onClick={() => handleModifyReservation("Cancelled")}
              variant="contained"
              fullWidth
              sx={{ bgcolor: "#E6475E" }}
            >
              Yes, Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={open3}
        onClose={() => setOpen3(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ fontSize: "14px", color: "#fff", textAlign: "center" }}
          >
            Are you sure you want to approve{" "}
            <b style={{ color: "#2ff924" }}>
              {" "}
              {data?.bookingUserDetails?.Name
                ? data?.bookingUserDetails?.Name
                : "--"}
            </b>{" "}
            Reservation
          </Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: 2, mt: 3 }}
          >
            <Button
              variant="outlined"
              onClick={() => setOpen3(false)}
              fullWidth
              sx={{ color: "#fff", borderColor: "#fff" }}
            >
              No, Close
            </Button>
            <Button
              onClick={() => handleApproveReservation()}
              variant="contained"
              fullWidth
              sx={{ bgcolor: "#2ff924b7", "&:hover": { bgcolor: "#1d2e28" } }}
            >
              Yes, Approve
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={() => setOpen2(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <Typography
            sx={{ fontSize: "14px", color: "#fff", textAlign: "center" }}
          >
            Change Table for{" "}
            <b style={{ color: borderColor }}>
              {" "}
              {data?.bookingUserDetails?.Name
                ? data?.bookingUserDetails?.Name
                : "--"}
            </b>{" "}
            Reservation
          </Typography>
          <Box sx={{ mt: 5, overflow: "scroll", maxHeight: "400px" }}>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "12px",
                textDecoration: "underline",
                mb: 2,
              }}
            >
              Available Tables{" "}
            </Typography>
            <Grid container spacing={2}>
              {tables?.map((item, i) => (
                <Grid item lg={3}>
                  <Box
                    sx={{
                      border: "0.5px solid #fff",
                      borderRadius: "8px",
                      boxSizing: "border-box",
                      p: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "0.1s all linear",
                      ...(selectedTable === item?.Id && {
                        border: `1px dashed ${borderColor}`,
                      }),
                    }}
                    onClick={() => setSelectedTable(item?.Id)}
                  >
                    <InputLabel
                      sx={{ color: "#fff", fontSize: "12px", fontWeight: 600 }}
                    >
                      {item?.Name}
                    </InputLabel>
                    <InputLabel sx={{ color: "#fff", fontSize: "12px" }}>
                      {item?.MinSize}-{item?.MaxSize}
                    </InputLabel>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Box sx={{ mt: 3 }}>
              <Button
                disabled={!selectedTable}
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: borderColor,
                  py: 2,
                  borderRadius: "8px",
                  "&:hover": { background: `${borderColor}b7` },
                }}
                onClick={handleChangeTable}
              >
                Change Table
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export const WaitlistCard = ({
  data,
  borderColor,
  action,
  handleSelectReservation,
  selectedReservation,
  tables,
}) => {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const [isLoading, setIsLoading] = useState(false);

  const [selectedTable, setSelectedTable] = useState(null);

  const handleModifyReservation = async (status) => {
    setIsLoading(true);
    await changeBookingStatus(data?.BoookingId, status)
      .then((res) => {
        setIsLoading(false);
        action();
        setOpen(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleChangeTable = async () => {
    setIsLoading(true);

    await changeReservationTable(data?.BoookingId, selectedTable)
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        if (data?.status) {
          handleAlert("success", `${data?.success_message}`);

          action();
          setOpen2(false);
        } else {
          handleAlert("error", `${data?.result[0]}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert("error", `${err}`);
      });
  };
  const restaurantName = localStorage.getItem("restaurantName");

  const handleApproveReservation = async () => {
    setIsLoading(true);
    await confirmReservation(data?.BoookingId)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          handleAlert(
            "success",
            `${
              res?.data?.success_message || "Reservation successfully approved"
            }`
          );
          action();
          setOpen3(false);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        handleAlert("error", `${err.message}`);
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Box
          sx={{
            border: `1px solid ${borderColor}`,
            borderRadius: "5px",
            transition: "0.1s all linear",
            cursor: "pointer",
            ...(selectedReservation === data && {
              border: `1.5px dashed ${borderColor}`,
            }),
          }}
          onClick={() => handleSelectReservation(data)}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              color: "#fff",
              p: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
              <InputLabel
                sx={{ color: "#fff", fontWeight: 500, fontSize: "12px" }}
              >
                {data?.bookingUserDetails?.Name
                  ? data?.bookingUserDetails?.Name
                  : "--"}
              </InputLabel>
              <InputLabel
                sx={{ color: "#fff", fontWeight: 400, fontSize: "12px" }}
              >
                {data?.RestaurantName ? data?.RestaurantName : "--"}
              </InputLabel>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "10px" }}
                >
                  {data?.Guest ? data?.Guest : "--"}
                </Typography>
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "10px" }}
                >
                  {`${data?.Time} `}
                </Typography>
              </Box>
              <Typography
                sx={{ color: "#fff", fontWeight: 300, fontSize: "10px" }}
              >
                {`${data?.Date} `}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#333",
                borderRadius: "3px",

                height: "35px",
                width: "35px",
                display: "grid",
                placeItems: "center",
                boxSizing: "border-box",
              }}
            >
              <Typography
                sx={{ color: "#fff", fontWeight: 500, fontSize: "12px" }}
              >
                {data?.TableName ? data?.TableName : "--"}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              bgcolor: "#333",
              p: 1,
              borderRadius: "0px 0px 10px 10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button fullWidth sx={{ color: "#fff" }}>
              Accept Reservation
            </Button>
          </Box>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ fontSize: "14px", color: "#fff", textAlign: "center" }}
          >
            Are you sure you want to cancel{" "}
            <b style={{ color: "#E6475E" }}>
              {" "}
              {data?.bookingUserDetails?.Name
                ? data?.bookingUserDetails?.Name
                : "--"}
            </b>{" "}
            Reservation
          </Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: 2, mt: 3 }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              fullWidth
              sx={{ color: "#fff", borderColor: "#fff" }}
            >
              No, Close
            </Button>
            <Button
              onClick={() => handleModifyReservation("Cancelled")}
              variant="contained"
              fullWidth
              sx={{ bgcolor: "#E6475E" }}
            >
              Yes, Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={open3}
        onClose={() => setOpen3(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ fontSize: "14px", color: "#fff", textAlign: "center" }}
          >
            Are you sure you want to approve{" "}
            <b style={{ color: "#2ff924" }}>
              {" "}
              {data?.bookingUserDetails?.Name
                ? data?.bookingUserDetails?.Name
                : "--"}
            </b>{" "}
            Reservation
          </Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: 2, mt: 3 }}
          >
            <Button
              variant="outlined"
              onClick={() => setOpen3(false)}
              fullWidth
              sx={{ color: "#fff", borderColor: "#fff" }}
            >
              No, Close
            </Button>
            <Button
              onClick={() => handleApproveReservation()}
              variant="contained"
              fullWidth
              sx={{ bgcolor: "#2ff924b7", "&:hover": { bgcolor: "#1d2e28" } }}
            >
              Yes, Approve
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={() => setOpen2(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <Typography
            sx={{ fontSize: "14px", color: "#fff", textAlign: "center" }}
          >
            Change Table for{" "}
            <b style={{ color: borderColor }}>
              {" "}
              {data?.bookingUserDetails?.Name
                ? data?.bookingUserDetails?.Name
                : "--"}
            </b>{" "}
            Reservation
          </Typography>
          <Box sx={{ mt: 5, overflow: "scroll", maxHeight: "400px" }}>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "12px",
                textDecoration: "underline",
                mb: 2,
              }}
            >
              Available Tables{" "}
            </Typography>
            <Grid container spacing={2}>
              {tables?.map((item, i) => (
                <Grid item lg={3}>
                  <Box
                    sx={{
                      border: "0.5px solid #fff",
                      borderRadius: "8px",
                      boxSizing: "border-box",
                      p: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "0.1s all linear",
                      ...(selectedTable === item?.Id && {
                        border: `1px dashed ${borderColor}`,
                      }),
                    }}
                    onClick={() => setSelectedTable(item?.Id)}
                  >
                    <InputLabel
                      sx={{ color: "#fff", fontSize: "12px", fontWeight: 600 }}
                    >
                      {item?.Name}
                    </InputLabel>
                    <InputLabel sx={{ color: "#fff", fontSize: "12px" }}>
                      {item?.MinSize}-{item?.MaxSize}
                    </InputLabel>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Box sx={{ mt: 3 }}>
              <Button
                disabled={!selectedTable}
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: borderColor,
                  py: 2,
                  borderRadius: "8px",
                  "&:hover": { background: `${borderColor}b7` },
                }}
                onClick={handleChangeTable}
              >
                Change Table
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
