import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomModal from "../../../Common/CustomModal";
import {
  addGuestTags,
  createGuestTags,
  getGuestAnalysis,
  getGuestTags,
  guestDeleteTag,
  guestUnTag,
} from "../../../../api";
import { useSnackbar } from "notistack";
import { RiAddLine } from "react-icons/ri";
import { MdDone } from "react-icons/md";
import DeleteModal from "../../../Common/DeleteModal";

const AddTags = ({ name, data, callback }) => {
  const { Id, Tags } = data || {};
  const [openModal, setOpenModal] = useState(false);
  const [guestTags, setGuestTags] = useState([]);
  const [savedTags, setSavedTags] = useState([]);
  const [openTagModal, setOpenTagModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const [tagName, setTagName] = useState("");

  const [tagLoader, setTagLoader] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);

  const [loaders, setLoaders] = useState({
    create: false,
    add: false,
    untag: false,
    delete: false,
    get: false,
  });

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handleGetGuestInfo = async () => {
    await getGuestAnalysis(Id)
      .then((res) => {
        setTagLoader(false);
        if (res?.data?.status) {
          setSavedTags(res?.data?.result[0]?.Tags);
          console.log(res?.data?.result[0]?.Tags, "Saved");
        }
      })
      .catch((err) => {
        setTagLoader(false);
        handleAlert("error", `${err?.message}`);
      });
  };

  const handleGetGuestTags = async () => {
    setLoaders({ ...loaders, get: true });
    await getGuestTags()
      .then((res) => {
        setLoaders({ ...loaders, get: false });
        console.log(res);
        setGuestTags(res.data.result);
      })
      .catch((err) => {
        setLoaders({ ...loaders, get: false });
        console.log(err);
      });
  };

  useEffect(() => {
    if (Tags) {
      handleGetGuestTags();
      setSavedTags(Tags);
    }
  }, [data, Tags]);

  const handleCreateTag = async () => {
    setTagLoader(true);
    await createGuestTags(tagName)
      .then((res) => {
        if (res?.data?.status) {
          setOpenTagModal(false);
          handleGetGuestTags();
          handleAlert("success", `${res?.data?.success_message}`);
          setTagName("");
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
        setTagLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setTagLoader(false);
        handleAlert("error", `${err.message}`);
      });
  };

  const handleAddGuestTag = async (tagId) => {
    setSelectedTag(tagId);
    setTagLoader(true);
    await addGuestTags(tagId?.Id, Id)
      .then((res) => {
        // setTagLoader(false);
        if (res?.data?.status) {
          handleGetGuestInfo();
        }
      })
      .catch((err) => {
        setTagLoader(false);
      });
  };

  const handleDeleteTag = async () => {
    setLoaders({ ...loaders, delete: true });

    await guestDeleteTag(selectedTag?.Id)
      .then((res) => {
        handleGetGuestTags();
        setOpenDelete(false);
           setLoaders({ ...loaders, delete: false });
      })
      .catch((err) => {
        console.log(err);
           setLoaders({ ...loaders, delete: false });
      });
  };
  const handleUntag = (chipToDelete) => async () => {
    setSelectedTag(chipToDelete);
    setLoaders({ ...loaders, untag: true });
    await guestUnTag(chipToDelete?.Id, Id)
      .then((res) => {
        setLoaders({ ...loaders, untag: false });
        handleGetGuestInfo();
      })
      .catch((err) => {
        console.log(err);
            setLoaders({ ...loaders, untag: false });
      });
  };

  const updatedTag = guestTags?.map((tag) => {
    const isAddedTag = savedTags?.some((guest) => guest.Id === tag.Id);

    return { ...tag, isAddedTag };
  });
  return (
    <>
      <Button
        sx={{
          px: 2,
          fontSize: "12px",
          border: "1px dashed white",
          borderRadius: "50px",
          color: "#fff",
        }}
        onClick={() => setOpenModal(true)}
      >
        Add/Edit Tag
      </Button>

      {/* Modal popup */}

      <CustomModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          callback();
        }}
        title="Add/Edit Tags"
        width={500}
      >
        <Box sx={{ p: 3, boxSizing: "border-box" }}>
          <Typography sx={{ color: "#ccc" }}>{name} Tags</Typography>
          <Box sx={{ mt: 2, display: "flex", columnGap: 2, flexWrap: "wrap" }}>
            {savedTags.map((data) => {
              return (
                <Chip
                  key={data.Id}
                  label={data.Name}
                  clickable
                  deleteIcon={
                    loaders.untag &&
                    selectedTag?.Id === data?.Id && (
                      <CircularProgress size={10} sx={{ color: "#fff" }} />
                    )
                  }
                  onDelete={
                    data.label === "React" ? undefined : handleUntag(data)
                  }
                  sx={{
                    color: "#fff",
                    background: data.color,
                    fontSize: "10px",
                    fontFamily: "outfit",
                    mb: 0.5,
                  }}
                />
              );
            })}
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box>
            <Typography>Available Tags</Typography>
            <Box
              sx={{ mt: 2, display: "flex", columnGap: 2, flexWrap: "wrap" }}
            >
              {loaders.get ? (
                <>Loading...</>
              ) : updatedTag.length === 0 ? (
                <>No Guest Tag</>
              ) : (
                updatedTag.map((data) => (
                  <Chip
                    key={data.Id}
                    label={data.Name}
                    disabled={data?.isAddedTag}
                    onDelete={
                      data.label === "React"
                        ? undefined
                        : () => {
                            setSelectedTag(data);
                            setOpenDelete(true);
                          }
                    }
                    onClick={() => handleAddGuestTag(data)}
                    clickable
                    icon={
                      tagLoader && selectedTag?.Id === data?.Id ? (
                        <CircularProgress size={10} sx={{ color: "#fff" }} />
                      ) : data?.isAddedTag ? (
                        <MdDone />
                      ) : (
                        <RiAddLine />
                      )
                    }
                    sx={{
                      color: "#fff",
                      fontSize: "10px",
                      fontFamily: "outfit",
                      mb: 0.8,
                    }}
                  />
                ))
              )}
            </Box>
          </Box>

          <Button
            onClick={() => setOpenTagModal(true)}
            sx={{ whiteSpace: "nowrap", px: 2, color: "#fff", mt: 2 }}
            startIcon={<RiAddLine style={{ fontSize: "14px" }} />}
          >
            {" "}
            Custom
          </Button>
        </Box>
      </CustomModal>

      <CustomModal
        open={openTagModal}
        onClose={() => setOpenTagModal(false)}
        title="Create Custom Tag"
        width={300}
      >
        <Box sx={{ px: 3, pt: 3 }}>
          {/* <InputLabel sx={{ mt: 3, color:'#ccc' , fontSize:'12px'}}>Tag Name</InputLabel> */}
          <TextField
            fullWidth
            margin="dense"
            variant="standard"
            label="Tag Name"
            value={tagName}
            onChange={(e) => setTagName(e.target.value)}
          />

          <Box align="center" sx={{ px: 4, my: 3 }}>
            <Button
              startIcon={
                tagLoader && (
                  <CircularProgress size={15} sx={{ color: "#fff" }} />
                )
              }
              disabled={!tagName || tagLoader}
              onClick={handleCreateTag}
              variant="contained"
              fullWidth
              sx={{ borderRadius: "8px", height: "45px" }}
            >
              Create
            </Button>
          </Box>
        </Box>
      </CustomModal>

      <DeleteModal
        isLoading={loaders.delete}
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        handleDelete={handleDeleteTag}
        name={`Tag - ${selectedTag?.Name}`}
      />
    </>
  );
};

export default AddTags;
