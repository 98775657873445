import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  InputLabel,
  TextField,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import emptyIcon from "../../../assets/img/emptyRes.svg";
import userAdd from "../../../assets/Icons/user-add.svg";
import Modal from "@mui/material/Modal";

import { alpha, styled } from "@mui/material/styles";
import NewGuest from "../../Components/Guest/Create/NewGuest";
import { getGuestBook } from "../../../api";
import GuestList from "../../Components/Guest/GuestList";
import Loader from "../../Common/Loader";
import { Helmet } from "react-helmet-async";
import NoMobile from "../Screens/NoMobile";
import SEO from "../../../Utility/SEO";
import AdvancedGuest from "../../Components/Guest/AdvancedGuest/AdvancedGuest";
import CRM from "../../Components/Guest/crm/CRM";
import { CgArrowLeft } from "react-icons/cg";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "../../../STYLES/theme/ThemeContextProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "none",
  borderRadius: "5px",
  boxShadow: 24,
  borderRadius: 2,
};

const GuestBook = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

    const [isLoading, setIsLoading] = useState(false);

  const [guestData, setGuestData] = useState(null);






  const updateClose = (state) => {
    setOpen(state);
  };


  const handleGetGuest = async () => {
    setIsLoading(true);
    await getGuestBook()
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        setGuestData(data?.result);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetGuest();

  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const user = useSelector(
    (state) => state?.user?.user?.roles?.[0] ?? "defaultRole"
  );

  const [isPaidPremium, setIsSubcribed] = useState(false);

  useEffect(() => {
    if (user === "Splash_Subcription_Owner") {
      setIsSubcribed(true);
    } else {
      setIsSubcribed(false);
    }
  }, [user]);

  const [isCrm, setIsCrm] = useState(false);
  const { isDarkTheme } = useContext(ThemeContext);
  return (
    <>
      {isMobile ? (
        <>
          <NoMobile />
        </>
      ) : (
        <>
          <SEO
            title="Reisty | Restaurant GuestBook Management"
            description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
            name="Reisty"
            type="article"
            canonicalUrl="https://restaurant.reisty.com/dashboard/guest-book"
          />
          {isLoading && <Loader />}
          {!guestData || guestData.length === 0 ? (
            <>
              <Box
                sx={{
                  // border: "1px solid red",
                  height: "80vh",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Box align="center" sx={{ width: "70%" }}>
                  <img src={emptyIcon} alt="icon" />
                  <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                    You have no guests on your guestbook{" "}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 300,
                      mt: 2,
                    }}
                  >
                    There are currently no guests in your guestbook list. To add
                    a new list of guests, please click on the "Create Guest"
                    button below.
                  </Typography>
                  <Button
                    onClick={handleOpen}
                    sx={{
                      mt: 3,
                      px: 3,
                    }}
                  >
                    <img
                      src={userAdd}
                      alt="user_add"
                      style={{ marginRight: "5px" }}
                    />
                    Create Guest
                  </Button>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  background: isDarkTheme ? "#1a1a1a" : "#ffff",
                  borderBottom: "0.2px solid #ccccccb7",
                  p: 2,

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "80px",
                }}
              >
                {isCrm ? (
                  <>
                    <Box
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9, rotate: "2.5deg" }}
                      transition={{
                        duration: 0.2,
                        ease: "easeInOut",
                      }}
                      component={motion.div}
                      sx={{ cursor: "pointer", color: "text.primary" }}
                      onClick={() => setIsCrm(false)}
                    >
                      <CgArrowLeft style={{}} />
                      <Typography sx={{ fontSize: "12px" }}>
                        Back to Guestbook
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: 700,
                        fontFamily: "optima",
                      }}
                    >
                      Guestbook
                    </Typography>
                    <Typography variant="caption" sx={{ fontSize: "12px" }}>
                      Manage and track guest entries, feedback, and special
                      requests for a personalized dining experience.
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                >
                  {isPaidPremium && (
                    <Button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9, rotate: "2.5deg" }}
                      transition={{
                        duration: 0.2,
                        ease: "easeInOut",
                      }}
                      component={motion.button}
                      sx={{
                        bgcolor: "#FFEDED",
                        px: 2,
                        textAlign: "center",
                        borderRadius: "50px",
                        fontSize: "12px",
                        color: "#BC172F",
                        fontWeight: 400,
                        cursor: "pointer",
                      }}
                      onClick={() => setIsCrm(true)}
                    >
                      Advanced Guestbook
                    </Button>
                  )}
                  <Button variant="contained" onClick={handleOpen}>
                    Create Guest
                  </Button>
                </Box>
              </Box>

              {isCrm ? (
                <CRM data={guestData}  callBack={handleGetGuest}/>
              ) : (
                <>
                  {isPaidPremium ? (
                    <>
                      <AdvancedGuest data={guestData} action={handleGetGuest} />
                    </>
                  ) : (
                    <GuestList data={guestData} action={handleGetGuest} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <NewGuest handleCloses={updateClose} callBack={handleGetGuest} />
        </Box>
      </Modal>
    </>
  );
};

export default GuestBook;
