export const PATHS = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  PRICING: "/pricing",
  GET_STARTED: "/get-started",
  SETUP: "/quick-setup",
  RESET_PASSWORD: "/reset-password",
  CREATE_PASSWORD: "/account/team/createpassword",
  CUSTOMER_SUPPORT: "/customer-support",
  FAQ: "/faq",
  GUEST_PLAN: "/guest-floorplan",
  TUTORIALS: "/tutorials",
  SELECT_RESTAURANT: "/dashboard/select",
  

  DASHBOARD: {
    DASHBOARD: "/dashboard",
    RESTAURANT_PROFILE: "/dashboard/restaurantprofile",
    BASIC_INFORMATION: "/dashboard/basic-information",
    OFFERINGS: "/dashboard/offerings",
    EXPERIENCE: "/dashboard/experience",
    GALLERY: "/dashboard/gallery-photos",
    GUESTBOOK: "/dashboard/guest-book",
    EXTERNAL_LINKS: "/dashboard/online-reservation",
    RESERVATION_MANAGEMENT: "/reservation-management",
    MENU: "/dashboard/menu",
    TABLES: "/dashboard/table-rooms",
    ROOM: "/dashboard/room/:id",
    FLOOR_PLAN: "/floor-plan",
    RESERVATION_HOURS: "/dashboard/reservation-hours",
    BILLINGS: "/dashboard/billings",
    TICKET: "/dashboard/experience/tickets/:name/:id",
    ACCOUNT: "/dashboard/account",
    POINT_SYSTEM:'/dashboard/point-system',

// ADVANCE REPORT=================
// =================================
    ADVANCE_REPORT: {
      INDEX: "/dashboard/advanced-reports",
      RATINGS: "/dashboard/advanced-reports/rating-report",
      RESERVATION: "/dashboard/advanced-reports/reservation-report",
      EXPERIENCE: "/dashboard/advanced-reports/events-reports",
    },
  },
};
