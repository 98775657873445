import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Typography,
  Button,
  Divider,
  Modal,
  IconButton,
  InputLabel,
  TextField,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { PiCoinsLight } from "react-icons/pi";
import emptyIcon from "../../../assets/img/emptyRes.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  background: "#1a1a1a",
  boxShadow: 24,

  borderRadius: "12px",
};

const CreatePrice = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [price, setPrice] = useState(null);
  return (
    <>
      <Box
        sx={{
          border: "1px solid #333333",
          borderRadius: "8px",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: 500, p: 1.5 }}>
          Prices
        </Typography>
        <Divider />
        <Box
          sx={{
            mt: 2,
            px: 1.5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "350px",
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            onClick={() => setOpen(true)}
            sx={{
              borderColor: "#ccc",
              color: "#ccc",
              borderRadius: "8px",
              height: "40px",
              width: "70%",
              fontWeight: 300,
            }}
          >
            Create price
          </Button>

          <Box sx={{ mt: 4, width: "100%" }}>
            {!price || price.length === 0 ? (
              <>
                <Box
                  sx={{
                    height: "300px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={emptyIcon} width={100} />
                  <Typography sx={{ mt: 2 }}>Create Price for claim</Typography>
                </Box>
              </>
            ) : (
              <>
                {[1, 2, 3, 4]?.map((price, index) => (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{}}>
                        <Typography>Cocktail Cashout</Typography>
                        <Typography sx={{ fontSize: "10px" }}>
                          Count: 6
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: "12px" }}>
                        {" "}
                        <PiCoinsLight /> 300
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 1 }} />
                  </>
                ))}
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "12px 12px 0px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "background.card",
              borderBottom: "1px solid #BC172F",
              p: 2,
              width: "100%",
              px: 3,
              boxSizing: "border-box",
            }}
          >
            <Typography
              sx={{ fontFamily: "optima", fontSize: "18px", fontWeight: 700 }}
            >
              Create Price
            </Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseOutlined sx={{ fontSize: "20px" }} />
            </IconButton>
          </Box>
          <Box sx={{ mt: 4, px: 5 }}>
            <InputLabel>Redeem worth?</InputLabel>
            <TextField
              margin="dense"
              fullWidth
              // value={from}
              // onChange={(e) =>
              //   setFormValues({ ...formValues, from: e.target.value })
              // }
              InputProps={{ style: { borderRadius: "8px" } }}
            />
            <InputLabel sx={{ mt: 1 }}>How many point worth?</InputLabel>
            <TextField
              margin="dense"
              fullWidth
              type="number"
              // value={from}
              // onChange={(e) =>
              //   setFormValues({ ...formValues, from: e.target.value })
              // }
              InputProps={{ style: { borderRadius: "8px" } }}
            />
            <Box align="right" sx={{ mt: 3, mb: 5 }}>
              <Button
                // disabled={!value || !from || !to || isLoading}
                // onClick={handleCreatePoint}
                variant="contained"
                sx={{
                  height: "45px",
                  width: isLoading ? "auto" : "150px",
                  borderRadius: "8px",
                  transition: "0.2s all linear",
                }}
              >
                {isLoading ? (
                  <CircularProgress size={30} color="inherit" thickness={5.5} />
                ) : (
                  "       Create price"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CreatePrice;
