import React, { useEffect } from "react";
import { MenuItem, Box, Button, Popover } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main CSS file
import "react-date-range/dist/theme/default.css"; // Theme CSS file
import { format } from "date-fns";
import { useState } from "react";
import { enUS } from "date-fns/locale";
import "./style.css";

const DateFilterDropdown = ({ handleFilterValue, outlined }) => {
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [dayInterval, setDayInterval] = React.useState(7);
  const [dropdownLabel, setDropdownLabel] = React.useState("Today");

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [reservationDate, setReservationDate] = useState({
    start: new Date().toISOString().split("T")[0],
    end: "",
  });

  useEffect(() => {
    handleFilterValue(reservationDate?.start, reservationDate?.end);
  }, [reservationDate]);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover" : undefined;

  const handleClickMenu = (label) => {
    const today = new Date();
    handleClose2();
    setDropdownLabel(label);

    if (label === "This week") {
      const dayOfWeek = today.getDay();
      const startOfWeek = new Date(today);
      const endOfWeek = new Date(today);

      startOfWeek.setDate(
        today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
      );

      endOfWeek.setDate(startOfWeek.getDate() + 6);

      const startDate = startOfWeek.toISOString().split("T")[0];
      const endDate = endOfWeek.toISOString().split("T")[0];

      setReservationDate({
        ...reservationDate,
        start: startDate,
        end: endDate,
      });
    } else if (label === "Last week") {
      const lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      const startDate = lastWeek.toISOString().split("T")[0];
      const endDate = today.toISOString().split("T")[0];

      setReservationDate({
        ...reservationDate,
        start: startDate,
        end: endDate,
      });
    } else if (label === "Today") {
      setReservationDate({
        ...reservationDate,
        start: today.toISOString().split("T")[0],
        end: "",
      });
    }
  };

  const [isCustom, setIsCustom] = useState(false);

  const handleFilter = () => {
    handleClose2();
    const { endDate, startDate } = range[0];
    setReservationDate({
      ...reservationDate,
      start: startDate.toISOString().split("T")[0],
      end: endDate.toISOString().split("T")[0],
    });
    setDropdownLabel(`${reservationDate?.start} - ${reservationDate?.end}`);
  };

  // console.log(reservationDate, "Interval");

  return (
    <>
      <Button
      
        sx={{
          bgcolor: outlined ? "transparent" : "#2f2f2f",
          border:outlined && "1px solid #fff",
          borderRadius : outlined && "8px",
          px: 3,
          fontSize: "12px",
          color: "#fff",
          textTransform: "initial",
        }}
        endIcon={<IoIosArrowDown  style={{marginLeft:'10px'}}/>}
        onClick={handleClick2}
        aria-describedby={id2}
      >
        {dropdownLabel}
      </Button>
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 1, transition: "0.2s all linear" }}>
          {isCustom ? (
            <>
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={range}
                locale={enUS}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1.5,
                }}
              >
                <Button
                  onClick={() => setIsCustom(false)}
                  sx={{
                    bgcolor: "#1e1e1e",
                    borderRadius: "4px",
                    px: 4,
                    color: "#fff",
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={() => handleFilter()}
                  sx={{ bgcolor: "#1e1e1e", borderRadius: "4px", px: 4 }}
                >
                  Filter
                </Button>
              </Box>
            </>
          ) : (
            <>
              <MenuItem
                sx={{ fontSize: "12px", py: 1, placeContent: "center" }}
                onClick={() => handleClickMenu("Today")}
              >
                Today
              </MenuItem>
              <MenuItem
                sx={{ fontSize: "12px", py: 1, placeContent: "center" }}
                onClick={() => handleClickMenu("This week")}
              >
                This week
              </MenuItem>
              <MenuItem
                sx={{ fontSize: "12px", py: 1, placeContent: "center" }}
                onClick={() => handleClickMenu("Last week")}
              >
                Last week
              </MenuItem>

              <MenuItem
                sx={{ fontSize: "12px", py: 1, placeContent: "center" }}
                onClick={() => setIsCustom(true)}
              >
                Select date range
              </MenuItem>
            </>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default DateFilterDropdown;
