import React, { useEffect, useState } from "react";
import { BsFillHexagonFill, BsHexagon } from "react-icons/bs";
import { Box, Typography, Divider } from "@mui/material";
import { getPoints } from "../../../api";

const PointList = ({point_list}) => {
  const [isLoading, setIsLoading] = useState(false);




  return (
    <>
    {
     (!point_list || point_list?.length === 0 ) ? (
      <>

      </>
     ):(
      <>
  <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: 4,
          justifyContent: "space-between",
        }}
      >
       {
        point_list?.map((point, index)=>(
     <Hexagon name={point?.Name} key={index} />
        ))
       }
   

      </Box>

      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: 4,
          justifyContent: "space-between",
          mt: 2,
        }}
      >
       {
        point_list?.map((point, index)=>(
    <Box sx={{ width: "55px" }} key={index} >
          <Typography
            sx={{
              textAlign: "center",
              color: "#ccc",
              fontSize: "14px",
              fontFamily: "optima",
            }}
          >
         { `${point?.Value} / ${point?.TotalGiven}`}
          </Typography>
          <Typography
            sx={{ textAlign: "center", color: "#ccc", fontSize: "10px" }}
          >
            Points Away
          </Typography>
        </Box>
        ))
       }
    
   
      </Box>
      </>
     )
    }
    </>
  );
};

export default PointList;

const Hexagon = ({ name }) => {
  return (
    <Box sx={{ width: "55px" }}>
      <Typography
        sx={{ position: "relative", top: 37, zIndex: 1000, left: 20 }}
      >
        {name}
      </Typography>
      <BsHexagon style={{ fontSize: "55px", color: "rgb(120, 16, 32)" }} />
      <BsFillHexagonFill
        style={{
          fontSize: "40px",
          marginLeft: "-47px",
          position: "relative",
          top: "-8px",
          color: "rgb(120, 16, 32)",
        }}
      />
    </Box>
  );
};
