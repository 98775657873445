import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import { PieChart, Pie, Cell } from "recharts";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const COLORS = ["#FFC000", "#00FF47", "#06F", "#FF0025"];

const ReservationPieChart = ({ Rdata }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const data = [
    { name: "Group A", value: Rdata?.TotalExpected },
    { name: "Group B", value: Rdata?.TotalSeated },
    { name: "Group C", value: Rdata?.TotalFinished },
    { name: "Group D", value: Rdata?.TotalCancelled },
  ];

  const total =
    Rdata?.TotalCancelled +
    Rdata?.TotalExpected +
    Rdata?.TotalFinished +
    Rdata?.TotalSeated;
  const empty = total === 0 || Number.isNaN(total);

  return (
    <>
      <Box sx={{ display: "flex", columnGap: 5 }}>
        <Box
          sx={{
            width: "50%",
            height: "300px",
          }}
        >
          {empty ? (
            <>
              <Box
                sx={{
                  width: { md: "200px ", sm: "200px", xs: "120px" },
                  height: { md: "200px ", sm: "200px", xs: "120px" },
                  borderRadius: "50%",
                  border: "1px solid #999999b7",
                  mt: 5,
                  ml: { md: 10, sm: 5, xs: 0 },
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                  }}
                >
                  No Reservation
                </Typography>
              </Box>
            </>
          ) : (
            <>
              {isMobile ? (
                <>
                  <PieChart width={300} height={300}>
                    <Pie
                      data={data}
                      cx={80}
                      cy={110}
                      innerRadius={50}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={1}
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </>
              ) : (
                <>
                  <PieChart width={500} height={400}>
                    <Pie
                      data={data}
                      cx={170}
                      cy={160}
                      innerRadius={90}
                      outerRadius={120}
                      fill="#8884d8"
                      paddingAngle={1}
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </>
              )}
            </>
          )}
        </Box>
        <Box sx={{ mt: 5, width: "40%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontWeight: 400 }}>Status</Typography>
            <Typography sx={{ fontWeight: 400 }}>Cover</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
              mt: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <FiberManualRecordIcon sx={{ color: "#00FF47" }} />
              <Typography sx={{ fontSize: "11px" }}>Seated</Typography>
            </Box>
            <Typography sx={{ fontSize: "11px", mt: 0.5 }}>
              {Rdata?.TotalSeated || "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <FiberManualRecordIcon sx={{ color: "#FFC000" }} />
              <Typography sx={{ fontSize: "11px" }}>Expected</Typography>
            </Box>
            <Typography sx={{ fontSize: "11px", mt: 0.5 }}>
              {Rdata?.TotalExpected || "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <FiberManualRecordIcon sx={{ color: "#06F" }} />
              <Typography sx={{ fontSize: "11px" }}>Finished</Typography>
            </Box>
            <Typography sx={{ fontSize: "11px", mt: 0.5 }}>
              {Rdata?.TotalFinished || "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <FiberManualRecordIcon sx={{ color: "#FF0025" }} />
              <Typography sx={{ fontSize: "11px" }}>Cancelled</Typography>
            </Box>
            <Typography sx={{ fontSize: "11px", mt: 0.5 }}>
              {Rdata?.TotalCancelled || "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ mt: 4, fontSize: "13px" }}>Total</Typography>
            <Typography sx={{ mt: 4, fontSize: "13px" }}>
              {total ? total : 0}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReservationPieChart;
