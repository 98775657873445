import React, { useState, forwardRef, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSnackbar, SnackbarContent } from "notistack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LinearProgress } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    "@media (min-width:600px)": {
      minWidth: "344px !important",
    },
  },
  card: {
    width: "100%",
  },
  typography: {
    color: "#000",
  },
  actionRoot: {
    padding: "8px 8px 8px 16px",
    justifyContent: "space-between",
  },
  icons: {
    marginLeft: "auto",
  },
  expand: {
    padding: "8px 8px",
    transform: "rotate(0deg)",
    color: "#000",
    transition: "all .2s",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  paper: {
    backgroundColor: "#fff",
    padding: 16,
  },
  checkIcon: {
    fontSize: 20,
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: "none",
  },
}));

const ErrorAlert = forwardRef(({ id, ...props }, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 250);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card
        className={classes.card}
        style={{ backgroundColor: "#160b0b", borderRadius: "8px" }}
      >
        <LinearProgress variant="determinate" value={progress} />
        <CardActions
          classes={{ root: classes.actionRoot }}
          style={{ borderLeft: "0px solid #BC172F", borderRadius: "5px" }}
        >
          <Typography
            sx={{ fontFamily: "outfit", color: "#f4c7c7", fontSize: "12px" }}
          >
            {props.message}
          </Typography>
          <div className={classes.icons}>
            <IconButton
              size="small"
              className={classes.expand}
              onClick={handleDismiss}
            >
              <CloseIcon sx={{ color: "#f4c7c7" }} fontSize="small" />
            </IconButton>
          </div>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

ErrorAlert.displayName = "ErrorAlert";

export default ErrorAlert;
