import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  Modal,
  InputLabel,
  TextField,
  Avatar,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { ArrowBackSharp, CloseOutlined } from "@mui/icons-material";
import LoungeCard from "./LoungeCard";
import { addMoreTables, deleteTables, getSingleFloorPlan } from "../../../api";
import Loader from "../../Common/Loader";
import CustomInput from "../CustomField/CustomInput";
import AddMoreTables from "./Modals/AddMoreTables";
import { TiInputCheckedOutline } from "react-icons/ti";
import { IoDuplicateOutline } from "react-icons/io5";
import { TiDocumentDelete } from "react-icons/ti";
import { useSnackbar } from "notistack";
import { MdOutlineViewInAr } from "react-icons/md";

import LayoutDesign from "./LayoutDesign";
import { ThemeContext } from "../../../STYLES/theme/ThemeContextProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#1a1a1a",

  boxShadow: 24,
  p: 0,
  borderRadius: "8px",
};

const Room = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);

  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [isSelect, setIsSelect] = useState(false);
  const [deleteIds, setDeleteId] = useState([]);
  const [name, setName] = useState("");

  const [isLayout, setIsLayout] = useState(false);

  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const updateDelete = (newDataArray) => {
    setDeleteId(newDataArray);
  };

  const handleGetSingleFloor = async () => {
    setIsLoading(true);
    await getSingleFloorPlan(id)
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        if (data.status) {
          setData(data?.result[0].Tables);
          setName(data?.result[0]?.Name);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    handleGetSingleFloor();
  }, [id]);
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleSearch = (event) => {
    const searchParams = event.target.value.toLowerCase();
    const filtered = data.filter((item) =>
      item.Name.toLowerCase().includes(searchParams)
    );
    setFilteredData(filtered);
    setSearchParams(searchParams);
  };

  const handleDeleteTables = async () => {
    setIsLoading(true);
    const selectedIds = deleteIds?.join(",");

    await deleteTables(selectedIds)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          handleGetSingleFloor();
          handleAlert("success", "Tables deleted  successfully");
        } else {
          handleAlert("error", `${res?.data?.result[0]}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", err.message);
      });
  };

  const handleDuplicateTable = async () => {
    const filteredData = data.filter((item) => deleteIds.includes(item.Id));

    const payload = filteredData.map((item) => ({
      name: item?.Name,
      maxSize: item?.MaxSize,
      minSize: item?.MinSize,
      position: item?.Position,
      setting: item?.Setting,
      shape: item?.Shape,
    }));

    setIsLoading(true);
    await addMoreTables(id, payload)
      .then((res) => {
        if (res?.data?.status) {
          handleGetSingleFloor();
          handleAlert("success", "Table created successfully");
        } else {
          handleAlert("error", `${res?.data?.result[0]}`);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert("error", err.message);
      });
  };

  const { isDarkTheme } = useContext(ThemeContext);
  return (
    <>
      {isLoading && <Loader />}
      <Box
        sx={{
          borderBottom: "0.2px solid #ccccccb7",
          // mt: -1.3,
          background: isDarkTheme ? "#1a1a1a" : "#ffff",
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "80px",
        }}
      >
        <Box sx={{ display: "flex", columnGap: 3, alignItems: "center" }}>
          <Typography sx={{ fontWeight: 700, fontFamily: "optima" }}>
            Rooms -{" "}
            <span style={{ textTransform: "uppercase", fontWeight: 700 }}>
              {name}
            </span>
          </Typography>
        </Box>
        <Box sx={{ width: "30%" }}>
          <CustomInput
            type="text"
            name="search tables..."
            value={searchParams}
            onChange={handleSearch}
          />
        </Box>
      </Box>
      {isLayout && (
        <>
          <LayoutDesign
            name={name}
            onEdit={() => setIsLayout(false)}
            tables={data}
            action={handleGetSingleFloor}
            floorID={id}
          />
        </>
      )}

      {!isLayout && (
        <>
          <Box sx={{ p: 2 }}>
            <Box sx={{ border: "0.5px solid #fff" }}>
              <Box
                sx={{
                  borderBottom: "0.5px solid ",
                  p: 1,
                  borderColor: "text.primary",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: "background.card",
                  boxShadow:
                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                  columnGap: 2,
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                >
                  <Link to="/dashboard/table-rooms">
                    <IconButton>
                      <ArrowBackSharp sx={{ color: "text.primary" }} />
                    </IconButton>
                  </Link>
                  <Typography sx={{ whiteSpace: "nowrap" }}>{name}</Typography>
                </Box>
                <Box
                  sx={{
                    // border: "1px solid red",
                    width: "fit-content",
                    display: "flex",
                  }}
                >
                  <Button
                    onClick={() => setIsLayout(true)}
                    variant="outlined"
                    sx={{
                      fontWeight: 400,
                      px: 3,
                      mr: 3,
                      fontSize: "10px",
                      borderRadius: "10px",
                    }}
                    startIcon={<MdOutlineViewInAr />}
                  >
                    Floor Layout
                  </Button>
                  {isSelect ? (
                    <>
                      {" "}
                      <Button
                        onClick={handleDuplicateTable}
                        variant="outlined"
                        sx={{
                          fontWeight: 400,
                          px: 3,
                          mr: 3,
                          fontSize: "10px",
                          borderRadius: "10px",
                        }}
                        startIcon={<IoDuplicateOutline />}
                      >
                        Duplicate Table
                      </Button>
                      <Button
                        onClick={handleDeleteTables}
                        variant="contained"
                        sx={{
                          fontWeight: 400,
                          px: 3,
                          mr: 3,
                          fontSize: "10px",
                          borderRadius: "10px",
                        }}
                        startIcon={<TiDocumentDelete />}
                      >
                        Delete
                      </Button>
                    </>
                  ) : (
                    <AddMoreTables id={id} action={handleGetSingleFloor} />
                  )}

                  <Button
                    onClick={() => setIsSelect(!isSelect)}
                    variant="outlined"
                    startIcon={<TiInputCheckedOutline />}
                    sx={{}}
                  >
                    {" "}
                    {isSelect ? "Done" : "Select"}
                  </Button>
                </Box>
              </Box>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  {data &&
                    filteredData?.map((item, index) => (
                      <Grid item lg={3} md={4} key={index}>
                        <LoungeCard
                          action={handleGetSingleFloor}
                          index={index}
                          data={item}
                          select={isSelect}
                          updateDelete={updateDelete}
                          deleteArray={deleteIds}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      )}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              bgcolor: "#333",
              p: 2,
              borderRadius: "8px 8px 0px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "#fff", fontWeight: 500 }}>
              Add More Tables
            </Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseOutlined sx={{ color: "#fff" }} />
            </IconButton>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box sx={{ pb: 4, borderBottom: "1px solid #fff" }}>
              <InputLabel
                sx={{ color: "#fff", fontWeight: 300, fontSize: "12px" }}
              >
                Name of Floor Plan
              </InputLabel>
              <TextField
                // value={names}
                // onChange={(e) => setNames(e.target.value)}
                placeholder="vip lounge"
                margin="dense"
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                    fontSize: "13px",
                    borderRadius: "10px",
                    border: "1px solid #fff",
                    color: "#fff",
                    offset: " 1px solid #fff",
                  },
                }}
              />

              <InputLabel
                sx={{ color: "#fff", mt: 3, fontWeight: 300, fontSize: "12px" }}
              >
                Location of Floor
              </InputLabel>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: 400 }}>
                Add Tables
              </Typography>
              <InputLabel
                sx={{ color: "#fff", mt: 1, fontWeight: 300, fontSize: "12px" }}
              >
                How many tables are in the lounge
              </InputLabel>
              <TextField
                // value={totalTables}
                // onChange={(e) => setTotalTables(e.target.value)}
                type="number"
                margin="dense"
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                    fontSize: "13px",
                    borderRadius: "10px",
                    border: "1px solid #fff",
                    color: "#fff",
                    offset: " 1px solid #fff",
                  },
                }}
              />
            </Box>

            <Button
              fullWidth
              sx={{ py: 2, mt: 3, borderRadius: "10px" }}
              variant="contained"
              // onClick={handleEditFloorplan}
            >
              Save changes to {name}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Room;
