import React, { useState, useEffect } from "react";
import ReservationCard from "../Cards/ReservationCard";
import { Box, Typography, Grid, CircularProgress, LinearProgress } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import SquareIcon from "@mui/icons-material/Square";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";

const Expected = ({
  expanded,
  handleChange,
  upcoming,
  handleGetReservations,
  loader
}) => {
  const [sortedUpcoming, setSortedUpcoming] = useState([]);

  useEffect(() => {
    const sortedItems = upcoming?.sort((a, b) => {
      if (!a.IsConfirmed && b.IsConfirmed) return -1; // a comes first
      if (a.IsConfirmed && !b.IsConfirmed) return 1; // b comes first
      return 0; // maintain order for equal values
    });

    setSortedUpcoming(sortedItems);
  }, [upcoming]);

  const [active_id, setActiveId] = useState(null);

  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const activeBooking = searchParams.get("BookingID");
    if (activeBooking) {
      setActiveId(activeBooking);
    } else {
      setActiveId(null);
    }
  }, [location, upcoming]);
  return (
    <>
      <Accordion
        expanded={expanded === "Expected"}
        onChange={handleChange("Expected")}
        sx={{ background: expanded === "Expected" ? "#1A1A1A" : "#333" }}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{ border: "0.5px solid #fff" }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", columnGap: 2 }}>
              <SquareIcon
                sx={{ color: "#E6BF47", width: "20px", height: "20px" }}
              />
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: 500,
                  fontSize: {
                    lg: "12px",
                    md: "12px",
                    sm: "12px",
                    xs: "10px",
                  },
                }}
              >
                UPCOMING
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 500,
                fontSize: {
                  lg: "12px",
                  md: "12px",
                  sm: "12px",
                  xs: "10px",
                },
              }}
            >
              {sortedUpcoming ? (
                `${sortedUpcoming.length} guests`
              ) : (
                <CircularProgress size={20} />
              )}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {!sortedUpcoming || sortedUpcoming.length === 0 ? (
              <>
                <Box
                  sx={{
                    p: 4,
                    display: "grid",
                    placeItems: "center",
                    width: "100%",
                  }}
                >
                {
                  loader &&   <Box sx={{width:'40%', mb:1}}>
                    <LinearProgress/>
                  </Box>
                }
                  <Typography sx={{ textAlign: "center", color: "#fff" }}>
                    No Reservations Available
                  </Typography>
                </Box>
              </>
            ) : (
              sortedUpcoming &&
              sortedUpcoming.map((item, index) => (
                <Grid item lg={3} md={3} sm={6} xs={6} key={index}>
                  <ReservationCard
                    action={handleGetReservations}
                    borderColor="#E6BF47"
                    data={item}
                    upcoming={upcoming}
                    active_id={active_id}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Expected;

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  background: "#333",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ExpandCircleDownOutlinedIcon
        sx={{ fontSize: "1.5rem", color: "#fff" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
