import React from 'react'
import WaitlistCard from '../Cards/WaitlistCard';
import { Accordion, AccordionDetails, AccordionSummary } from './Expected';
import { Box, Typography, Grid } from '@mui/material'

import { IoMdTime } from 'react-icons/io';

const Waitlist = ({expanded, handleChange, waitlist, handleGetWaitlistReservation, handleGetReservations, finished}) => {
  return (
<>
       <Accordion
                expanded={expanded === "Waitlist"}
                onChange={handleChange("Waitlist")}
                sx={{ background: expanded === "Waitlist" ? "#1A1A1A" : "#333" }}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  sx={{ border: "0.5px solid #fff" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", columnGap: 2 }}>
                      <IoMdTime style={{ width: "20px", height: "20px" }} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: {
                            lg: "12px",
                            md: "12px",
                            sm: "10px",
                            xs: "10px",
                          },
                        }}
                      >
                        WAITLIST
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "10px",
                          xs: "10px",
                        },
                        fontWeight: 500,
                      }}
                    >
                      {waitlist && `${waitlist.length} guests`}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {!waitlist || waitlist.length === 0 ? (
                      <>
                        <Box
                          sx={{
                            p: 4,
                            display: "grid",
                            placeItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{ textAlign: "center", color: "#fff" }}
                          >
                            No Waitlist Reservations
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      waitlist &&
                      waitlist.map((item, index) => (
                        <Grid item lg={3} md={3} sm={6} xs={6} key={index}>
                          <WaitlistCard
                            action={() => {
                              handleGetWaitlistReservation();
                              handleGetReservations();
                            }}
                            borderColor="#5C5C5C"
                            data={item}
                            finished={finished}
                          />
                        </Grid>
                      ))
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
</>
  )
}

export default Waitlist;