import { Box, IconButton, Typography } from "@mui/material";
import icon1 from "../../../assets/Icons/details/1.svg";
import icon2 from "../../../assets/Icons/details/2.svg";
import icon3 from "../../../assets/Icons/details/3.svg";
import icon4 from "../../../assets/Icons/details/4.svg";
// import icon5 from "../../../assets/Icons/details/5.svg";
// import icon6 from "../../../assets/Icons/details/6.svg";
// import icon7 from "../../../assets/Icons/details/7.svg";
import icon8 from "../../../assets/Icons/details/8.svg";
import icon9 from "../../../assets/Icons/details/9.svg";
import icon10 from "../../../assets/Icons/details/10.svg";
import icon11 from "../../../assets/Icons/details/11.svg";

import React from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { formatDate2 } from "../../../helpers/Format";

const ReservationDetails = ({ handleDetailsScreen, data }) => {
  return (
    <>
      <Box
        sx={{
          bgcolor: "background.card",
          mt: 3,
          borderRadius: "24px 0px 0px 0px",
          px: 3,
          py: 1,
          display: "flex",
          columnGap: 2,
          alignItems: "center",
        }}
      >
        <IconButton onClick={handleDetailsScreen}>
          <MdOutlineKeyboardBackspace />
        </IconButton>
        <Typography sx={{ color: "#ccc", fontSize: "14px" }}>
          Back to home
        </Typography>
      </Box>
      <Box
        sx={{ mt: 2, p: 3, bgcolor: "#1a1a1a", borderRadius: "10px", px: 4 }}
      >
        <Typography sx={{ color: "#fff", fontWeight: 500, fontSize: "16px" }}>
          Reservation Details
        </Typography>
        <Box sx={{ mt: 5 }}>
          <Typography sx={{ color: "#fff" }}>Diner Details</Typography>
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "15%",
                  columnGap: 2,
                }}
              >
                <img src={icon1} width={15} />
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "14px" }}
                >
                  Name of Guest:
                </Typography>
              </Box>
              <Typography
                sx={{ color: "#fff", fontWeight: 400, fontSize: "14px" }}
              >
                {data?.DinnerName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "15%",
                  columnGap: 2,
                }}
              >
                <img src={icon2} width={15} />
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "14px" }}
                >
                  Party Size:
                </Typography>
              </Box>
              <Typography
                sx={{ color: "#fff", fontWeight: 400, fontSize: "14px" }}
              >
                {data?.PartySize}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "15%",
                  columnGap: 2,
                }}
              >
                <img src={icon3} width={15} />
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "14px" }}
                >
                  Date:
                </Typography>
              </Box>
              <Typography
                sx={{ color: "#fff", fontWeight: 400, fontSize: "14px" }}
              >
                {formatDate2(data?.Date)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "15%",
                  columnGap: 2,
                }}
              >
                <img src={icon4} width={15} />
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "14px" }}
                >
                  Time:
                </Typography>
              </Box>
              <Typography
                sx={{ color: "#fff", fontWeight: 400, fontSize: "14px" }}
              >
                {data?.Time}
              </Typography>
            </Box>

            <Box sx={{ borderTop: "1px solid #ffffffa1", my: 4 }} />
          </Box>
          {/* <Box sx={{ mt: 3 }}>
            <Typography
              sx={{ color: "#fff", fontWeight: 400, fontSize: "16px", mb: 4 }}
            >
              Order Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "25%",
                  columnGap: 2,
                }}
              >
                <img src={icon5} />
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "14px" }}
                >
                  Order Details
                </Typography>
              </Box>
              <Typography
                sx={{ color: "#fff", fontWeight: 400, fontSize: "14px" }}
              >
                Ocean Basket Table for 4
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "25%",
                  columnGap: 2,
                }}
              >
                <img src={icon6} />
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "14px" }}
                >
                  Total Amount:
                </Typography>
              </Box>
              <Typography
                sx={{ color: "#fff", fontWeight: 400, fontSize: "14px" }}
              >
                ₦8, 000.00
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "25%",
                  columnGap: 2,
                }}
              >
                <img src={icon7} />
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "14px" }}
                >
                  Payment method:
                </Typography>
              </Box>
              <Typography
                sx={{ color: "#fff", fontWeight: 400, fontSize: "14px" }}
              >
                Via Wallet
              </Typography>
            </Box>

            <Box sx={{ borderTop: "1px solid #ffffffa1", my: 4 }} />
          </Box> */}
          <Box sx={{ mt: 3 }}>
            <Typography
              sx={{ color: "#fff", fontWeight: 400, fontSize: "16px", mb: 4 }}
            >
              Other Info
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "25%",
                  columnGap: 2,
                }}
              >
                <img src={icon8} />
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "14px" }}
                >
                  Invited Guests:
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#7e7e7e",
                  fontWeight: 300,
                  fontSize: "12px",
                  fontStyle: "italic",
                }}
              >
                {/* paulkehindeolanrewaju@reisty.com emmanueldaramola@reisty.com
                georgeikwegbu@reisty.com deborahthestardesigner@reisty.com */}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "25%",
                  columnGap: 2,
                }}
              >
                <img src={icon9} />
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "14px" }}
                >
                  Special Requests:
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#7e7e7e",
                  fontWeight: 300,
                  fontSize: "12px",
                  fontStyle: "italic",
                }}
              >
                Not Provided
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "25%",
                  columnGap: 2,
                }}
              >
                <img src={icon10} />
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "14px" }}
                >
                  Dietary Restrictions:
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#7e7e7e",
                  fontWeight: 300,
                  fontSize: "12px",
                  fontStyle: "italic",
                }}
              >
                Not Provided
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "25%",
                  columnGap: 2,
                }}
              >
                <img src={icon11} />
                <Typography
                  sx={{ color: "#fff", fontWeight: 300, fontSize: "14px" }}
                >
                  Occasion
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#7e7e7e",
                  fontWeight: 300,
                  fontSize: "12px",
                  fontStyle: "italic",
                }}
              >
                Not Provided
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReservationDetails;

