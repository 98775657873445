import React,{useState} from 'react'
import { confirmReservation } from '../../../../../api';
import { Modal, Box, Typography, Button } from '@mui/material';
import { BsBookmarkCheckFill } from 'react-icons/bs';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 700, md: 700, sm: 400, xs: 350 },
  maxHeight: "70vh",
  overflow: "scroll",
  bgcolor: "#1a1a1a",
  borderRadius: 2,
  boxShadow: 24,
  p: 0,
};

const Approve = ({data, handleAlert, action, handleClose, open}) => {

    const [isLoading, setIsLoading] = useState(false);
    const handleApproveReservation = async () => {
    setIsLoading(true);
    await confirmReservation(data?.BoookingId)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          handleAlert(
            "success",
            `${
              res?.data?.success_message || "Reservation successfully approved"
            }`
          );
          action();
       handleClose()
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        handleAlert("error", `${err.message}`);
      });
  };
  return (
<>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
      <Box sx={{ p: 3 }}>
            <Typography
              sx={{ fontSize: "14px", color: "#fff", textAlign: "center" }}
            >
              Are you sure you want to approve{" "}
              <b style={{ color: "#19A662" }}>
                {" "}
                {data?.bookingUserDetails?.Name || "--"}
              </b>{" "}
              Reservation
            </Typography>
            <Box sx={{ display: "grid", placeContent: "center", mt: 2 }}>
              <BsBookmarkCheckFill style={{ fontSize: "40px" }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                mt: 3,
              }}
            >
              <Button
                variant="outlined"
                onClick={handleClose}
                fullWidth
                sx={{ color: "#fff", borderColor: "#fff" }}
              >
                No, Close
              </Button>
              <Button
                onClick={() => handleApproveReservation()}
                variant="contained"
                fullWidth
                sx={{ bgcolor: "#19A662", "&:hover": { bgcolor: "#19A662b7" } }}
              >
                Yes, Approve
              </Button>
            </Box>
          </Box>
                  </Box>
      </Modal>
</>
  )
}

export default Approve