import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LiaUserEditSolid } from "react-icons/lia";
import CustomModal from "../../../Common/CustomModal";
import CustomTextField from "../../CustomField/CustomTextField";
import { editGuestInfo } from "../../../../api";
import Loader from "../../../Common/Loader";
import { useSnackbar } from "notistack";

const EditGuest = ({ guest, callback }) => {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // console.log(guest, "Guest Ifo");

  const [guestDetails, setGuestDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });

  useEffect(() => {
    if (!guest) return null;

    setGuestDetails({
      ...guestDetails,
      firstName: guest.FirstName,
      lastName: guest.LastName,
      phoneNumber: guest.PhoneNumber,
      email: guest.EmailAddress,
    });
  }, [guest]);

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handleEditGuest = async () => {
    setIsLoading(true);
    const { firstName, lastName, phoneNumber, email } = guestDetails;

    await editGuestInfo(guest?.Id, firstName, lastName, phoneNumber, email)
      .then((res) => {
        if (res?.data?.status) {
          handleAlert("success", `${res?.data?.success_message}`);
          setOpenModal(false);
          callback();
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        handleAlert("error", `${err.message} `);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      <Button
        onClick={() => setOpenModal(true)}
        startIcon={<LiaUserEditSolid />}
        variant="outlined"
        sx={{ color: "#fff", borderColor: "#fff", borderRadius: "8px" }}
      >
        Edit
      </Button>

      <CustomModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={`Edit Guest - ${guest.FirstName || " "} ${
          guest.LastName || " "
        }`}
        width={450}
      >
        <Box sx={{ p: 3, boxSizing: "border-box" }}>
          <Box>
            <CustomTextField
              name="First name"
              value={guestDetails.firstName}
              onChange={(e) =>
                setGuestDetails({ ...guestDetails, firstName: e.target.value })
              }
            />
            <Box sx={{ mt: 2 }}>
              <CustomTextField
                name="Last name"
                value={guestDetails.lastName}
                onChange={(e) =>
                  setGuestDetails({ ...guestDetails, lastName: e.target.value })
                }
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <CustomTextField
                name="Email"
                value={guestDetails.email}
                onChange={(e) =>
                  setGuestDetails({ ...guestDetails, email: e.target.value })
                }
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <CustomTextField
                name="Phone Number"
                value={guestDetails.phoneNumber}
                onChange={(e) =>
                  setGuestDetails({
                    ...guestDetails,
                    phoneNumber: e.target.value,
                  })
                }
              />
            </Box>
            <Box sx={{ mt: 4 }} align="right">
              <Button
                onClick={handleEditGuest}
                variant="contained"
                sx={{ borderRadius: "8px", height: "45px", width: "125px" }}
              >
                Edit Profile
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

export default EditGuest;
